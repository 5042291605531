import React, { Component } from "react";
import Modal from "react-responsive-modal";
class DepositModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      show: false,
      timeHorizon: "",
      paymentRequestButtonLoading: false,
      earningData: [],
      fileName: "MinerData",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);

    this.setShow = this.setShow.bind(this);
    this.onChangeTimeHorizon = this.onChangeTimeHorizon.bind(this);
    this.clearTime = this.clearTime.bind(this);
  }

  async setShow(startDate, endDate) {
    if (startDate && endDate) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }
  async clearTime() {
    this.setState({ startDate: "" });
    this.setState({ endDate: "" });
    this.setState({ timeHorizon: "" });
  }

  async onChangeTimeHorizon(time) {
    this.setState({
      timeHorizon: time,
    });
  }
  async onChangeAddress(e) {
    this.setState(
      {
        address: e.target.value,
      },
      () => {
        this.setShow(this.state.address, this.state.endDate);
      }
    );
  }
  async onSubmit() {}

  render() {
    const { depositModalOpen, depositOnCloseModal, cancelSubscription } =
      this.props;
    return (
      <Modal
        open={depositModalOpen}
        onClose={depositOnCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <h4
                      className="text-center text-black"
                      style={{ alignSelf: "flex-start !important" }}
                    >
                      Do you really want to cancel Subscription
                    </h4>
                    <div className="d-flex justify-content-center mt-4">
                      <button
                        className="btn modal-button mr-2 cancel-button"
                        type="button"
                        onClick={depositOnCloseModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn modal-button update-button"
                        type="button"
                        onClick={cancelSubscription}
                      >
                        {" "}
                        Confirm{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default DepositModal;
