import React from "react";

const TokensIssuedTooltip = () => {
  return (
    <div className="earningTooltip1">
      <span className=".subInfo" style={{ fontSize: "12px" }}>
        DEC tokens are issued as credit for the Balance Due.
      </span>
    </div>
  );
};

export default TokensIssuedTooltip;
