import { TOGGLE_DARK_MODE } from "../actions/types";

export const initialState = false;

export const DarkModeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_DARK_MODE:
      const siteWrapper = document.getElementsByTagName("html")[0];
      if (!state) {
        siteWrapper.setAttribute("data-theme", "dark");
      } else {
        siteWrapper.setAttribute("data-theme", "light");
      }

      return !state;
    default:
      return state;
  }
};
