import React from "react";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";

class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    document.title =
      messageConstants.TERMS_CONDITIONS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
  }

  componentDidMount() {
    pageProgress("remove");
  }

  render() {
    return (
      <div className="">
        <p className="terms-header">Terms & Conditions</p>
        <div className="terms-container">
          <div className="custom-container">
            <div className="terms-container">
              <p>
                The website{" "}
                <strong>
                  <a
                    href="www.permianchain.com"
                    target="_blank"
                    style={{ color: "#333333" }}
                  >
                    www.permianchain.com
                  </a>
                </strong>{" "}
                (the “Website”) is owned and operated by PermianChain
                Technologies Inc. (“we”, “our”, “us”) and is made available as a
                source of information about us, including information about
                news, services, and other topics of interest. By using the
                Website, you accept and agree to be bound and comply with these
                terms of use (the “Terms of Use”) which may be amended from time
                to time, and our privacy policy (the “Privacy Policy”),
                incorporated herein by reference. If you do not agree to the
                Terms of Use or the Privacy Policy, you must not access or use
                the Website. As a condition of your access and use, you agree to
                use the Website only for lawful purposes and in accordance with
                the Terms of Use. Your use of the Website is solely at your own
                risk. We do not accept any liability for your access to or use
                of the Website.
              </p>
              <p>
                BY USING THE WEBSITE, YOU ACCEPT AND AGREE TO BE BOUND AND
                COMPLY WITH THESE TERMS AND CONDITIONS AND OUR PRIVACY POLICY,
                FOUND AT PERMIANCHAIN.COM, INCORPORATED HEREIN BY REFERENCE. IF
                YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS OR THE PRIVACY
                POLICY, YOU MUST NOT ACCESS OR USE THE WEBSITE.
              </p>
              <p>
                By using this Website, you represent and warrant that you are
                the legal age of majority under applicable law to form a binding
                contract with the Company and meet all of the foregoing
                eligibility requirements. If you do not meet all of these
                requirements, you must not access or use the Website.
              </p>
              <h5>Modifications to the Terms and Conditions of Use</h5>
              <p>
                We reserve the right in our sole discretion to revise and update
                these terms and conditions from time to time. Any and all such
                modifications are effective immediately upon posting and apply
                to all access to and continued use of the Website. You agree to
                periodically review the terms and conditions in order to be
                aware of any such modifications and your continued use shall be
                your acceptance of these.
              </p>
              <p>
                The information and material on this Website, and the Website,
                may be changed, withdrawn, or terminated at any time in our sole
                discretion without notice. We will not be liable if, for any
                reason, all or any part of the Website is restricted to users or
                unavailable at any time or for any period.
              </p>
              <h5>
                Additional Terms and Conditions of Use for Certain Services and
                Functionality
              </h5>
              <p>
                Certain services and features that are accessible through the
                Website are subject to additional terms and conditions. You
                shall be prompted to review and accept the additional terms and
                conditions of use before being given access to such services and
                features.
              </p>
              <h5>Your Use of the Website and Security</h5>
              <p>
                The security of your personal information is very important to
                us. We use physical, electronic, and administrative measures
                designed to secure your personal information from accidental
                loss and from unauthorized access, use, alteration, and
                disclosure.
              </p>
              <p>
                The safety and security of your information also depends on you.
                Users are responsible for obtaining their own access to the
                Website. Users are required to ensure that all persons who
                access the Website through a user's internet connection are
                aware of these Terms and Conditions and comply with them.
              </p>
              <p>
                The Website, including content or areas of the Website, may
                require user registration. It is a condition of your use of the
                Website that all the information you provide on the Website is
                correct, current, and complete.
              </p>
              <p>
                Unfortunately, the transmission of information via the Internet
                is not completely secure. Although we do our best to protect
                your personal information, we cannot guarantee the security of
                your personal information transmitted to our Website. Any
                transmission of personal information is at your own risk. We are
                not responsible for circumvention of any privacy settings or
                security measures contained on the Website.
              </p>

              <h5>No reliance</h5>
              <p>
                The content on our Website is provided for general information
                purposes only. It is not intended to amount to advice on which
                you should rely. You must obtain more specific or professional
                advice before taking, or refraining from, any action or inaction
                on the basis of the content on our site.
              </p>
              <p>
                Although we make reasonable efforts to update the information on
                our Website, we make no representations, warranties, or
                guarantees, whether express or implied, that the content on our
                Website is accurate, complete, or up to date. Your use of the
                Website is at your own risk and neither the Company or its
                directors, officers, employees, agents, service providers,
                contractors, licensors, licensees, suppliers, or successors have
                any responsibility or liability whatsoever for your use of this
                Website.
              </p>
              <p>
                This Website may include content provided by third parties,
                including from other users and third-party licensors. All
                statements and/or opinions expressed in any such third-party
                content, other than the content provided by the Company, are
                solely the opinions and the responsibility of the person or
                entity providing those materials. Such materials do not
                necessarily reflect the opinion of the Company. Neither the
                Company nor its directors, officers, employees, agents, service
                providers, contractors, licensors, licensees, suppliers, or
                successors have any responsibility or liability whatsoever to
                you, or any third party, for the content or accuracy of any
                third-party materials.
              </p>
              <p>
                Although we make reasonable efforts to update the information on
                the Website, we make no representations, warranties, or
                guarantees, whether express or implied, that the content on the
                Website is accurate, complete, or up to date. Since the date the
                information was first posted on the Website, there may have been
                changes to the law, or other reasons which have affected the
                currency of the information. Information found on the Website is
                provided on an “as is” and “as available” basis. Your use of the
                Website is at your own risk and we have no responsibility or
                liability whatsoever for your use of the Website. We expressly
                disclaim any and all representations, warranties and/or
                conditions that may be implied by law, including, without
                limitation, any representations, warranties and/or conditions of
                merchantability, merchantable quality, and/or fitness for a
                particular purpose. In no event shall we, our partners, agents,
                contractors or employees be liable for any loss or damages for
                whatever reason, arising out of any use or misuse of the
                Website.
              </p>

              <h5>Intellectual Property</h5>
              <p>
                You understand and agree that the Website and its entire
                contents, features, and functionality, including, but not
                limited to, all information, software, code, text, displays,
                graphics, photographs, video, audio, design, presentation,
                selection, and arrangement, are owned by the Company, its
                licensors, or other providers of such material and are protected
                in all forms by intellectual property laws including without
                limitation, copyright, trademark, patent, trade secret, and any
                other proprietary rights.
              </p>
              <p>
                The Company name and all related names, logos, product and
                service names, designs, images, and slogans are trademarks of
                the Company or its affiliates or licensors. You must not use
                such marks without the prior written permission of the Company.
                Other names, logos, product and service names, designs, images,
                and slogans mentioned, or which appear on this Website are the
                trademarks of their respective owners. Use of any such property,
                except as expressly authorized, shall constitute an infringement
                or violation of the rights of the property owner and may be a
                violation of federal or other laws and could subject the
                infringer to legal action.
              </p>

              <h5>Privacy</h5>
              <p>
                By using the Website and submitting your personal information,
                you consent to the collection, use, and disclosure of such
                information in compliance with our Privacy Policy.
              </p>
              <p>
                By using this Website, you are consenting to the use of cookies
                which allow a server to recall previous requests or registration
                and/or IP addresses to analyze website use patterns. You can set
                your browser to notify you before you receive a cookie, giving
                you the chance to decide whether to accept it. You can also set
                your browser to turn off cookies. If you do, however, some areas
                of the Website may not function adequately.
              </p>

              <h5>Third-Party Websites</h5>
              <p>
                For your convenience, this Website may provide links or pointers
                to third-party sites. We make no representations about any other
                websites that may be accessed from this Website. If you choose
                to access any such sites, you do so at your own risk. We have no
                control over the contents of any such third-party sites and
                accept no responsibility for such sites or for any loss or
                damage that may arise from your use of them. You are subject to
                any terms and conditions of such third-party sites.
              </p>

              <h5>Geographic Restriction</h5>
              <p>
                The owner of the Website is based in province of Ontario in
                Canada. We provide this Website for use only by persons located
                in Canada. This Website is not intended for use in any
                jurisdiction where its use is not permitted. If you access the
                Website from outside Canada, you do so at your own risk and you
                are responsible for compliance with local laws of your
                jurisdiction.
              </p>

              <h5>Limited License</h5>
              <p>
                We grant you a limited license to use the Website to display,
                download, or print content of the Website for your own personal
                and non-commercial use. You agree that you will not remove or
                modify any acknowledgements, credits or legal notices contained
                on the Website or in the content thereof. Any other use requires
                our prior written consent.
              </p>
              <p>
                Except as otherwise expressly stated herein, you may not alter,
                modify, copy, distribute (for compensation or otherwise),
                transmit, publicly display, publicly perform, reproduce, reuse,
                post, republish, license, frame, download, store for subsequent
                use, create derivative works of, transfer, or sell any of the
                materials on the Website, in whole or in part, without our prior
                written authorization.
              </p>
              <p>
                You may not use any robot, spider or other automatic device,
                process or means to access the Website for any purpose,
                including without limitation monitoring or copying any of the
                material on the Website or extracting or scraping any
                information made available by the Website.
              </p>
              <h5>Disclaimer of Warranties</h5>
              <p>
                YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS
                CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE
                WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY
                SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE ARE
                PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
                WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED
                INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES
                THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
              <p>
                NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR
                THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
                PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR
                SUCCESSORS MAKE ANY WARRANTY, REPRESENTATION, OR ENDORSEMENT
                WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY,
                SUITABILITY, ACCURACY, CURRENCY, OR AVAILABILITY OF THE WEBSITE
                OR ITS CONTENTS. WITHOUT LIMITING THE FOREGOING, NEITHER THE
                COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR
                RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
                PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR
                SUCCESSORS REPRESENT OR WARRANT THAT THE WEBSITE, ITS CONTENT,
                OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE
                WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
                DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT
                MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS.
              </p>
              <p>
                WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA
                AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE WEBSITE WILL
                BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND
                ENTIRELY RESPONSIBLE FOR YOUR USE OF THE WEBSITE AND YOUR
                COMPUTER, INTERNET, AND DATA SECURITY. TO THE FULLEST EXTENT
                PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
                CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED
                DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING, OR
                CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER
                TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
                EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
                MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS
                FOUND OR ATTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF
                ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
              </p>
              <h5>Limitation on Liability</h5>
              <p>
                EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO
                CIRCUMSTANCE WILL THE COMPANY NOR ITS PARENT, SUBSIDIARIES,
                AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES,
                AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES,
                SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS
                NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH,
                DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY
                DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
                PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL
                INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE,
                LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS
                OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
                TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, BREACH OF
                PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED
                OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR
                USE, OR INABILITY TO USE, OR RELIANCE ON, THE WEBSITE, ANY
                LINKED WEBSITES OR SUCH OTHER THIRD-PARTY WEBSITES, NOR ANY
                WEBSITE CONTENT, MATERIALS, POSTING, OR INFORMATION THEREON EVEN
                IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW.
              </p>
              <h5>Indemnification</h5>
              <p>
                To the maximum extent permitted by applicable law, you agree to
                defend, indemnify, and hold harmless the Company, its parent,
                subsidiaries, affiliates, and their respective directors,
                officers, employees, agents, service providers, contractors,
                licensors, suppliers, successors, and assigns from and against
                any claims, liabilities, damages, judgments, awards, losses,
                costs, expenses, or fees (including reasonable attorneys' fees)
                arising out of or relating to your breach of these Terms and
                Conditions or your use of the Website, including, but not
                limited to, third-party sites, any use of the Website's content,
                services, and products other than as expressly authorized in
                these Terms and Conditions.
              </p>
              <h5>Governing Law</h5>
              <p>
                The Website and the Terms of Use will be governed by and
                construed in accordance with the laws of the Province of Ontario
                and the federal laws of Canada applicable therein, without
                giving effect to any choice or conflict of law provision,
                principle, or rule and notwithstanding your domicile, residence,
                or physical location. Any action or proceeding arising out of or
                relating to this Website and under the Terms of Use will be
                instituted in the courts of the Province of Ontario and/or the
                Federal Court of Canada. Each party irrevocably submits the
                exclusive jurisdiction of such courts in any such action or
                proceeding. You waive any and all objections to the exercise of
                jurisdiction over you by such courts and to the venue of such
                courts.
              </p>

              <h5>Contacting Us</h5>
              <p className="mb-0">
                If you have any questions about the Website or these Terms and
                Conditions, you can email us at{" "}
                <strong>
                  <a
                    href="mailto:contact@permianchain.com"
                    style={{ color: "#333333" }}
                  >
                    contact@permianchain.com
                  </a>
                  .
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Terms;
