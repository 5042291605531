import React, { Component } from "react";
import Api from "../../services/api";
import SuccessImg from "../../assets/img/Success.png";
import { toast } from "react-toastify";
import "./Success.css";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";

let api;
class Success extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      session: "",
      paymentStatus: "",
      okayLoading: false,
    };
  }

  createCustomer = async () => {
    const { session, paymentStatus } = this.state;
    if (session) {
      const response = await api.create("miner/saveStripeBudgetPayment", {
        session: session,
        payment_status: paymentStatus,
      });
      if (response.code === 200) {
        toast.success(response.message);
        this.setState({ okayLoading: false });
      }
    }
  };

  completeOfftakeOrBuy = async (pending_id) => {
    const { session, paymentStatus } = this.state;
    if (session) {
      const response = await api.create("miner/saveStripeDecPayment", {
        pending_id: pending_id,
        payment_status: paymentStatus,
      });
      if (response.code === 200) {
        toast.success(response.message);
        this.setState({ okayLoading: false });
      }
    }
  };

  getSessionDetails = async () => {
    this.setState({ okayLoading: true });
    const urlData = window.location.href;
    const urlArr = urlData.split("?");
    const urlQuery = urlArr[1];
    if (!urlQuery) {
      this.props.history.push("/wallets");
    } else {
      // If this is off-take or DEC buy type (paymentSource)
      // will encode the pending listing as DEC-<listing _id>
      const sessionArr = urlQuery.split("=");
      const exSessionArr = sessionArr[1];
      const filteredSessionArr = exSessionArr.split("&");
      const session = filteredSessionArr[0];
      const paymentSource = sessionArr[sessionArr.length - 1];
      const pending_id = (paymentSource.startsWith("DEC-") ? paymentSource.split("-")[1] : undefined);
      this.setState({ paymentSource });
      if (session) {
        this.setState({ session });
        const response = await api.get("miner/checkout-session", {
          session: session,
        });
        if (response.code === 200) {
          if (response.data.payment_status === "paid") {
            this.setState({ paymentStatus: true });
          } else {
            this.setState({ paymentStatus: false });
          }
        }
      }
      if (this.state.paymentSource === "budget") {
        this.createCustomer();
      } else if (this.state.paymentSource.startsWith("DEC-")) {
        this.completeOfftakeOrBuy(pending_id);
      } else {
        this.setState({ okayLoading: false });
      }
    }
  };

  componentDidMount() {
    pageProgress("remove");
    toast.success("Please wait saving the transaction details!");
    this.getSessionDetails();
  }

  render() {
    const { okayLoading } = this.state;

    return (
      <div className="buy-xpr-container ">
        <div className="content-i operation_page_main dashboard-page-main">
          <div className="content-box">
            <div className="succes-container p-3">
              <div className="text-center">
                <img className="success-img" src={SuccessImg} alt="success" />
              </div>
              <div className="w-100 mt-4">
                <h5 className="success-head text-center pl-2 pr-2 ">
                  {this.state.paymentSource === "budget"
                    ? "Payment successfully Requested!"
                    : "Congratulations, your purchase is successful. Your DEC tokens will be credited to your DEC wallet balance shortly after verification of the transaction by your PermianChain Administrator."}
                </h5>
              </div>

              <div className="text-center mt-3">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push("/wallets");
                  }}
                  disabled={okayLoading}
                >
                  Okay
                  {okayLoading && (
                    <i className="fa-spin fa fa-spinner text-white ml-2" />
                  )}
                </button>
              </div>
              <div className="text-center mt-3">
                <span className="support-text gray pl-2 pr-2">
                  For support you may contact support@permianchain.com or use
                  the live chat below.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUserInfo: state.user.userInfo,
});

export default connect(mapStateToProps)(Success);
