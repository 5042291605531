import React from "react";
import Footer from "./Footer";
import { ToastContainer } from "react-toastify";
import investorLogoImg from "../../assets/img/miner-w.png";
import { Link } from "react-router-dom";

class TermsBasePages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { currentLocation } = this.props;
    return (
      <div
        className={`basepage-main-container ${currentLocation}_wrapper_container`}
      >
        <div className="user-dashboard-header-bg-color header-fixed-menu">
          <div
            className="terms-topbar padding-10 text-center"
            style={{ display: "block" }}
          >
            <Link to="/">
              <img
                className="customLogo"
                src={investorLogoImg}
                height={70}
                alt="Miner_Logo"
              />
            </Link>
          </div>
        </div>
        <div className="min-height-90vh terms-center-content">
          {this.props.children}
        </div>
        <div className="footer footer-containerr basepage-footer-containerr">
          <Footer color="black" />
        </div>
        <ToastContainer />
      </div>
    );
  }
}
export default TermsBasePages;
