import React, { Component } from "react";
import Modal from "react-responsive-modal";

class DepositModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      amount: "",
      show: false,
      timeHorizon: "",
      paymentRequestButtonLoading: false,
      earningData: [],
      fileName: "MinerData",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.setShow = this.setShow.bind(this);
    this.onChangeTimeHorizon = this.onChangeTimeHorizon.bind(this);
    this.clearTime = this.clearTime.bind(this);
  }

  async setShow(startDate, endDate) {
    if (startDate && endDate) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }
  async clearTime() {
    this.setState({ startDate: "" });
    this.setState({ endDate: "" });
    this.setState({ timeHorizon: "" });
  }

  async onChangeTimeHorizon(time) {
    this.setState({
      timeHorizon: time,
    });
  }
  async onChangeAddress(e) {
    this.setState(
      {
        address: e.target.value,
      },
      () => {
        this.setShow(this.state.address, this.state.endDate);
      }
    );
  }
  async onChangeAmount(e) {
    this.setState(
      {
        amount: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
      }
    );
  }
  async onSubmit() {}

  render() {
    const { depositModalOpen, depositOnCloseModal, buttonLoading } = this.props;

    const buttonDisabled = buttonLoading === true ? true : false;

    return (
      <Modal
        open={depositModalOpen}
        onClose={depositOnCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <h4
                      className=""
                      style={{ alignSelf: "flex-start !important" }}
                    >
                      Deposit BTC???
                    </h4>
                    <div className="form-group">
                      <label htmlFor="title">
                        Amount<span className="required-field">*</span>
                      </label>
                      <input
                        className="form-control w-100 "
                        id="amount"
                        type="number"
                        placeholder="Enter Amount"
                        value={this.state.amount}
                        onChange={this.onChangeAmount}
                      />
                    </div>
                    <div className="d-flex justify-content-end mt-4">
                      <button
                        className="btn modal-button cancel-button"
                        type="button"
                        onClick={depositOnCloseModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn modal-button update-button"
                        type="button"
                        disabled={buttonDisabled}
                      >
                        {" "}
                        Deposit{" "}
                        {this.state.paymentRequestButtonLoading && (
                          <i className="fa-spin fa fa-spinner text-white m-1" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default DepositModal;
