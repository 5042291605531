let backendHost = "http://localhost:3000";
let frontendHost = "http://localhost:8085";
let network = "https://api-kovan.etherscan.io/api";
let networkEnviroment = "kovan";
// let priceKey = "price_1KT2GCB0KxQtg04wv1Sygz5h"; //test price id for expiry
let priceKey = "price_1K7HYUB0KxQtg04wcYRMrBdE"; // for local host
let contractAddress = "0xa78fE1e7829c20670d8F3f0F7784E28D74355Ffc";
let recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
let fbPageId = "102683751657734";
let fbAppId = "549470799477280";
if (process.env.REACT_APP_NODE_ENV === "production") {
  priceKey = "price_1KBjNQFNDiMaOrJqkF3QcyLs";
  frontendHost = "https://miner.permianchain.com";
  contractAddress = "0xa6D7BCc3c352700d891DA2B5DCaf2fa7A2A0c383";
  networkEnviroment = "mainnet";
  fbPageId = "102683751657734";
  fbAppId = "549470799477280";
  backendHost = "https://masterapi.permianchain.com";
  network = "https://api.etherscan.io/api";
  recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
} else if (process.env.REACT_APP_NODE_ENV === "test") {
  priceKey = "price_1K7HYUB0KxQtg04wcYRMrBdE";
  frontendHost = "https://privateminer.permianchain.com";
  fbPageId = "102683751657734";
  fbAppId = "549470799477280";
  contractAddress = "0xa78fE1e7829c20670d8F3f0F7784E28D74355Ffc";
  network = "https://api-kovan.etherscan.io/api";
  networkEnviroment = "kovan";
  backendHost = "https://api.permianchain.com";
  recaptchaSiteKey = "6LdZLrIUAAAAAEye5q5ECwRb_TNrSo0hSeHdY8oq";
}

export const API_NETWORKENVIROMENT = `${networkEnviroment}`;
export const PRICE_KEY = `${priceKey}`;
export const API_CONTRACTADDRESS = `${contractAddress}`;
export const FRONT_HOST = `${frontendHost}`;
export const FACEBOOK_PAGE_ID = `${fbPageId}`;
export const FACEBOOK_APP_ID = `${fbAppId}`;
export const API_NETWORK = `${network}`;
export const API_ROOT = `${backendHost}`;
export const RECAPTCHASITEKEY = `${recaptchaSiteKey}`;
export const tokenSymbol = "NRT";
export const GOOGLE_MAP_KEY = "AIzaSyDPMb_HpWEBLDjUvlL9LCq6SX-4NjySefY";
export const IMAGE_VERSION = 3;
