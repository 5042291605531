import React from "react";
const WireTooltip = () => {
  return (
    <div className="wireTooltip">
      <span>Wire Transfer</span>
    </div>
  );
};

export default WireTooltip;
