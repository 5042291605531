import React, { Component } from "react";
import Modal from "react-responsive-modal";
import Api from "../../services/api";
import _ from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { niceNumberDecimalDisplay } from "../../utils/Util";
import { toast } from "react-toastify";
import "./modals.css";

let api;

class WithdrawBTCModal extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      address: "",
      amount: "",
      decAmount: "",
      invoiceId: this.props.invoiceId,
      buttonDisabled: true,
      walletAddress: "",
      show: false,
      timeHorizon: "",
      recieverAddress: "",
      paymentRequestButtonLoading: false,
      earningData: [],
      fileName: "MinerData",
    };
  }
  openSuccess = () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire("Withdraw Successfully Requested!", "", "success");
  };
  changecRecieverAddress = (event) => {
    this.setState({ recieverAddress: event.target.value });
  };

  componentDidMount = async () => {
    this.setState({ amount: this.props.amount });
    const response = await api.create("miner/getPaymentCredentials", {
      paymentMode: "btc",
    });
    if (response.code === 200) {
      this.setState({ walletAddress: response.data[0].BTCWallet });
    }
  };

  showWithdraw = async (address, amount) => {
    if (address && amount) {
      this.setState({ buttonDisabled: false });
    } else {
      this.setState({ buttonDisabled: true });
    }
  };
  getAllBalance = (value) => {
    this.setState({
      amount: value,
    });
  };
  clearTime = () => {
    this.setState({ startDate: "" });
    this.setState({ endDate: "" });
    this.setState({ timeHorizon: "" });
  };

  onChangeTimeHorizon = (time) => {
    this.setState({
      timeHorizon: time,
    });
  };
  onChangeAddress = (e) => {
    this.setState(
      {
        address: e.target.value,
      },
      () => {
        this.showWithdraw(this.state.address, this.state.amount);
      }
    );
  };
  onChangeAmount = (e) => {
    this.setState({
      amount: e.target.value,
    });
  };
  onChangedecAmount = (e) => {
    this.setState({
      decAmount: e.target.value,
    });
  };
  onSubmit = async () => {
    this.props.withdrawOnCloseModal();
    this.props.depositOnCloseModal();
    const { walletAddress, amount, invoiceId } = this.state;
    if (amount > 0) {
      const response = await api.create("miner/BtcPayment", {
        address: walletAddress,
        amount,
        invoiceId,
      });
      if (!_.isUndefined(response)) {
        if (response.code === 200) {
          this.openSuccess();
          this.props.getRecords(this.props.reference);
        } else {
          toast.error(response.message);
        }
      }
    } else {
      toast.error(`Invalid Amount!`);
    }
  };

  render() {
    const {
      btcModalOpen,
      withdrawOnCloseModal,
      amountUsd,
      senderWalletAddress,
    } = this.props;
    const { walletAddress, amount } = this.state;
    return (
      <Modal
        open={btcModalOpen}
        onClose={withdrawOnCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <div>
                      <h4
                        className="text-black"
                        style={{ alignSelf: "flex-start !important" }}
                      >
                        BTC Payment
                      </h4>
                      <div className="form-group text-black">
                        <label htmlFor="title">
                          Receiving Address
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control w-100"
                          id="address"
                          type="text"
                          placeholder="Enter Address"
                          value={walletAddress}
                          disabled={true}
                        />
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="title">
                          Sender Address
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control w-100"
                          id="address"
                          type="text"
                          placeholder="Enter Address"
                          value={senderWalletAddress}
                          disabled={true}
                        />
                      </div>

                      <div className="form-group text-black">
                        <label htmlFor="title">
                          Amount<span className="required-field">*</span>
                        </label>
                        <div className="">
                          <input
                            className="form-control"
                            id="amount"
                            type="number"
                            placeholder="Enter Amount"
                            value={amount}
                            disabled={true}
                            onChange={this.onChangeAmount}
                          />
                        </div>
                      </div>

                      <div className="container-right gray">
                        <span className="gray">
                          Amount USD: $ {niceNumberDecimalDisplay(amountUsd, 2)}
                        </span>
                      </div>
                      <div className="d-flex justify-content-end mt-4 ">
                        <button
                          className="btn modal-button cancel-button mr-2"
                          type="button"
                          onClick={withdrawOnCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn modal-button update-button"
                          type="button"
                          onClick={this.onSubmit}
                        >
                          {" "}
                          Pay{" "}
                          {this.state.paymentRequestButtonLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default WithdrawBTCModal;
