import React from "react";
import QRCode from "react-qr-code";

const BarCodeGenerator = ({ dynamicValue }) => {
  return (
    <div className="App">
      <QRCode
        style={{ width: "150px", height: "150px" }}
        value={dynamicValue}
      />
    </div>
  );
};

export default BarCodeGenerator;
