import React, { Component } from "react";
import { Link } from "react-router-dom";
import { pageProgress } from "../../utils/Util";

class OrderPlaced extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    pageProgress("force_remove");
  }

  render() {
    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="buy-container">
              <div className="row pb-5 pt-5">
                <div className="align-items-center col-md-12 d-flex justify-content-center">
                  <div className="check-round vertical-center">
                    <i className="fa fa-check" aria-hidden="true"></i>
                  </div>
                  <h1 className="mt-2 pb-1 ml-3">Order Placed</h1>
                </div>
              </div>
              <div className="row pb-5 pt-5">
                <div className="col-md-12 ">
                  <p className="order-msg text-center mb-0">
                    Your order has been successfully placed.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center align-items-center mt-5 pb-5">
                <div class="col-md-12 order_button_left">
                  <div className="col-md-6 order_button_left_top">
                    <Link to="/dashboard">
                      <button className="btn buy-btn w-250 position-relative h-50">
                        <span className="color-white white-lighter-text">
                          Back to Dashboard
                        </span>
                      </button>
                    </Link>
                  </div>
                  <div className="col-md-6 ">
                    <button className="btn buy-btn w-250 position-relative h-50">
                      <span className="white-lighter-text">
                        Place New Order
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderPlaced;
