import * as React from "react";
import { PDFExport } from "@progress/kendo-react-pdf";
import BarCodeGenerator from "./BarCodeGenerator";
import PerChainTextLogo from "../../assets/img/logo-powered-white.png";
import "./modals.css";

export const ExportPdf = ({ decSecretKey, decWalletAddress }) => {
  const pdfExportComponent = React.useRef(null);
  return (
    <div>
      <div className="example-config">
        <button
          className="btn btn-primary"
          onClick={() => {
            if (pdfExportComponent.current) {
              pdfExportComponent.current.save();
            }
          }}
        >
          Print
        </button>
      </div>

      <div
        style={{
          position: "absolute",
          left: "-2000px",
          top: 0,
        }}
      >
        <PDFExport paperSize="A4" margin="0cm" ref={pdfExportComponent}>
          <div
            style={{
              width: "600px",
            }}
          >
            <div className="pdfHeader">
              <img
                style={{
                  width: "250px",
                  height: "150px",
                }}
                src={PerChainTextLogo}
                alt=""
              />
            </div>
            <div
              display="flex"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "120px",
                    height: "120px",
                    marginTop: "50px",
                    marginBottom: "10px",
                  }}
                >
                  <BarCodeGenerator dynamicValue={decWalletAddress} />
                </div>
                <div
                  style={{
                    width: "150px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "10px",
                      textAlign: "center",
                    }}
                  >
                    Public Address:
                  </div>
                </div>
                <div
                  style={{
                    borderTop: "1px solid #4A6AC2",
                    borderBottom: "1px solid #4A6AC2",
                    fontSize: "12px",
                  }}
                >
                  {decWalletAddress}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "150px",
                    height: "150px",
                    marginTop: "30px",
                    marginBottom: "10px",
                  }}
                >
                  <BarCodeGenerator dynamicValue={decSecretKey} />
                </div>
                <div
                  style={{
                    width: "150px",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "10px",
                      textAlign: "center",
                    }}
                  >
                    Private Key:
                  </div>
                </div>
                <div
                  style={{
                    borderTop: "1px solid #4A6AC2",
                    borderBottom: "1px solid #4A6AC2",
                    fontSize: "12px",
                  }}
                >
                  {decSecretKey}
                </div>
              </div>
              <div
                style={{
                  padding: "0px 20px",
                  marginTop: "10px",
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: "9px" }}>
                  DISCLOSURE:&nbsp;
                </span>
                <span style={{ fontSize: "9px" }}>
                  &nbsp; A private key is a sophisticated form of cryptography
                  that allows a user to access their digital assets (i.e.
                  cryptocurrency). The only way to recover your wallet and the
                  digital assets stored on your wallet is by using the private
                  key.If the private key(s) are lost, you may completely lose
                  access to the corresponding digital assets and its value may
                  be lost. Make sure to keep this document in a safe place where
                  it cannot be stolen or damaged. We recommend to also have a
                  backup copy stored in a separate location. We do not recommend
                  to store this document on you computer or mobile device as
                  such devices may be stolen or hacked by a third-party which
                  may result in access to your private key. PermianChain
                  Technologies Inc. will not be responsible for any lost or
                  stolen private keys.
                </span>
              </div>
            </div>
            <div></div>

            <p></p>
          </div>
        </PDFExport>
      </div>
    </div>
  );
};
