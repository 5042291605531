import React from "react";
import Api from "../../services/api";
import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";
let api;

class EmailVerify extends React.Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {};
  }

  async componentDidMount() {
    const verificationCode = this.props.match.params.id;
    pageProgress("display");
    try {
      const response = await api
        .setToken(verificationCode)
        .get("user/emailVerify");
      if (response.code === 200) {
        toast.success(response.message);
      }
    } catch (e) {
      toast.error(messageConstants.SOMETHING_WENT_WRONG);
    }
    this.props.history.push("/signin");
  }

  render() {
    return <div className="register-container"></div>;
  }
}

export default EmailVerify;
