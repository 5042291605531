import React from "react";

const TableHeaderTitle = ({ title, unit }) => {
  return (
    <div className="text-center">
      <div>{title}</div>
      <div>({unit})</div>
    </div>
  );
};

export default TableHeaderTitle;
