import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import Api from "../../services/api";
import { Link } from "react-router-dom";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";

let api;

class KycSubmit extends React.Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      error: null,
      buttonLoading: false,
      apiError: "",
    };
    this.saveKycDetails = this.saveKycDetails.bind(this);
  }

  componentWillMount() {
    document.title =
      messageConstants.KYC_SUBMIT_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("display");
  }

  componentDidMount() {
    pageProgress("remove");
  }

  async saveKycDetails(event) {
    event.preventDefault();
    this.setState({
      buttonLoading: true,
    });
    const response = await api.create("user/kycMinerSubmit");
    if (!_.isUndefined(response) && response.code === 200) {
      this.props.history.push("/kyc_status/pending");
      toast.success(response.message);
    } else {
      this.setState({
        buttonLoading: false,
      });
      toast.error(response.message);
    }
  }

  render() {
    const { buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <div className="kyc-container kyc-document-container">
        <h3 className="text-center kyc-header standardColorIcon">Submit KYC</h3>
        <div className="row">
          <div className="col-md-1" />
          <div className="col-md-12">
            <div className="row mt-3 progress-steps-container">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                {/*<Steps labelPlacement="vertical" current={2}>
                  <Step title="Fill Details"  />
                  <Step title="Upload" />
                  <Step title="Submit"  />
                </Steps>*/}

                <ul className="progressbar d-flex p-0 justify-content-center">
                  <li className="standardColorIcon">Fill Details</li>
                  <li className="standardColorIcon">Upload</li>
                  <li className="active standardColorIcon">Submit</li>
                </ul>
              </div>
              <div className="col-md-2"></div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <div className="element-wrapper p-4">
                  <div className="element-box custom-element-box">
                    <p>Note:</p>
                    <p>
                      KYC seeks a process of collecting pertinent, identifying
                      information about the users of a service. In applying KYC,
                      the platform or company providing the service will compel
                      all users to submit (internationally recognizable)
                      identify documents.
                    </p>

                    <p>
                      Although KYC is seen as “soft” or civil regulation, the
                      protocols are in fact laws, enforced by industry bodies
                      and law enforcement alike. The core purpose of KYC is to
                      make sure people are disqualified to partake of a service
                      – either through being underage, illegal resident or
                      criminally motivated – remain precluded from
                      participation.
                    </p>

                    <p>
                      KYC also generates a database that is useful to both
                      parties. Law enforcement can identify and pursue criminals
                      or terminate the service provision to minors, but users
                      can also employ the database to validate their presence
                      and participation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 pr-4 pl-4">
              <div className="col-md-12 text-right ">
                <Link to="/dashboard">
                  <span className="btn cancel-button mr-2 p-2 pl-1 pr-1">
                    Do it later
                  </span>
                </Link>{" "}
                <Link to="/kyc_upload" className="color-white">
                  <button
                    className="btn btn-primary permian-button mr-3"
                    type="button"
                  >
                    Previous
                  </button>
                </Link>
                <button
                  className="btn btn-primary permian-button "
                  type="submit"
                  onClick={this.saveKycDetails}
                  disabled={buttonDisabled}
                >
                  Submit{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white m-1" />
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-1" />
        </div>
      </div>
    );
  }
}

export default KycSubmit;
