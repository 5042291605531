import React from "react";
const BudgetTooltip = () => {
  return (
    <div className="walletTooltip1" style={{ whiteSpace: "normal" }}>
      <span>
        This record shows a list of all your ASIC purchases from the ASIC Budget
        balance.
      </span>
    </div>
  );
};

export default BudgetTooltip;
