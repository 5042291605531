import React from "react";

const PaybackTooltip = () => {
  return (
    <div className="payback-tooltip">
      The Rate of Return and Payback are notional results that fluctuate based
      on your Net Earnings (USD) which are based on the real-time BTC/USD
      exchange rate. Other factors include, but not limited to power cost,
      hashrate, efficiency, block reward and difficulty rate.
    </div>
  );
};

export default PaybackTooltip;
