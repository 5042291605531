import React, { Component } from "react";
import Modal from "react-responsive-modal";
import Api from "../../services/api";
import { API_CONTRACTADDRESS } from "../../services/api-config";
import { niceNumberNoDecimalDisplay } from "../../utils/Util";
import { sendTokens } from "../../utils/Util";
import { toast } from "react-toastify";
import "./modals.css";

let api;
class DecModal extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      decAddress: "",
      amountinUSD: "",
      discountAmount: "",
      decTokens: "",
    };
  }
  sendTokens = async (
    listedTokenContractAddress,
    receivingEthAddress,
    tokens
  ) => {
    try {
      await window.ethereum.enable();
      if (listedTokenContractAddress && receivingEthAddress && tokens) {
        const response = await sendTokens(
          listedTokenContractAddress,
          receivingEthAddress,
          tokens
        );
        if (response && response.hash) {
          this.props.setInvoiceLoading();
          const saveDetailsResponse = await api.create(
            "miner/decInvoicePayment",
            {
              reqInvoiceId:
                this.props.invoiceId.invoiceId &&
                this.props.invoiceId.invoiceId._id,
              txHash: response.hash,
              amount: this.state.discountAmount,
            }
          );
          this.props.getRecords(this.props.reference);
          if (saveDetailsResponse.code === 200) {
            toast.success(saveDetailsResponse.message);
          } else {
            toast.error(saveDetailsResponse.message);
          }
        }
        this.setState({ decAmount: "", recieverAddress: "" });
      } else {
        const erroMsg = !receivingEthAddress
          ? "User's wallet address is not whitelisted"
          : !tokens || tokens <= 0
          ? "Please enter a valid token."
          : "";
        toast.error(erroMsg);
        this.props.getRecords(this.props.reference);
        this.setState({ decAmount: "", recieverAddress: "" });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getDecInfo = async () => {
    try {
      const { invoiceId } = this.props;
      const userResponse = await api.get("miner/decTokenDetails", {
        symbol: "DEC",
      });
      if (
        userResponse.code === 200 &&
        userResponse.data &&
        userResponse.data.tokenDetail
      ) {
        this.setState({
          decAddress: userResponse.data.tokenDetail.receivingAddress,
          amountinUSD: invoiceId.invoiceId.raisedAmountinUSD,
          decTokens: invoiceId.invoiceId.raisedAmountinDEC,
          discountAmount: invoiceId.invoiceId.raisedAmountInUsdAfterDecDiscount,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  async componentDidMount() {
    this.getDecInfo();
  }
  render() {
    const { onCloseDecModal, decModalOpen, depositOnCloseModal, invoiceId } =
      this.props;
    const { decAddress } = this.state;
    return (
      <Modal
        open={decModalOpen}
        onClose={onCloseDecModal}
        showCloseIcon={false}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container h-55",
        }}
      >
        <div className="onboarding-modal w-100">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <div>
                      <h4
                        className="text-black"
                        style={{ alignSelf: "flex-start !important" }}
                      >
                        DEC Payment
                      </h4>
                      <h6
                        className="theme-color mb-3"
                        style={{ alignSelf: "flex-start !important" }}
                      >
                        Congratulations! you are going to have{" "}
                        {invoiceId.invoiceId && invoiceId.invoiceId.decDiscount}
                        % Discount
                      </h6>
                      <div className="form-group text-black">
                        <label htmlFor="title">
                          Receiving Address
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control w-100"
                          id="address"
                          type="text"
                          placeholder="Enter Address"
                          value={decAddress}
                          disabled={true}
                        />
                      </div>

                      <div className="form-group text-black">
                        <label htmlFor="title">
                          Amount
                          <span className="required-field">*</span>
                        </label>
                        <div className="">
                          <input
                            className="form-control"
                            id="amount"
                            type="text"
                            placeholder="Enter Amount"
                            value={`$ ${niceNumberNoDecimalDisplay(
                              this.state.amountinUSD,
                              2
                            )}`}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="title">
                          Discounted Amount
                          <span className="required-field">*</span>
                        </label>
                        <div className="">
                          <input
                            className="form-control"
                            id="amount"
                            type="text"
                            placeholder="Enter Amount"
                            value={`$ ${niceNumberNoDecimalDisplay(
                              this.state.discountAmount,
                              2
                            )}`}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="title">
                          DEC Tokens<span className="required-field">*</span>
                        </label>
                        <div>
                          <input
                            className="form-control"
                            id="amount"
                            type="number"
                            placeholder="Enter Amount"
                            value={`${niceNumberNoDecimalDisplay(
                              this.state.decTokens,
                              0
                            )}`}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="form-group text-black">
                        <span
                          className="dec-info-text form-control"
                          style={{ fontSize: "14px" }}
                        >
                          <span className="text-bold">Note: </span> Gas Fees not
                          included. You can approve or reject the Gas Fees from
                          your MetaMask account after clicking ‘Pay’. Lowest Gas
                          Fees are estimated between time 5:30 pm UTC and 11:30
                          pm UTC.
                        </span>
                      </div>
                      <div className="d-flex justify-content-end mt-4 ">
                        <button
                          className="btn modal-button cancel-button mr-2"
                          type="button"
                          onClick={() => {
                            onCloseDecModal();
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn modal-button update-button"
                          type="button"
                          onClick={() => {
                            onCloseDecModal();
                            depositOnCloseModal();
                            this.sendTokens(
                              API_CONTRACTADDRESS,
                              this.state.decAddress,
                              this.state.decTokens
                            );
                          }}
                          disabled={
                            this.state.decAddress &&
                            this.state.amountinUSD &&
                            this.state.discountAmount &&
                            this.state.decTokens
                              ? false
                              : true
                          }
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default DecModal;
