import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import trunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { rootReducer } from "./reducers";

export const history = createBrowserHistory();

export const store = createStore(
  rootReducer(history),
  composeWithDevTools(applyMiddleware(trunk))
);
