import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import {
  niceNumberNoDecimalDisplay,
  hashrateConverter,
  hashrateGraphConverter,
  getBtcUsd,
  upgradeAlert,
  pageProgress,
} from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import Api from "../../services/api";
import moment from "moment";
import _ from "lodash";
import { fetchUserDetails } from "../../store/actions/UserAction";
import PaybackTooltip from "./PaybackTooltip";
import { connect } from "react-redux";
import "./dashboard.css";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import alertIcon from "../../assets/img/alert.svg?v3";
import { easeQuadInOut } from "d3-ease";
import { Link } from "react-router-dom";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import { API_NETWORK, API_CONTRACTADDRESS } from "../../services/api-config";
import { DEC_TO_USD } from "../../utils/constant";
import RateOfReturnTooltip from "./RateOfReturnTooltip";

let api;

am4core.useTheme(am4themes_animated);
am4core.options.autoSetClassName = true;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      tenMinHashRate: "",
      userName: "",
      depositModalOpen: false,
      isAgreementSubmitted: "",
      userEmail: "",
      alerComponent: "",
      hashrateGraphValue: "day",
      kycStatus: "",
      rateOfElectricity: 0,
      statisticsLoading: false,
      timeHorizon: "current",
      powerTimeHorizon: "24h",
      currency: "DEC",
      grossMinerYesterdaysData: null,
      grossMinerAllDaysData: null,
      totalInvestments: 0,
      averageActiveWorkers: 0,
      decDiscountPercentage: 0,
      shouldShowSubscriptionAlert: false,
    };
    this.showSubscriptionAlert = this.showSubscriptionAlert.bind(this);
  }

  getGraphStats = async (data, value) => {
    return Promise.all(
      data.map(async (chartEntityDetails) => {
        const newDate = moment(chartEntityDetails.timestamp * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const hashRateDisp = hashrateGraphConverter(
          chartEntityDetails.hashrate,
          value
        );
        if (hashRateDisp > 0) {
          return {
            date: newDate,
            visits: parseFloat(hashRateDisp),
            unit: value,
          };
        }
      })
    );
  };

  async componentDidMount() {
    document.title =
      messageConstants.DASHBOARD_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    const btcToUSD = await getBtcUsd();
    this.setState({
      btcToUSD: parseFloat(btcToUSD),
      originalBtcToUSD: parseFloat(btcToUSD),
    });
    await this.setStatistics();
    pageProgress("remove");
    await this.getDecDiscount();
    await this.getRealStatistics();
    await this.initChart();
    if (this.props.location.search.includes("showSubscriptions")) {
      this.setState({ shouldShowSubscriptionAlert: true });
    }
    const { fetchUserDetails, authToken } = this.props;
    fetchUserDetails(async (userDetails) => {
      await this.getWalletBalance(userDetails);
      this.setState(
        {
          userName: userDetails.fullName,
          userEmail: userDetails.email,
          isAgreementSubmitted: userDetails.isAgreementSubmitted,
          kycStatus: userDetails.kycStatus,
        },
        () => {
          this.showAlert();
          this.setState({
            depositModalOpen:
              this.state.isAgreementSubmitted === false ? true : false,
          });
          if (upgradeAlert(this.state.userEmail)) {
            toast.success(
              "Your Subscription will be upgraded automatically on 1 Feb, 2022"
            );
          }
        }
      );
    });
    if (
      authToken &&
      localStorage &&
      !localStorage.getItem("hardReloadStatus")
    ) {
      this.hardReloadAlert();
    }
  }

  async getWalletBalance(userDetails) {
    try {
      if (userDetails.receivingEthAddress) {
        const headers = {
          headers: "",
        };
        const decBalance = await axios.get(
          `${API_NETWORK}?module=account&action=tokenbalance&contractaddress=${API_CONTRACTADDRESS}&address=${userDetails.receivingEthAddress}&tag=latest&apikey=APJX4Q44UGSK2EUNQYUY31YSW8UGPY2D6V`,
          headers
        );
        if (decBalance.status === 200) {
          const energyCurrencyInDEC =
            Math.ceil(Number(decBalance.data.result)) / Math.pow(10, 18);
          this.setState({ energyCurrencyInDEC });
        }
      }
      if (userDetails.btcWalletAddress) {
        const response = await api.get("miner/checkBalanceBtc");
        if (response.code === 200 && response.data && response.data.Balance) {
          this.setState({
            walletBalanceInBTC: response.data.Balance.available,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  hardReloadAlert = () => {
    const question =
      'A new version of PermianChain Miner is now available. Click  the "Okay" button below and then press "CTRL + SHIFT + R" on your keyboard to update to the latest version';

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <h3 className="text-bold text-center">{question}</h3>
              <div className="text-center mt-3">
                <button
                  className="c-button"
                  onClick={() => {
                    const hardReloadStatus = { hardReloadStatus: "true" };
                    localStorage.setItem(
                      "hardReloadStatus",
                      JSON.stringify(hardReloadStatus)
                    );
                    onClose();
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        );
      },
      closeOnClickOutside: false,
      closeOnEscape: false,
    });
  };

  onCloseModal = (check) => {
    if (check === true) {
      this.setState({ depositModalOpen: false });
    } else {
      toast.error("Please Accept the Terms and Condition");
    }
  };

  initChart = async () => {
    const { hashrateGraphValue } = this.state;
    let chart = am4core.create("chartdiv", am4charts.XYChart);
    chart.paddingRight = 20;
    chart.data = await this.generateChartData();
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.baseInterval = {
      timeUnit: hashrateGraphValue ? "days" : "hours",
      count: 1,
    };

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "visits";
    series.dataFields.extra = "unit";
    series.tooltipText =
      "Hashrate: [bold]{valueY}[/] {extra} \n Date: [bold]{date}";
    series.fillOpacity = 0.3;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.series.push(series);
  };

  generateChartData = async () => {
    const hashUnits = ["EH/s", "PH/s", "TH/s", "GH/s", "MH/s", "KH/s", "H/s"];
    const { hashrateGraphValue } = this.state;
    let chartData = [];
    const unitArr = [];
    try {
      const response = await api.get("user/hashrate/graph", {
        hashrateGraphValue,
      });

      if (response.code === 200) {
        const tempData = !_.isEmpty(response.data.graphData)
          ? response.data.graphData
          : [];
        chartData = tempData.map(async (chartEntityDetails) => {
          const newDate = moment(chartEntityDetails.timestamp * 1000).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          const hashRateDisp = hashrateConverter(
            chartEntityDetails.hashrate,
            true
          );
          const phHash = hashrateConverter(chartEntityDetails.hashrate);
          const getHashArr = phHash.split(" "); // here phHash is like "45.35 TH/s" and we are splitting it to push it in the unitArr[].
          unitArr.push(getHashArr[1]); // getting the array like ["PH/s","TH/s",..] so that we can make the graph according the units by checking the units.
          if (hashRateDisp > 0) {
            return {
              date: newDate,
              visits: parseFloat(hashRateDisp),
            };
          }
        });
        for (let i = 0; i < hashUnits.length - 1; i++) {
          if (unitArr.includes(hashUnits[i])) {
            chartData = await this.getGraphStats(tempData, hashUnits[i]);
            return chartData;
          }
        }
        return chartData;
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  showAlert = () => {
    const alert = (
      <div className="alert alert-warning mt-3 kyc-alert-container">
        <div className="d-flex">
          <div>
            <img height="30" className="mr-2" src={alertIcon} alt="kyc alert" />
          </div>
          <div>
            <span className="text-black">
              Please submit <span className="text-bold">KYC</span> to verify
              your account and enjoy uninterrupted services.
            </span>
          </div>
        </div>
        <div>
          <span
            className="ml-2 alert-orange-btn"
            onClick={() => {
              this.props.history.push("/kyc");
            }}
          >
            Submit KYC
          </span>
        </div>
      </div>
    );
    this.setState({ alerComponent: alert });
  };
  showSubscriptionAlert = () => {
    return (
      <div className="alert alert-warning mt-3 kyc-alert-container">
        <div className="d-flex">
          <div>
            <img height="30" className="mr-2" src={alertIcon} alt="kyc alert" />
          </div>
          <div>
            <span className="text-black">
              <p>
                Automate your monthly invoice payments with a Power Pack
                subscription to avoid service interruptions.{" "}
              </p>
              <p>
                Invoices are due within 24 hours of issuance. Late payments can
                result in bitcoin deductions.
              </p>
            </span>
          </div>
        </div>
        <div>
          <span
            className="ml-2 alert-orange-btn"
            onClick={() => {
              this.props.history.push("/subscriptions");
            }}
          >
            Subscribe
          </span>
          <span
            className="ml-2 alert-orange-btn"
            onClick={() => {
              this.setState({ shouldShowSubscriptionAlert: false });
            }}
          >
            I’ll pay on time
          </span>
        </div>
      </div>
    );
  };

  getDecDiscount = async () => {
    try {
      const { code, data } = await api.get("miner/decTokenDetails", {
        symbol: "DEC",
      });
      if (code === 200 && data.tokenDetail) {
        this.setState({
          decDiscountPercentage: data.tokenDetail.discount,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getRealStatistics = async () => {
    try {
      const response = await api.get("user/miner/minerRealTimeStatistics");
      if (response.code === 200 && response.data) {
        this.setState({
          validNum: response.data.active_workers || 0,
          invalidNum: response.data.unactive_workers || 0,
          repairingNum: response.data.repairing_workers || 0,
          tenMinHashRate: hashrateConverter(response.data.hashrate_10min),
          oneHourHashRate: hashrateConverter(response.data.hashrate_1hour),
          dayHashRate: hashrateConverter(response.data.hashrate_24hour),
          rateOfElectricity: response.data.rateOfElectricity,
          averagePowerConsumptionPerDay:
            response.data.averagePowerConsumptionPerDay,
          averageActiveWorkers: response.data.averageActiveWorkers,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  convertBTCToUSD = (data, rate) => {
    if (!data) {
      return 0;
    }
    const btcToUSD = this.state.originalBtcToUSD;
    const poolFees = (parseFloat(data.poolFees) * btcToUSD) / rate;
    const powerCost = (parseFloat(data.powerCost) * btcToUSD) / rate;
    const netEarnings =
      (parseFloat(data.earnings) - poolFees - powerCost) * rate;
    return netEarnings;
  };

  setStatistics = async () => {
    try {
      this.setState({ statisticsLoading: true });
      const response = await api.get("user/miner/statistics");
      if (response.code === 200 && response.data) {
        this.setState({
          grossMinerYesterdaysData: response.data.grossMinerYesterdaysData,
          grossMinerAllDaysData: response.data.grossMinerAllDaysData,
          grossMinerYTDData: response.data.grossMinerYTDData,
          statisticsLoading: false,
          totalInvestments: response.data.totalInvestments,
          totalEarningDays: response.data.totalEarningDays,
        });
      }
    } catch (err) {
      this.setState({ statisticsLoading: false });
      console.log("err", err);
    }
  };

  filterHashRateGraph = (e, filterType) => {
    this.setState(
      {
        hashrateGraphValue: filterType,
      },
      () => {
        this.initChart();
      }
    );
  };

  selectTimeHorizon = (value) => {
    this.setState({ timeHorizon: value });
  };

  selectPowerTimeHorizon = (value) => {
    this.setState({ powerTimeHorizon: value });
  };

  selectCurrency = (value) => {
    this.setState({ currency: value });
  };

  changeExchangeRate = (e) => {
    this.setState({
      btcToUSD: e.target.value,
    });
  };

  renderRateOfReturn = () => {
    const {
      totalInvestments,
      timeHorizon,
      grossMinerAllDaysData,
      grossMinerYTDData,
      totalEarningDays,
      btcToUSD,
    } = this.state;
    const { darkMode } = this.props;

    const yearToDateNetEarnings = this.convertBTCToUSD(
      grossMinerYTDData,
      btcToUSD
    );

    const netEarnings = this.convertBTCToUSD(grossMinerAllDaysData, btcToUSD);

    let rateOfReturn;
    if (timeHorizon === "current") {
      rateOfReturn = (100 * yearToDateNetEarnings) / totalInvestments;
    } else if (timeHorizon === "annual") {
      const endingValue = (netEarnings / totalEarningDays) * 365;
      rateOfReturn = (100 * endingValue) / totalInvestments;
    } else if (timeHorizon === "all") {
      rateOfReturn = (100 * netEarnings) / totalInvestments;
    }

    let paybackDays = "Infinite";
    if (netEarnings > 0) {
      paybackDays = (
        totalInvestments /
        ((30 * netEarnings) / totalEarningDays)
      ).toFixed(2);
    }
    paybackDays = isNaN(paybackDays) ? paybackDays : parseFloat(paybackDays);
    rateOfReturn = isNaN(rateOfReturn)
      ? 0
      : parseFloat(rateOfReturn.toFixed(2));

    return (
      <div
        className={"card-container " + (darkMode ? "gloss-night" : "gloss-day")}
      >
        <div className="dark-blue-theme-color card-inner-padding dashboard-card text-center row">
          <div className="col-lg-4 pl-0 pr-0">
            <div className="c-flex">
              <h5 className="card-title text-xl text-default-color text-center">
                Purchase Value
              </h5>
              <div className="circle-container">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={100}
                  duration={2}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(
                      (totalInvestments * value) / 100
                    )
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return (
                      <CircularProgressbarWithChildren
                        value={value}
                        styles={buildStyles({
                          pathTransition: "none",
                          pathColor: `#97faff`,
                        })}
                      >
                        <span className="electronic-text">{roundedValue}</span>
                        <span>
                          <strong>USD</strong>
                        </span>
                      </CircularProgressbarWithChildren>
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
            </div>
          </div>
          <div className="col-lg-4 pl-0 pr-0 rate-of-return-container">
            <div className="c-flex">
              <h5 className="card-title text-xl text-default-color justify-content-center">
                Rate of Return
              </h5>
              <div className="text-bold text-center rate-of-return-period">
                <div className="mr-3 font-size-12">
                  <span
                    onClick={() => this.selectTimeHorizon("current")}
                    className={
                      (timeHorizon === "current" ? "active-currency" : "") +
                      " time-horizon-selector"
                    }
                  >
                    YTD
                  </span>
                  <span
                    onClick={() => this.selectTimeHorizon("all")}
                    className={
                      (timeHorizon === "all" ? "active-currency" : "") +
                      " time-horizon-selector"
                    }
                  >
                    All
                  </span>
                  <span
                    onClick={() => this.selectTimeHorizon("annual")}
                    className={
                      (timeHorizon === "annual" ? "active-currency" : "") +
                      " time-horizon-selector"
                    }
                  >
                    Annual
                  </span>
                </div>
              </div>
              <div className="circle-container">
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={rateOfReturn}
                  duration={2}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = rateOfReturn.toFixed(2);
                    return (
                      <CircularProgressbarWithChildren
                        value={value}
                        styles={buildStyles({
                          pathTransition: "none",
                          pathColor: `#97faff`,
                        })}
                      >
                        <span className="electronic-text">{roundedValue}</span>
                        <span>
                          <strong>%</strong>
                        </span>
                      </CircularProgressbarWithChildren>
                    );
                  }}
                </AnimatedProgressProvider>
              </div>
            </div>
            <div className="text-bold text-center rate-of-return-toolbar">
              <div>
                <input
                  type="number"
                  className="form-control"
                  style={{ borderColor: "rgba(204, 204, 204)" }}
                  value={btcToUSD}
                  onChange={this.changeExchangeRate}
                />
                <span className="custom-tooltip-coin row justify-content-center ml-1 mr-1">
                  <i
                    className="icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
                    style={{
                      fontSize: "18px",
                      color: "#999999",
                      cursor: "pointer",
                    }}
                  ></i>
                  <RateOfReturnTooltip />
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 pl-0 pr-0">
            <div className="c-flex">
              <h5 className="card-title text-xl text-default-color text-center">
                Payback
                <span className="custom-tooltip-coin row justify-content-center ml-1 mr-1">
                  <i
                    className="icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
                    style={{
                      fontSize: "18px",
                      color: "#999999",
                      cursor: "pointer",
                    }}
                  ></i>
                  <PaybackTooltip />
                </span>
              </h5>
              <div className="circle-container">
                {isNaN(paybackDays) ? (
                  <CircularProgressbar
                    value={100}
                    text={`${paybackDays} months`}
                    styles={buildStyles({ pathTransition: "none" })}
                  />
                ) : (
                  <AnimatedProgressProvider
                    valueStart={0}
                    valueEnd={paybackDays}
                    duration={2}
                    easingFunction={easeQuadInOut}
                  >
                    {(value) => {
                      const percentage = (value / 500) * 100;
                      const roundedValue = parseFloat(value.toFixed(2));
                      return (
                        <CircularProgressbarWithChildren
                          value={percentage}
                          styles={buildStyles({
                            pathTransition: "none",
                            pathColor: `#97faff`,
                          })}
                        >
                          <span className="electronic-text">
                            {roundedValue}
                          </span>
                          <span>
                            <strong>months</strong>
                          </span>
                        </CircularProgressbarWithChildren>
                      );
                    }}
                  </AnimatedProgressProvider>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderPower = () => {
    const { darkMode, userDetails } = this.props;
    const {
      powerTimeHorizon,
      currency,
      averagePowerConsumptionPerDay,
      rateOfElectricity,
      averageActiveWorkers,
      energyCurrencyInDEC,
      decDiscountPercentage,
    } = this.state;

    let powerConsumption = averagePowerConsumptionPerDay * averageActiveWorkers;
    if (powerTimeHorizon === "7d") {
      powerConsumption *= 7;
    } else if (powerTimeHorizon === "30d") {
      powerConsumption *= 30;
    }

    let powerExpense = powerConsumption * rateOfElectricity;
    let powerExpenseAfterDecDiscountInUsd = powerExpense;
    let powerExpenseAfterDecDiscount = powerExpense / DEC_TO_USD;
    let rateOfElectricityAfterDecDiscount = rateOfElectricity;
    let powerExpenseDiscount = 0;
    let powerExpenseDecimal = 3;
    let energyCurrency = energyCurrencyInDEC;

    if (currency === "DEC") {
      powerExpenseDiscount = decDiscountPercentage;
      rateOfElectricityAfterDecDiscount =
        (rateOfElectricity * (100 - powerExpenseDiscount)) / 100.0;
      powerExpenseAfterDecDiscountInUsd =
        rateOfElectricityAfterDecDiscount * powerConsumption;
      powerExpenseAfterDecDiscount =
        powerExpenseAfterDecDiscountInUsd / DEC_TO_USD;
      powerExpense = powerExpense / DEC_TO_USD;
      powerExpenseDecimal = 2;
    }

    if (currency === "USD" && userDetails.receivingEthAddress) {
      energyCurrency = energyCurrency * DEC_TO_USD;
    }

    return (
      <div
        className={"card-container " + (darkMode ? "gloss-night" : "gloss-day")}
      >
        <div className="dark-blue-theme-color card-inner-padding dashboard-card">
          <h5 className="card-title text-xl text-default-color d-flex justify-content-between align-items-md-center">
            <div>
              Power
              <span
                className="horizon mt-5 ml-4 text-bold"
                style={{ fontSize: "14px" }}
              >
                <span
                  className={
                    (powerTimeHorizon === "24h" ? "active-currency" : "") +
                    " time-horizon-selector"
                  }
                  onClick={() => this.selectPowerTimeHorizon("24h")}
                >
                  24H
                </span>
                <span
                  className={
                    (powerTimeHorizon === "7d" ? "active-currency" : "") +
                    " time-horizon-selector"
                  }
                  onClick={() => this.selectPowerTimeHorizon("7d")}
                >
                  7D
                </span>
                <span
                  onClick={() => this.selectPowerTimeHorizon("30d")}
                  className={
                    (powerTimeHorizon === "30d" ? "active-currency" : "") +
                    " time-horizon-selector"
                  }
                >
                  30D
                </span>
              </span>
            </div>
            <div>
              <span>
                <select
                  className="earnings-select"
                  id="selectedTimeFrame"
                  name="selectedTimeFrame"
                  defaultValue={currency}
                  style={{
                    padding: "5px",
                  }}
                  onChange={(e) => this.selectCurrency(e.target.value)}
                >
                  <option value="DEC">DEC</option>
                  <option value="USD">USD</option>
                  {/* <option value="BTC">BTC</option> */}
                </select>
              </span>
            </div>
          </h5>
          <div className="row mt-3">
            <div className="col-lg-6 card-d-block text-center">
              <div className="text-small text-bold text-semi-bold">
                Power Consumption (kWh)
              </div>
              <div className="electronic-text text-50">
                <span>{niceNumberNoDecimalDisplay(powerConsumption, 0)}</span>
              </div>
            </div>
            <div className="col-lg-6 card-d-block text-center">
              <div className="text-small text-bold text-semi-bold">
                Power Cost (USD/kWh)
              </div>
              <div className="electronic-text text-50">
                {powerExpenseDiscount > 0 && rateOfElectricity > 0 ? (
                  <span>
                    <strike className="mr-2 color-red">
                      {niceNumberNoDecimalDisplay(rateOfElectricity, 3)}
                    </strike>
                    {niceNumberNoDecimalDisplay(
                      rateOfElectricityAfterDecDiscount,
                      3
                    )}
                  </span>
                ) : (
                  <span>
                    {niceNumberNoDecimalDisplay(rateOfElectricity, 3)}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-3">
            {/* <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 col-xxs-12 card-d-block text-center text-bold mb-3">
              <div className="secondary-data text-small">
                <span className="dashboard-text-color text-bold">
                  {niceNumberNoDecimalDisplay(
                    powerExpense,
                    currency === "USD" ? 3 : 2
                  )}
                </span>
                <span className="ml-1">USD</span>
                <div className="line-separator"></div>
              </div>
              <div className="text-small text-semi-bold ">Monthly Expense</div>
            </div> */}
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block text-center text-bold mb-3">
              <div className="secondary-data text-small">
                <span className="dashboard-text-color text-bold">
                  {powerExpenseDiscount > 0 && !isNaN(powerExpense) ? (
                    <span>
                      <strike className="mr-2 color-red">
                        {niceNumberNoDecimalDisplay(
                          powerExpense,
                          powerExpenseDecimal
                        )}
                        <span className="ml-1">{currency}</span>
                      </strike>
                      {niceNumberNoDecimalDisplay(
                        powerExpenseAfterDecDiscount,
                        powerExpenseDecimal
                      )}
                      <span className="ml-1">{currency}</span>(
                      {niceNumberNoDecimalDisplay(
                        powerExpenseAfterDecDiscountInUsd,
                        3
                      )}
                      <span className="ml-1">USD</span>)
                    </span>
                  ) : (
                    <span>
                      {niceNumberNoDecimalDisplay(
                        powerExpense,
                        powerExpenseDecimal
                      )}
                      <span className="ml-1">{currency}</span>
                    </span>
                  )}
                </span>
                <div className="line-separator"></div>
              </div>
              <div className="text-small text-semi-bold">Power Expense</div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block text-center text-bold mb-3">
              <div className="secondary-data text-small">
                <span className="dashboard-text-color text-bold">
                  {niceNumberNoDecimalDisplay(
                    energyCurrency,
                    currency === "DEC" && userDetails.receivingEthAddress
                      ? 2
                      : 3
                  )}
                </span>
                <span className="ml-1">
                  {userDetails.receivingEthAddress ? currency : "USD"}
                </span>
                <div className="line-separator"></div>
              </div>
              <div className="text-small text-semi-bold">Energy Currency</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      tenMinHashRate,
      oneHourHashRate,
      dayHashRate,
      validNum,
      kycStatus,
      invalidNum,
      repairingNum,
      grossMinerYesterdaysData,
      grossMinerAllDaysData,
      walletBalanceInBTC,
    } = this.state;
    const { darkMode, workerStatistic } = this.props;
    const earnings = parseFloat(
      niceNumberNoDecimalDisplay(
        grossMinerYesterdaysData && grossMinerYesterdaysData.earnings,
        8
      )
    );

    const allWorkers =
      workerStatistic.length > 0
        ? workerStatistic.reduce((p, c) => {
            return { count: p.count + c.count };
          }).count
        : 0;
    return (
      <div className="buy-xpr-container">
        <div className="content-i operation_page_main dashboard-page-main">
          <div className="content-box dashboard-box">
            {(!kycStatus || kycStatus === "p") && this.state.alerComponent}
            {this.state.shouldShowSubscriptionAlert &&
              this.showSubscriptionAlert()}
            <div className="row r-1">
              <div className="c-1 col-xxl-3 p-2">
                <Link to={`earnings`}>
                  <div
                    className={
                      "card-container " +
                      (darkMode ? "gloss-night" : "gloss-day")
                    }
                  >
                    <div className="dark-blue-theme-color card-inner-padding dashboard-card">
                      <h4 className="card-title text-xl text-default-color">
                        Earnings
                      </h4>
                      <div className="row mt-3">
                        <div className="col-lg-12 card-d-block text-center">
                          <div className="text-small text-bold text-semi-bold">
                            Last 24H Earnings (BTC)
                          </div>
                          <div className="electronic-text text-50">
                            <AnimatedProgressProvider
                              valueStart={0}
                              valueEnd={earnings}
                              duration={2}
                              easingFunction={easeQuadInOut}
                            >
                              {(value) => {
                                const fixedValue = value.toFixed(8);
                                return <span>{fixedValue}</span>;
                              }}
                            </AnimatedProgressProvider>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block text-center text-bold mb-3">
                          <div className="secondary-data text-small">
                            <span className="dashboard-text-color text-bold">
                              {niceNumberNoDecimalDisplay(
                                grossMinerAllDaysData &&
                                  grossMinerAllDaysData &&
                                  grossMinerAllDaysData.earnings,
                                8
                              )}
                            </span>
                            <span className="ml-2">BTC</span>
                            <div className="line-separator"></div>
                          </div>
                          <div className="text-small text-semi-bold">
                            Gross Earnings
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block text-center text-bold mb-3">
                          <div className="secondary-data text-small">
                            <span className="dashboard-text-color text-bold">
                              {niceNumberNoDecimalDisplay(
                                walletBalanceInBTC,
                                8
                              )}
                            </span>
                            <span className="ml-2">BTC</span>
                            <div className="line-separator"></div>
                          </div>
                          <div className="text-small text-semi-bold">
                            Wallet Balance
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="c-2 col-xxl-5 p-2">
                {this.renderRateOfReturn()}
              </div>
              <div className="c-3 col-xxl-4 p-2">{this.renderPower()}</div>
            </div>
            <div className="row r-2 mb-4">
              <div className="col-xl-4 c-1 p-2">
                <div
                  className={
                    "mb-2 card-container " +
                    (darkMode ? "gloss-night" : "gloss-day")
                  }
                >
                  <div className="cpad text-center dark-blue-theme-color dashboard-card">
                    <h4 className="card-title text-xl text-default-color">
                      Workers
                    </h4>
                    <div className="row mt-5 mb-1">
                      <div className="col-sm-6">
                        <div className="circle-container mt-0 mb-5">
                          <CircularProgressbarWithChildren
                            value={
                              ((validNum + repairingNum) / allWorkers) * 100
                            }
                            styles={buildStyles({
                              pathColor: "orange",
                              trailColor: "#eee",
                              strokeLinecap: "butt",
                            })}
                          >
                            <div className="sub-circle">
                              <CircularProgressbarWithChildren
                                value={(validNum / allWorkers) * 100}
                                styles={buildStyles({
                                  pathColor: "#97faff",
                                  trailColor: "transparent",
                                  strokeLinecap: "butt",
                                })}
                              >
                                <h3>
                                  <strong>
                                    {niceNumberNoDecimalDisplay(allWorkers, 0)}
                                  </strong>
                                </h3>
                                <h5>
                                  <strong>All</strong>
                                </h5>
                              </CircularProgressbarWithChildren>
                            </div>
                          </CircularProgressbarWithChildren>
                        </div>
                      </div>
                      <div className="col-sm-6 text-center">
                        <span className="worker-badge-container">
                          <div className="worker-badge-row">
                            <span className="worker-badge worker-badge-offline" />
                            <span>{validNum}</span>
                            <span>Active</span>
                          </div>
                          <div className="worker-badge-row">
                            <span className="worker-badge worker-badge-inactive" />
                            <span>{invalidNum}</span>
                            <span>Inactive</span>
                          </div>
                          <div className="worker-badge-row">
                            <span className="worker-badge worker-badge-repairing" />
                            <span>{repairingNum}</span>
                            <span>Repairing</span>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "mt-2 card-container " +
                    (darkMode ? "gloss-night" : "gloss-day")
                  }
                >
                  <div className="dark-blue-theme-color card-inner-padding dashboard-card">
                    <h4 className="card-title text-xl text-default-color">
                      Hashrate
                    </h4>
                    <div className="row mt-3">
                      <div className="col-lg-12 card-d-block text-center">
                        <div className="text-small text-bold text-semi-bold">
                          10-Min Avg
                        </div>
                        <div>
                          <span className="electronic-text text-50">
                            {tenMinHashRate}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block text-center text-bold mb-3">
                        <div className="secondary-data text-small">
                          <span className="dashboard-text-color text-bold">
                            {oneHourHashRate}
                          </span>
                          <div className="line-separator"></div>
                        </div>
                        <div className="text-small text-semi-bold">
                          1-Hour Avg
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block text-center text-bold mb-3">
                        <div className="secondary-data text-small">
                          <span className="dashboard-text-color text-bold">
                            {dayHashRate}
                          </span>
                          <div className="line-separator"></div>
                        </div>
                        <div className="text-small text-semi-bold">
                          1-Day Avg
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 p-2">
                <div className="element-box table-space">
                  <div className="d-flex justify-content-between hashrate-card-d-block">
                    <div className="d-flex hashrate-card-text">
                      <div className="color-black card-title-font font-weight-light">
                        Hashrate
                      </div>
                    </div>
                    <div className="d-flex tab-group hashrate-card-button">
                      <div className="tab">
                        <button
                          className="tablinks hour-btn"
                          onClick={(e) => this.filterHashRateGraph(e, "hour")}
                        >
                          Hour
                        </button>
                        <button
                          className="tablinks day-btn"
                          onClick={(e) => this.filterHashRateGraph(e, "day")}
                        >
                          Day
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    id="chartdiv"
                    style={{ width: "100%", height: "510px" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.token,
  authUserInfo: state.user.userInfo,
  userDetails: state.user.userDetails,
  darkMode: state.darkMode,
  workerStatistic: state.miner.workerStatistic,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserDetails: (success) => dispatch(fetchUserDetails(success)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
