import React, { Component } from "react";
import { Link } from "react-router-dom";
import supplierLogoImg from "../../assets/img/miner-w.png?v1";
import dashboardIcon from "../../assets/img/sidebar-icons/dashboard.svg?v1";
import dashboardLightIcon from "../../assets/img/sidebar-icons/dashboard-light.svg?v1";
import workersIcon from "../../assets/img/sidebar-icons/workers.svg?v1";
import workersLightIcon from "../../assets/img/sidebar-icons/workers-light.svg?v1";
import earningsIcon from "../../assets/img/sidebar-icons/earnings.svg?v1";
import earningsLightIcon from "../../assets/img/sidebar-icons/earnings-light.svg?v1";
import billingIcon from "../../assets/img/Billings.png";
import billingIconSVG from "../../assets/img/bill.svg";
import billingLightIconSVG from "../../assets/img/invoice-light.svg";
import auth from "../../utils/auth";
import Api from "../../services/api";
import Collapsible from "react-collapsible";
import minerCalculatorIcon from "../../assets/img/sidebar-icons/miner-calculator.svg?v1";
import minerCalculatorLightIcon from "../../assets/img/sidebar-icons/miner-calculator-light.svg?v1";
import resourcesIcon from "../../assets/img/sidebar-icons/resources.svg?v1";
import resourcesLightIcon from "../../assets/img/sidebar-icons/resources-light.svg?v1";
import logoutIcon from "../../assets/img/sidebar-icons/logout.svg?v1";
import logoutLightIcon from "../../assets/img/sidebar-icons/logout-light.svg?v1";
import download from "../../assets/img/Download.svg";
import downloadBlue from "../../assets/img/DownloadBlue.svg";
import downloadBlueLight from "../../assets/img/download-light.svg";
import walletLogo from "../../assets/img/wallet.svg?v1";
import walletLightLogo from "../../assets/img/sidebar-icons/wallet-light.svg";
import subscriptionLogo from "../../assets/img/subscription.svg";
import subscriptionLightLogo from "../../assets/img/subscription-light.svg";
import Switch from "react-switch";
import { toggleDarkMode } from "../../store/actions/DarkModeActions";
import { connect } from "react-redux";
import decLogo from "../../assets/img/DecNewImg.png";
import BudgetModal from "./BudgetFoler/BudgetModal";

let api;

class UserDashboardMenu extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.interval = "";
    this.state = {
      isMenuActive: false,
      downloadLink: "",
      budgetModalOpen: false,
    };
    this.menu = React.createRef();
    this.getAgreement = this.getAgreement.bind(this);
    this.showBudgetModal = this.showBudgetModal.bind(this);
    this.onCloseModalBudget = this.onCloseModalBudget.bind(this);
    this.windowResizeHandler = this.windowResizeHandler.bind(this);
  }
  activeRoute = (routeName) =>
    this.props.pathname.indexOf(routeName) > -1
      ? "menu_active"
      : "cursor-pointer";

  toggleMenu = () => {
    let menu = this.menu;
    this.setState({ isMenuActive: !this.state.isMenuActive });
    this.state.isMenuActive
      ? (menu.current.style.display = "none")
      : (menu.current.style.display = "block");
  };

  closeMenu = () => {
    let menu = this.menu;
    if (window.innerWidth < 768) {
      menu.current.style.display = "none";
    }
  };

  windowResizeHandler = () => {
    const menu = this.menu;
    if (window.innerWidth >= 768 && menu) {
      menu.current.style.display = "block";
    }
  };

  async componentDidMount() {
    window.addEventListener("resize", this.windowResizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.windowResizeHandler);
  }

  async getAgreement() {
    const userAgreementResponse = await api.get("miner/getMinerAgreement");
    if (userAgreementResponse.code === 200) {
      let linkObject =
        userAgreementResponse.data.minerAgreementDetail.agreementDocument;
      this.setState(
        {
          downloadLink: linkObject[Object.keys(linkObject)[0]],
        },
        () => {
          window.open(this.state.downloadLink);
        }
      );
    }
  }

  showBudgetModal() {
    this.setState({ budgetModalOpen: true });
  }

  onCloseModalBudget() {
    this.setState({ budgetModalOpen: false });
  }

  render() {
    const { budgetModalOpen } = this.state;
    const { authUserInfo } = this.props;
    const userDisplayName = !!authUserInfo
      ? authUserInfo.userDisplayName || authUserInfo.fullName
      : "";
    const binancesubaccountNumber = !!authUserInfo
      ? authUserInfo.binanceAccountNumber
      : "";
    return (
      <div className="menu-container">
        <div className="menu-mobile menu-activated-on-click color-scheme-dark">
          <div className="mm-logo-buttons-w theme-dark-color">
            <Link to="/dashboard">
              <div className="mm-logo">
                <img
                  className="customLogo"
                  src={supplierLogoImg}
                  height={70}
                  alt="Supplier"
                />
              </div>
            </Link>
            <div className="mm-buttons">
              <div className="mobile-menu-trigger" onClick={this.toggleMenu}>
                <div className="os-icon os-icon-hamburger-menu-1"></div>
              </div>
            </div>
          </div>
          <div className="menu-and-user" ref={this.menu}>
            <div className="logged-user-w">
              <div className="logged-user-info-w">
                <div className="logged-user-name">
                  Welcome {userDisplayName}
                </div>
              </div>
            </div>
            <ul className="main-menu">
              <li
                className={this.activeRoute("journey")}
                onClick={this.closeMenu}
              >
                <Link to="/journey">
                  <span className="px-3 py-2">{`GET STARTED >>>`}</span>
                </Link>
              </li>

              <li
                className={this.activeRoute("dashboard")}
                onClick={this.closeMenu}
              >
                <Link to="/dashboard">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode ? dashboardLightIcon : dashboardIcon
                      }
                      alt="dashboard"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("workers")}
                onClick={this.closeMenu}
              >
                <Link to="/workers">
                  <div className="icon-w theme-color">
                    <img
                      src={this.props.darkMode ? workersLightIcon : workersIcon}
                      alt="workers"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Workers</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("worker_inventory")}
                onClick={this.closeMenu}
              >
                <Link to="/worker_inventory">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode
                          ? minerCalculatorLightIcon
                          : minerCalculatorIcon
                      }
                      alt="worker_inventory"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Inventory</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("orders")}
                onClick={this.closeMenu}
              >
                <Link to="/orders">
                  <div className="icon-w theme-color">
                    <i className="fa fa-list"></i>
                  </div>
                  <span>Orders</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("wallets")}
                onClick={this.closeMenu}
              >
                <Link to="/wallets">
                  <div className="icon-w theme-color">
                    <img
                      id="walletcolor"
                      height="44px"
                      width="32px"
                      src={this.props.darkMode ? walletLightLogo : walletLogo}
                      style={{ color: "blue" }}
                      alt=""
                    />
                  </div>
                  <span>Wallets</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("my_account")}
                onClick={this.closeMenu}
              >
                <Link to="/my_account">
                  <div className="icon-w theme-color">
                    <i className="os-icon os-icon-user-male-circle2"></i>
                  </div>
                  <span style={{ fontWeight: "500" }}>My Account</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("Subscriptions")}
                onClick={this.closeMenu}
              >
                <Link className="d-flex" to="/subscriptions">
                  <div className="icon-w theme-color">
                    <img
                      className={!this.props.darkMode && "subscribeColor"}
                      style={{
                        width: "32px",
                        height: "44px",
                      }}
                      src={
                        this.props.darkMode
                          ? subscriptionLightLogo
                          : subscriptionLogo
                      }
                      alt=""
                      to="/subscriptions"
                    />
                  </div>
                  <span>Subscriptions</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("invoices")}
                onClick={this.closeMenu}
              >
                <Link to="/invoices">
                  <div className="icon-w theme-color">
                    <img
                      style={{
                        width: "32px",
                        height: "44px",
                      }}
                      className={!this.props.darkMode && "filtered"}
                      src={
                        this.props.darkMode
                          ? billingLightIconSVG
                          : billingIconSVG
                      }
                      alt=""
                    />
                  </div>
                  <span>Invoices</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("earnings")}
                onClick={this.closeMenu}
              >
                <Link to="/earnings">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode ? earningsLightIcon : earningsIcon
                      }
                      alt="earnings"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Earnings</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("Agreement")}
                onClick={this.closeMenu}
              >
                <Link to="#">
                  <div className="icon-w theme-color">
                    <img
                      className="ml-1"
                      style={{
                        width: "25px",
                        height: "35px",
                      }}
                      onClick={() => {
                        this.getAgreement();
                      }}
                      src={
                        this.props.darkMode ? downloadBlueLight : downloadBlue
                      }
                      alt=""
                    />
                  </div>
                  <span
                    onClick={() => {
                      this.getAgreement();
                    }}
                  >
                    Agreement
                  </span>
                </Link>
              </li>
              <li
                className={this.activeRoute("mining_calculator")}
                onClick={this.closeMenu}
              >
                <Link to="/mining_calculator">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode
                          ? minerCalculatorLightIcon
                          : minerCalculatorIcon
                      }
                      alt="miner-calculator"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Mining Calculator</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("powersitesmarket")}
                onClick={this.closeMenu}
              >
                <Link to="/powersitesmarket">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode
                          ? minerCalculatorLightIcon
                          : minerCalculatorIcon
                      }
                      alt="Offtake Market"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Offtake Market</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("powersites")}
                onClick={this.closeMenu}
              >
                <Link to="/powersites">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode
                          ? minerCalculatorLightIcon
                          : minerCalculatorIcon
                      }
                      alt="My Offtakes"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>My Offtakes</span>
                </Link>
              </li>
              <li
                className={this.activeRoute("resources")}
                onClick={this.closeMenu}
              >
                <Link to="/resources">
                  <div className="icon-w theme-color">
                    <img
                      src={
                        this.props.darkMode ? resourcesLightIcon : resourcesIcon
                      }
                      alt="Resource Icon"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Resources</span>
                </Link>
              </li>
              <li className="hide-eshop" onClick={this.closeMenu}>
                <Link
                  to="#"
                  onClick={() => {
                    window.open(
                      `https://shop.permianchain.com?user=${auth.getToken()}`,
                      "_blank"
                    );
                  }}
                >
                  <div className="icon-w theme-color">
                    <i className="fa fa-shopping-bag"></i>
                  </div>
                  <span>Go to eShop</span>
                </Link>
              </li>

              <li>
                <Link to="/logout">
                  <div className="icon-w theme-color">
                    <img
                      src={this.props.darkMode ? logoutLightIcon : logoutIcon}
                      alt="logout"
                      height="20"
                      width="20"
                    />
                  </div>
                  <span>Logout</span>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <div className="icon-w theme-color">
                    <Switch
                      onColor="#6485e1"
                      // style={{width: "1px"}}
                      onChange={this.props.toggleDarkMode}
                      checked={this.props.darkMode}
                    />
                  </div>
                  <span className="ml-4 hide-dark-mode">Dark Mode</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="menu-w color-scheme-light color-style-transparent menu-position-side menu-side-left menu-layout-compact sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link user-dashboard-menu">
          <div className="logged-user-w avatar-inline">
            <div className="logged-user-i">
              <div className="logged-user-info-w emdcontainer">
                <div className="logged-user-name-color logged-user-name font-size-12">
                  Welcome {userDisplayName}
                </div>
              </div>
              <div className="logged-user-toggler-arrow">
                <div className="os-icon os-icon-chevron-down text-black"></div>
              </div>
              <div className="logged-user-menu color-style-bright">
                <div className="logged-user-avatar-info display-block">
                  <div className="logged-user-info-w menu-padding-set">
                    <div className="logged-user-name">{userDisplayName}</div>
                    <div className="logged-user-role">
                      Miner {`- ${binancesubaccountNumber}`}
                    </div>
                  </div>
                </div>

                <div className="bg-icon">
                  <i className="os-icon os-icon-walactiveRoutelet-loaded"></i>
                </div>
                <ul>
                  <li>
                    <Link
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      to="#"
                    >
                      <img
                        className="mr-2"
                        style={{
                          width: "20px",
                          height: "18px",
                          position: "relative",
                          left: "-10px",
                          // transform: "rotate(90deg)",
                          filter: "invert(100%)",
                        }}
                        onClick={() => {
                          this.getAgreement();
                        }}
                        src={download}
                        alt=""
                      />
                      <label
                        className="color-white"
                        onClick={() => {
                          this.getAgreement();
                        }}
                      >
                        Agreement
                      </label>
                    </Link>
                  </li>
                  <li>
                    <span className="collapLi">
                      <img
                        className="mr-3"
                        style={{
                          width: "20px",
                          height: "18px",
                          filter: "invert(100%)",
                          marginLeft: "5px",
                        }}
                        src={billingIcon}
                        alt=""
                      />
                      <Collapsible
                        className="billing color-white"
                        trigger="Billings"
                      >
                        <Link className="color-white" to="/subscriptions">
                          Subscriptions
                        </Link>
                        <Link to="/invoices" className="color-white">
                          Invoices
                        </Link>
                      </Collapsible>
                    </span>
                  </li>
                  <li>
                    <Link to="/my_account">
                      <i className="os-icon os-icon-user-male-circle2"></i>
                      <label className="color-white">My Account</label>
                    </Link>
                  </li>
                  <li>
                    <Link to="/logout">
                      <i className="os-icon os-icon-signs-11"></i>
                      <label className="color-white">Logout</label>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {/* <i className="os-icon os-icon-signs-11"></i> */}
                      <Switch
                        height={20}
                        width={40}
                        onColor="#6485e1"
                        onChange={this.props.toggleDarkMode}
                        checked={this.props.darkMode}
                      />
                      <label className="color-white ml-2 mt-1">Dark Mode</label>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <ul className="main-menu sidebar-menu-name li span">
            <li className={this.activeRoute("dashboard")}>
              <Link to="/dashboard">
                <div className="icon-w theme-color">
                  <img
                    src={
                      this.props.darkMode ? dashboardLightIcon : dashboardIcon
                    }
                    alt="dashboard"
                    height="20"
                    width="20"
                  />
                </div>
                <span>Dashboard</span>
              </Link>
            </li>
            <li className={this.activeRoute("workers")}>
              <Link to="/workers">
                <div className="icon-w theme-color">
                  <img
                    src={this.props.darkMode ? workersLightIcon : workersIcon}
                    alt="workers"
                    height="20"
                    width="20"
                  />
                </div>
                <span>Workers</span>
              </Link>
            </li>
            <li className={this.activeRoute("worker_inventory")}>
              <Link to="/worker_inventory">
                <div className="icon-w theme-color">
                  <img
                    src={
                      this.props.darkMode
                        ? minerCalculatorLightIcon
                        : minerCalculatorIcon
                    }
                    alt="worker_inventory"
                    height="20"
                    width="20"
                  />
                </div>
                <span>Inventory</span>
              </Link>
            </li>
            <li className={this.activeRoute("earnings")}>
              <Link to="/earnings">
                <div className="icon-w theme-color">
                  <img
                    src={this.props.darkMode ? earningsLightIcon : earningsIcon}
                    alt="earnings"
                    height="20"
                    width="20"
                  />
                </div>
                <span>Earnings</span>
              </Link>
            </li>
            <li className={this.activeRoute("orders")}>
              <Link to="/orders">
                <div className="icon-w theme-color">
                  <i className="fa fa-list"></i>
                </div>
                <span>My Orders</span>
              </Link>
            </li>
            <li className={this.activeRoute("wallets")}>
              <Link to="/wallets">
                <div className="icon-w theme-color">
                  <img
                    height="25"
                    src={this.props.darkMode ? walletLightLogo : walletLogo}
                    style={{ color: "blue" }}
                    alt=""
                  />
                </div>
                <span>Wallets</span>
              </Link>
            </li>
            <li className={this.activeRoute("mining_calculator")}>
              <Link to="/mining_calculator">
                <div className="icon-w theme-color">
                  <img
                    src={
                      this.props.darkMode
                        ? minerCalculatorLightIcon
                        : minerCalculatorIcon
                    }
                    alt="miner-calculator"
                    height="20"
                    width="20"
                  />
                </div>
                <span>Mining Calculator</span>
              </Link>
            </li>
            <li className={this.activeRoute("powersitesmarket")}>
              <Link to="/powersitesmarket">
                <div className="icon-w theme-color">
                  <img
                    src={
                      this.props.darkMode
                        ? minerCalculatorLightIcon
                        : minerCalculatorIcon
                    }
                    alt="Offtake Market"
                    height="20"
                    width="20"
                  />
                </div>
                <span>Offtake Market</span>
              </Link>
              <li className={this.activeRoute("powersites")}>
                <Link to="/powersites" style={{ textDecoration: "inherit" }}>
                  <div></div>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &ndash;&nbsp; My Offtakes
                  </span>
                </Link>
              </li>
            </li>
            <li
              className={this.activeRoute("resources")}
              onClick={this.closeMenu}
            >
              <Link to="/resources">
                <div className="icon-w theme-color">
                  <img
                    src={
                      this.props.darkMode ? resourcesLightIcon : resourcesIcon
                    }
                    alt="Resource Icon"
                    height="20"
                    width="20"
                  />
                </div>
                <span>Resources</span>
              </Link>
            </li>
          </ul>

          <div className="main-menu mt-4 py-3 buy-blue-span">
            <Link to="/journey">
              <span className="px-3 py-2">{`GET STARTED >>>`}</span>
            </Link>
          </div>
        </div>
        <BudgetModal
          onCloseModalBudget={this.onCloseModalBudget}
          BudgetModalOpen={budgetModalOpen}
          paymentSource="DEC"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  darkMode: state.darkMode,
  authUserInfo: state.user.userInfo,
});

const mapDispatchToProps = {
  toggleDarkMode: toggleDarkMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboardMenu);
