import React, { Component } from "react";
import { connect } from "react-redux";

class UserDashboardTopBar extends Component {
  constructor(props) {
    super(props);
    this.interval = "";
    this.state = {};
  }

  render() {
    //let { authUserInfo } = this.props;
    return (
      <div>
        <div className="top-bar color-scheme-transparent admin-top-bar"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUserInfo: state.user.userInfo,
});

export default connect(mapStateToProps)(UserDashboardTopBar);
