import React from "react";

export class ComponentToPrint extends React.PureComponent {
  render() {
    const { decSecretKey } = this.props;

    return (
      <div className="form-group">
        <label className="text-black" htmlFor="title">
          Secret Key&nbsp;&nbsp;&nbsp;-
        </label>
        <label class="setKey text-black" htmlFor="title">
          &nbsp;&nbsp;&nbsp; {decSecretKey}
        </label>
      </div>
    );
  }
}
