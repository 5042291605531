import React from "react";

const SubscribedTooltip = () => {
  return (
    <div className="earningTooltip2">
      <span className="subInfo" style={{ fontSize: "12px" }}>
        Your Power Pack Subscription payments are applied to your monthly
        Electricity Fee invoices.
      </span>
    </div>
  );
};

export default SubscribedTooltip;
