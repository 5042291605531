import React, { Component } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import Api from "../../services/api";
import ChangePasswordModal from "./ChangePasswordModal";
import EditProfileModal from "./EditProfileModal";
import { confirmAlert } from "react-confirm-alert";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";
import { storeUserDetails } from "../../store/actions/UserAction";

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      phoneNumber: "",
      showPhoneNumber: "",
      email: "",
      changePasswordModalOpen: false,
      editProfileModalOpen: false,
      updateEthAddressButtonLoading: false,
      changePasswordButtonLoading: false,
      editProfileButtonLoading: false,
      toTpUri: "",
      showEmail: "",
      twoFAFormattedKey: "",
      withdrawBtcAddress: "",
      withdrawEthAddress: "",
      currency: "ERC-20",
      twoFAStatus: false,
      accountId: "",
      twoFAcode: "",
      receivingEthAddress: "",
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      currentKycStatus: "u",
      role: "",
      paymentMethods: [],
      userPaymentDetails: {},
    };
    this.onchange = this.onchange.bind(this);
    this.openChangePasswordModal = this.openChangePasswordModal.bind(this);
    this.onChangePasswordCloseModal =
      this.onChangePasswordCloseModal.bind(this);
    this.openEditProfileModal = this.openEditProfileModal.bind(this);
    this.onEditProfileCloseModal = this.onEditProfileCloseModal.bind(this);
    this.twoFaAuthenticationChange = this.twoFaAuthenticationChange.bind(this);
    this.updateReceivingEthAddress = this.updateReceivingEthAddress.bind(this);
    this.onWhiteListAddressChange = this.onWhiteListAddressChange.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.updateKyc = this.updateKyc.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
  }

  async getUserDetails() {
    const api = new Api();
    const { storeUserDetails } = this.props;
    try {
      const response = await api.create("user/getUserDetails", { twoFA: true });
      if (response.code === 200) {
        const userDetails = response.data;
        storeUserDetails(userDetails);
        this.setState({
          accountId: userDetails.binanceAccountNumber,
          twoFAFormattedKey: userDetails.twoFAFormattedKey,
          twoFAStatus: userDetails.twoFAStatus,
          toTpUri: userDetails.toTpUri,
          withdrawBtcAddress: userDetails.withdrawBtcAddress,
          withdrawEthAddress: userDetails.withdrawEthAddress,
          receivingEthAddress:
            this.state.currency === "ERC-20"
              ? userDetails.withdrawEthAddress
              : userDetails.withdrawBtcAddress,
          fullName: userDetails.fullName,
          email: userDetails.email,
          showEmail: userDetails.email,
          phoneNumber: userDetails.phoneNumber,
          showPhoneNumber: userDetails.phoneNumber,
          role: userDetails.role,
        });
      } else {
        toast.error(response.message);
      }
      pageProgress("remove");
    } catch (err) {
      console.log(err);
      pageProgress("force_remove");
      toast.error(err.message);
    }
  }

  async componentWillMount() {
    document.title =
      messageConstants.MY_ACCOUNT_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("display");
    this.getUserDetails();
  }

  async updateRejectKycStatus(userId, type) {
    const api = new Api();
    const response = await api.create("user/kyc_status/update/reject");
    if (!_.isUndefined(response)) {
      if (response.code === 200) {
        this.props.history.push("/kyc");
      } else {
        toast.error(response.message);
      }
    }
  }

  updateKyc() {
    let _self = this;
    let buttons = "";
    buttons = [
      {
        label: "Cancel",
      },
      {
        label: "Yes",
        onClick: () => _self.updateRejectKycStatus(),
      },
    ];

    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure want to resubmit KYC?",
      buttons: buttons,
    });
  }

  async twoFaAuthenticationChange(type = "enable") {
    let { twoFAcode } = this.state;
    let authUserInfo = this.props.authUserInfo;
    let twoFAStatus = type === "disable" ? false : true;
    if (
      !_.isUndefined(twoFAcode) &&
      authUserInfo.email !== "" &&
      twoFAcode !== ""
    ) {
      const api = new Api();
      const response = await api.create("user/verify2FA", {
        email: authUserInfo.email,
        code: twoFAcode,
        status: twoFAStatus,
      });
      if (response.code === 200) {
        this.setState({
          twoFAcode: "",
          twoFAStatus: response.data.twoFAStatus,
          toTpUri: response.data.toTpUri,
        });
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(messageConstants.ERROR_MSG_VALID_TWO_FA_CODE);
    }
  }

  async updateReceivingEthAddress() {
    let { receivingEthAddress, currency } = this.state;
    if (!_.isUndefined(receivingEthAddress) && receivingEthAddress !== "") {
      let response;
      const api = new Api();
      if (currency === "BTC") {
        response = await api.create("user/checkBtcAddress", {
          btcAddress: receivingEthAddress,
        });
        this.getUserDetails();
      }
      if (currency === "ERC-20") {
        response = await api.create("user/checkEthAddress", {
          ethAddress: receivingEthAddress,
        });
        this.getUserDetails();
      }

      if (response.code === 200) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error(messageConstants.ERROR_MSG_VALID_RECEIVING_ETH_ADDRESS);
    }
  }

  async updatePassword() {
    let { oldPassword, newPassword, confirmNewPassword } = this.state;
    if (
      !_.isUndefined(oldPassword) &&
      oldPassword !== "" &&
      newPassword !== "" &&
      newPassword === confirmNewPassword
    ) {
      this.setState({ changePasswordButtonLoading: true });
      const api = new Api();
      const response = await api.create("user/changePassword", {
        oldPassword: oldPassword,
        newPassword: newPassword,
      });
      this.setState({ changePasswordButtonLoading: false });
      if (response.code === 200) {
        toast.success(response.message);
        this.onChangePasswordCloseModal();
      } else {
        toast.error(response.message);
      }
    } else {
      let msg =
        newPassword !== confirmNewPassword
          ? "Password and confirm password did not matched."
          : messageConstants.REQUIRED_FIELDS_VALID_MSG;
      toast.error(msg);
    }
  }

  async updateProfile() {
    let { fullName, phoneNumber, email } = this.state;
    if (
      !_.isUndefined(fullName) &&
      fullName !== "" &&
      phoneNumber !== "" &&
      email !== "" &&
      !_.isUndefined(email) &&
      !_.isUndefined(phoneNumber)
    ) {
      this.setState({ editProfileButtonLoading: true });
      const api = new Api();
      const response = await api.create("user/profile/edit", {
        fullName: fullName,
        phoneNumber: phoneNumber,
        email: email,
      });
      this.setState({ editProfileButtonLoading: false });
      if (response.code === 200) {
        toast.success(response.message);
        this.onEditProfileCloseModal();
        this.getUserDetails();
      } else {
        toast.error(response.message);
        this.getUserDetails();
      }
    } else {
      let msg = messageConstants.REQUIRED_FIELDS_VALID_MSG;
      toast.error(msg);
    }
  }

  async onWhiteListAddressChange(event) {
    this.setState({ currency: event.target.value }, () => {
      if (this.state.currency === "BTC") {
        this.setState({
          receivingEthAddress: this.state.withdrawBtcAddress,
        });
      }
      if (this.state.currency === "ERC-20") {
        this.setState({
          receivingEthAddress: this.state.withdrawEthAddress,
        });
      }
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  openChangePasswordModal() {
    this.setState({ changePasswordModalOpen: true });
  }

  onChangePasswordCloseModal() {
    this.setState({ changePasswordModalOpen: false });
  }

  openEditProfileModal() {
    this.setState({ editProfileModalOpen: true });
  }

  onEditProfileCloseModal() {
    this.setState({ editProfileModalOpen: false });
  }

  render() {
    let {
      toTpUri,
      twoFAStatus,
      twoFAFormattedKey,
      twoFAcode,
      fullName,
      showEmail,
      showPhoneNumber,
      receivingEthAddress,
      accountId,
      updateEthAddressButtonLoading,
      currentKycStatus,
    } = this.state;
    let qrCodeImg = "";
    let twofaButtonClassName = "account-button ";
    if (!twoFAStatus && toTpUri !== "") {
      qrCodeImg =
        "https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=200x200&chld=M|0&cht=qr&chl=" +
        toTpUri;
    }
    let twofaLabel = "Disabled";
    let twofaClassName = "badge-danger";
    let buttonLabel = "Enable 2FA";
    let buttonType = "enable";
    if (twoFAStatus === true) {
      twofaLabel = "Enabled";
      twofaClassName = "badge-success";
      buttonLabel = "Disable 2FA";
      buttonType = "disable";
      twofaButtonClassName = "btn-danger";
    }
    let kycFontClass = "";
    let kycLabel = "No KYC Submitted";
    let kycDescription = "You do not have submitted a KYC.";
    if (currentKycStatus === "u") {
      kycFontClass = "fa-eye";
      kycLabel = "Under Review";
      kycDescription =
        "Your KYC is under review. We will notify you once it approves.";
    } else if (currentKycStatus === "r") {
      kycFontClass = "fa-times";
      kycLabel = "Rejected";
      kycDescription = "Your KYC is rejected. Please submit it again.";
    } else if (currentKycStatus === "a") {
      kycFontClass = "fa-check";
      kycLabel = "Verified";
      kycDescription =
        "Your KYC is already verified. You can update if there any changes.";
    }
    let updateEthAddressButtonDisabled =
      updateEthAddressButtonLoading === true ? true : false;
    return (
      <div className="my-account-container">
        <div className="content-i">
          <div className="content-box">
            <div className="row row-eq-height">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className=" dark-blue-theme-color my-account-header-block h-100 myaccount_card">
                  <div>
                    <h5>
                      <span>Account Information</span>
                      <button
                        className="btn account-button pull-right ml-2 standardColor"
                        onClick={() => this.openEditProfileModal()}
                      >
                        Edit
                      </button>
                    </h5>
                    <div className="mt-1 text-style">
                      Account ID :{" "}
                      <span className="pl-1">
                        {accountId ? accountId : "N/A"}
                      </span>
                    </div>
                    <div className="mt-1 text-style">
                      Name : <span className="pl-1">{fullName}</span>
                    </div>
                    <div className="mt-2 text-style">
                      Email :{" "}
                      <span className="pl-1">
                        {!_.isUndefined(showEmail) &&
                        !_.isEmpty(showEmail) &&
                        !_.isNull(showEmail)
                          ? showEmail
                          : "N/A"}
                      </span>
                    </div>
                    <div className="mt-2 text-style">
                      Phone :{" "}
                      <span className="pl-1">
                        {!_.isUndefined(showPhoneNumber) &&
                        !_.isEmpty(showPhoneNumber) &&
                        !_.isNull(showPhoneNumber)
                          ? showPhoneNumber
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                  <div className="mt-3 text-center d-flex">
                    <button
                      className="btn standardColor account-button 
                    my-account-button"
                      onClick={() => this.openChangePasswordModal()}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mgtp-sm-4 mgtp-md-4 mgtp-xs-4">
                <div className=" dark-blue-theme-color my-account-header-block h-100">
                  <h5>
                    <span>Whitelist Your Wallet</span>
                  </h5>
                  <div className="mt-3 text-style">
                    To receive rewards and make withdrawals make sure to insert
                    your BTC and ERC20 wallet addresses for whitelisting.
                  </div>
                  <div className="mt-3">
                    <div className="myaccount-whitelist-container">
                      <input
                        className="form-control receiving-eth-address"
                        placeholder={`Receiving wallet address`}
                        type="text"
                        name="receivingEthAddress"
                        id="receivingEthAddress"
                        value={receivingEthAddress}
                        onChange={this.onchange}
                      />
                      <select
                        className="whitelist-select"
                        id="whitelistAddress"
                        name="whitelistAddress"
                        defaultValue="ERC-20"
                        onChange={this.onWhiteListAddressChange}
                      >
                        <option value="ERC-20">ERC20</option>
                        <option value="BTC">BTC</option>
                      </select>
                    </div>
                  </div>
                  <div className="mt-3 text-center d-flex">
                    <button
                      className="btn standardColor account-button my-account-button"
                      onClick={this.updateReceivingEthAddress}
                      disabled={updateEthAddressButtonDisabled}
                    >
                      Update{" "}
                      {updateEthAddressButtonLoading && (
                        <i className="fa-spin fa fa-spinner text-white m-1" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 mgtp-sm-4 mgtp-md-4 mgtp-xs-4">
                <div className=" dark-blue-theme-color my-account-header-block h-100 myaccount_card">
                  <div>
                    <h5>
                      <span>KYC</span>
                    </h5>
                    <div className="mt-3 text-center">
                      <i
                        className={
                          "kyc-success-icon-container fa " + kycFontClass
                        }
                      ></i>
                      <span className="pl-3 verified-text">{kycLabel}</span>
                    </div>
                    <div className="mt-3 text-center text-style">
                      <span>{kycDescription}</span>
                    </div>
                  </div>
                  <div className="mt-3 text-center d-flex">
                    {(!currentKycStatus || currentKycStatus === "p") && (
                      <button
                        className="btn standardColor account-button my-account-button"
                        type="button"
                        onClick={() => this.props.history.push("/kyc")}
                      >
                        Submit KYC
                      </button>
                    )}
                    {(currentKycStatus === "a" || currentKycStatus === "r") && (
                      <button
                        className="btn standardColor account-button my-account-button"
                        type="button"
                        onClick={() => this.updateKyc()}
                      >
                        Update KYC
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="col-lg-12 col-sm-12 ">
                <div className="dark-blue-theme-color my-account-header-block two-factor-block">
                  <h5>
                    <span>2 Factor Authentication</span>
                    <span className="pull-right">
                      <span className="text-black">Current Status</span>{" "}
                      <label className={"badge " + twofaClassName}>
                        {twofaLabel}
                      </label>
                    </span>
                  </h5>
                  <div className="mt-2 two-factor-heading-text text-style two-factor-heading-header">
                    Two-factor authentication is a method for protection your
                    web account. When it is activated you need to enter not only
                    your password, but also a special code.
                  </div>
                  <div className="QrRow mt-4 ">
                    <div className="text-center">
                      {qrCodeImg !== "" && (
                        <div className=" mr-3 mb-4">
                          <img alt="" src={qrCodeImg} height="170" />
                        </div>
                      )}
                    </div>
                    <div className="">
                      {!twoFAStatus && (
                        <div className="ml-2">
                          <div className="two-factor-heading-text text-style ">
                            Open up your 2FA mobile app and scan the following
                            QR barcode. If your 2FA mobile app does not support
                            QR barcodes, enter in the following number:
                          </div>
                          <h5 className="mt-4 ">{twoFAFormattedKey}</h5>
                        </div>
                      )}
                      <div className="rowInput">
                        <label className="mb-2 ml-2 mr-2">2FA Code</label>
                        <input
                          className="form-control mr-2"
                          type="text"
                          name="twoFAcode"
                          id="twoFAcode"
                          placeholder="Enter 2FA code"
                          onChange={this.onchange}
                          value={twoFAcode}
                        />
                        <button
                          className={"btn pl-3 " + twofaButtonClassName}
                          style={{ height: "32px" }}
                          onClick={() =>
                            this.twoFaAuthenticationChange(buttonType)
                          }
                        >
                          {buttonLabel}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ChangePasswordModal
          {...this.props}
          {...this.state}
          onInputValueChange={this.onchange}
          isFormValid={this.isFormValid}
          displayValidationErrors={this.displayValidationErrors}
          onCloseModal={this.onChangePasswordCloseModal}
          updatePassword={this.updatePassword}
        />
        <EditProfileModal
          {...this.props}
          {...this.state}
          onCloseModal={this.onEditProfileCloseModal}
          onInputValueChange={this.onchange}
          updateProfile={this.updateProfile}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUserInfo: state.user.userInfo,
  userDetails: state.user.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  storeUserDetails: (user) => dispatch(storeUserDetails(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
