import { STORE_WORKER_STATISTIC } from "./types";
import Api from "../../services/api";
import * as minerApi from "../../api/minerApi";

export const fetchWorkerStatistic = (success, error) => {
  return async (dispatch, state) => {
    const api = new Api();
    const { token } = state().user;
    try {
      if (token) {
        const response = await api
          .setToken(token)
          .get(minerApi.GET_WORKER_STATISTIC);
        if (response.code === 200) {
          const workerStatistic = response.data;
          dispatch(storeWorkerStatistic(workerStatistic));
          if (success && typeof success == "function") {
            success(workerStatistic);
          }
        }
      }
    } catch (err) {
      if (error && typeof error == "function") {
        error(err);
      }
    }
  };
};

export const storeWorkerStatistic = (workerStatistic) => {
  return {
    type: STORE_WORKER_STATISTIC,
    payload: workerStatistic,
  };
};
