import React, { Component } from "react";
import { Link } from "react-router-dom";
import { pageProgress } from "../../utils/Util";

class Exchange extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    pageProgress("force_remove");
  }

  render() {
    return (
      <div className="buy-xpr-container ">
        <div className="content-i">
          <div className="content-box">
            <h1 className="color-white text-center mt-2 pb-1">Buy</h1>
            <div className="buy-container">
              <div className="row justify-content-center">
                <div className="col-custom">
                  <p className="color-white white-lighter-text  ">Location</p>
                </div>
                <div className="col-custom">
                  <p className="color-white white-lighter-text">
                    :&nbsp;ArRayn Station
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-custom">
                  <p className="color-white white-lighter-text  ">
                    Location ID
                  </p>
                </div>
                <div className="col-custom">
                  <p className="color-white white-lighter-text">
                    :&nbsp;248659
                  </p>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-custom">
                  <p className="color-white white-lighter-text  ">Stock</p>
                </div>
                <div className="col-custom">
                  <p className="color-white white-lighter-text">
                    :&nbsp;100kWh
                  </p>
                </div>
              </div>
              <div className="row justify-content-center mt-4 ">
                <div className="balance-container">
                  <span className="white-lighter-text color-white">
                    Balance : <strong>200 kWh</strong>
                  </span>
                </div>
                <button className="btn buy-btn ml-2">Buy</button>
              </div>
              <div className="row justify-content-center align-items-center mt-5">
                <div className="align-items-center calc-counter d-flex flex-column justify-content-center">
                  <p className="color-white white-lighter-text m-2">
                    1 kWh = $ 0.15
                  </p>
                  <div className="custom-select-container border-0 w-200 row theme-bg ml-0 mr-4">
                    <input
                      className="pl-2 buy-xpr-input  dark-blue-font-color h-50  w-50 theme-border "
                      type="text"
                      id="fullName"
                      name="contributionAmount"
                    />
                    <div className="align-items-center border-transparent buy-xpr-currencey-select d-flex justify-content-center h-50 w-50">
                      kWh
                    </div>
                  </div>
                </div>
                <div className="align-self-end color-white d-flex">
                  <p className="color-white h2 ">=</p>
                </div>
                <div className="align-items-center calc-counter d-flex flex-column justify-content-center">
                  <p className="color-white white-lighter-text m-2">&nbsp;</p>
                  <div className="custom-select-container border-0 w-200 row theme-bg ml-4 align-items-center d-flex">
                    <input
                      className="pl-2 buy-xpr-input  dark-blue-font-color h-50  w-50 theme-border "
                      type="text"
                      id="fullName"
                      name="contributionAmount"
                    />
                    <select
                      className="buy-xpr-currencey-select border-transparent w-35 ml-3"
                      name="contributionCurrency"
                    >
                      <option value="eth">USDC</option>
                      <option value="btc">BTC</option>
                      <option value="ltc">ETH</option>
                      <option value="usd-coin">USDT</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center align-items-center mt-5">
                <div className="d-flex flex-column">
                  <p className="color-white white-lighter-text  ">
                    You will reserve:
                  </p>
                  <h2 className="color-white font-weight-light h1">100 kWh</h2>
                </div>
              </div>
              <div className="row justify-content-center align-items-center mt-5">
                <div className="col-2">
                  <Link to="/power_sites">
                    <button className="btn buy-btn w-250 position-relative h-50">
                      <div className="white-round btn-left-icon vertical-center">
                        <i className="fa fa-arrow-left theme-color "></i>
                      </div>
                      <span className="color-white white-lighter-text">
                        Back
                      </span>
                    </button>
                  </Link>
                </div>
                <div className="col-1"></div>
                <div className="col-2">
                  <Link to="/checkout">
                    <button className="btn buy-btn w-250 position-relative h-50">
                      <span className="color-white white-lighter-text">
                        Checkout
                      </span>
                      <div className="white-round btn-right-icon vertical-center">
                        <i className="fa fa-arrow-right theme-color"></i>
                      </div>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Exchange;
