import React, { Component } from "react";
import DocLogo from "../../assets/img/Document-icon.png";
import defaultResource from "../../assets/img/defaultResource1.jpg";
import { confirmAlert } from "react-confirm-alert";

class CardElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullDesc: false,
    };
    this.showDescription = this.showDescription.bind(this);
    this.expandDescription = this.expandDescription.bind(this);
  }

  expandDescription() {
    if (this.state.fullDesc === true) {
      this.setState({ fullDesc: false });
    } else if (this.state.fullDesc === false) {
      this.setState({ fullDesc: true });
    }
  }
  componentDidMount() {
    if (window.innerWidth > 991 && window.innerWidth < 1788) {
      this.setState({ largeCol: "col-lg-4" });
    } else {
      this.setState({ largeCol: "col-lg-3" });
    }
  }
  showDescription(description) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
              <h3 className="text-bold text-center">Description</h3>
              <p className="alert__body text-center mt-2">{description}</p>
              <div className="text-center mt-3">
                <button
                  className="c-button"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  render() {
    const { title, description, link, filetype, category, document } =
      this.props;
    const { fullDesc } = this.state;
    let newDes = description;

    if (newDes) {
      if (newDes.length > 70) {
        newDes = `${newDes.slice(0, 70)}...`;
      }
    }
    return (
      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
        <div
          className={`resource-card-bg post-box emd-box`}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexWrap: "nowrap",
          }}
        >
          <div className="d-flex">
            <div className="avatar">
              <img
                alt="category"
                size="82"
                style={{ width: "82px", height: "82px", borderRadius: "10px" }}
                className="resource-avatar"
                src={
                  filetype === "link"
                    ? defaultResource
                    : filetype === "file" && document === true
                    ? DocLogo
                    : link
                }
                round="8px"
              />
            </div>
            <div className="username">
              <h5 className="post-title text-xl">{title}</h5>
              <h6
                className="post-title mt-2 text-default"
                style={{ fontSize: "13px" }}
              >
                <span className="text-bold">Category - </span>
                <span>{category}</span>
              </h6>
            </div>
          </div>
          <div className="description">
            <span
              className=""
              onClick={
                // () => {
                // this.showDescription(description);
                this.expandDescription
                // }
              }
              style={{
                cursor: "pointer",
                // color: "rgba(90, 99, 126, 0.89)",
                fontSize: "13px",
              }}
            >
              {/* {description} */}
              {fullDesc ? description : newDes}
            </span>
          </div>
          <div className="post-foot d-flex">
            <div>
              <button
                className="btn cancel-button btn-md"
                onClick={() => {
                  window.open(link);
                }}
              >
                View
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CardElement;
