import React, { Component } from "react";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";

class PowerSites extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    pageProgress("force_remove");
    document.title =
      messageConstants.POWER_SITES_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
  }

  render() {
    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="element-box card-inner-padding">
              <div className="table-responsive">
                <table className="table table-td-no-border min-table">
                  <thead className="table-head">
                    <tr>
                      <th className="custom-table-th text-center">
                        site id
                        <br />
                        <span>&nbsp;&nbsp;&nbsp;</span>
                      </th>
                      <th className="custom-table-th text-center">
                        supplier
                        <br />
                        <span>&nbsp;&nbsp;&nbsp;</span>
                      </th>
                      <th className="custom-table-th text-center">
                        location <br />
                        <span>&nbsp;&nbsp;&nbsp;</span>
                      </th>
                      <th className="custom-table-th text-center">
                        power capacity <br />
                        <span className="normal-text"> (kWh/Day)</span>
                      </th>
                      <th className="custom-table-th text-center">
                        sold capacity <br />
                        <span className="normal-text"> (kWh)</span>
                      </th>
                      <th className="custom-table-th text-center">
                        available capacity <br />
                        <span className="normal-text"> (kwh/day)</span>
                      </th>
                      <th className="custom-table-th text-center">
                        power price <br />
                        <span className="normal-text"> (usd/kwh)</span>
                      </th>
                      <th className="custom-table-th text-center">
                        contract duration <br />
                        <span className="normal-text"> (days)</span>
                      </th>
                      <th className="custom-table-th text-center">
                        action <br />
                        <span>&nbsp;&nbsp;&nbsp;</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="power-sites-table">
                      <td className="text-center" colSpan="9">
                        No Records Found
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PowerSites;
