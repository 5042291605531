import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import StopLossTable from "./StopLossTable";
import "./Inventory.css";
import Select from "react-select";
import SortCategory from "./SortCategory";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";

let api;

class WorkerInventory extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      tableLoading: false,
      workerLoading: false,
      inventoryPage: 1,
      inventorySizePerPage: 10,
      inventoryTotalSize: 0,
      workerData: [],
      filter: {},
      stopLossData: null,
    };
  }

  onchange = (event) => {
    this.setState({
      filter: {
        ...this.state.filter,
        [event.target.name]: event.target.value,
      },
    });
  };

  onStatusChange = (obj) => {
    this.setState({
      filter: {
        ...this.state.filter,
        status: obj ? obj.value : "",
      },
    });
  };

  doFilterRecords = () => {
    this.setState(
      {
        inventoryPage: 1,
      },
      async () => {
        this.fetchWorkerInventory();
      }
    );
  };

  clearFilterRecords = () => {
    this.setState(
      {
        filter: {
          workerIdViaBTC: "",
          serial: "",
          status: "",
        },
      },
      async () => {
        this.fetchWorkerInventory();
      }
    );
  };

  async componentDidMount() {
    pageProgress("force_remove");
    document.title =
      messageConstants.WORKER_INVENTORY_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.fetchWorkerInventory();
  }

  fetchWorkerInventory = async () => {
    try {
      const { inventoryPage, inventorySizePerPage, filter } = this.state;
      this.setState({
        workerLoading: true,
        tableLoading: true,
      });
      const response = await api.get("miner/workerInvetory", {
        ...filter,
        page: inventoryPage,
        sizePerPage: inventorySizePerPage,
      });
      if (
        response.code !== 200 ||
        !response.data ||
        !response.data.workerInventory
      ) {
        throw new Error("Fail to fetch worker inventory");
      }
      this.setState({
        workerData: response.data.workerInventory,
        inventoryTotalSize: response.data.total,
        workerLoading: false,
        tableLoading: false,
      });
    } catch (err) {
      alert(`${err}`);
      this.setState({
        workerLoading: false,
        tableLoading: false,
      });
    }
  };

  sortWorkerInventory = (sortField, sortOrder) => {
    const data = this.state.workerData;
    if (data) {
      data.sort((a, b) => {
        const valueA = a[sortField];
        const valueB = b[sortField];
        if (valueA === valueB) {
          return 0;
        }
        const reverse = sortOrder === "asc" ? 1 : -1;
        return reverse * (valueA > valueB ? 1 : -1);
      });
    }
  };

  handleTableChange = (type, params) => {
    if (type === "pagination") {
      const { page, sizePerPage } = params;
      if (
        this.state.inventorySizePerPage !== sizePerPage ||
        this.state.inventoryPage !== page
      ) {
        this.setState(
          { inventorySizePerPage: sizePerPage, inventoryPage: page },
          () => {
            this.fetchWorkerInventory();
          }
        );
      }
    } else if (type === "sort") {
      const { sortField, sortOrder } = params;
      this.sortWorkerInventory(sortField, sortOrder);
    }
  };

  render() {
    const {
      tableLoading,
      inventorySizePerPage,
      inventoryTotalSize,
      inventoryPage,
      workerLoading,
      workerData,
      filter,
    } = this.state;

    const { workerStatistic } = this.props;

    const statusColors = {
      delivered: "purple",
      installed: "success",
      repairing: "orange",
      "in transit": "warning",
      configuring: "primary",
      "stop loss": "red",
    };

    const allWorkers =
      workerStatistic.length > 0
        ? workerStatistic.reduce((p, c) => {
            return { count: p.count + c.count };
          }).count
        : 0;

    const sortCaret = (order) => {
      return <SortCategory sort={order} />;
    };

    const workerColumns = [
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "model",
        text: "Miner",
        sort: true,
        sortCaret,
      },
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "serial",
        text: "Serial Number",
        sort: true,
        sortCaret,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "hashrate",
        text: "HR",
        sort: true,
        sortCaret,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "powerConsumption",
        text: "W",
        sort: true,
        sortCaret,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "powerWth",
        text: "W/THs",
        sort: true,
        sortCaret,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "location",
        text: "Location",
        sort: true,
        sortCaret,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "workerIdViaBTC",
        text: "Worker ID",
        sort: true,
        sortCaret,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "subAccountId",
        text: "Sub Account ID",
        sort: true,
        sortCaret,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "status",
        text: "STATUS",
        formatter: (text) => {
          const badgeColor = statusColors[text.toLowerCase()] || "secondary";
          return <span className={"badge badge-" + badgeColor}>{text}</span>;
        },
        sort: true,
        sortCaret,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "notes",
        text: "Notes",
      },
    ];

    const WorkerRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={workerLoading}
          data={data}
          columns={workerColumns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            styles: {
              overlay: (base) => ({
                ...base,
                background: "rgba(192, 192, 192, 0.3)",
              }),
            },
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <div className="col-sm-12 dark-blue-theme-color card-inner-padding">
              <div className="row mb-3">
                <div className="col-md-6">
                  <h5 className="card-title-font form-table-header">
                    Worker Inventory
                  </h5>
                </div>
                <div className="col-md-6 text-right">
                  <span className="mr-1">All</span>
                  <span className="mr-3 statistic-icon-all">{allWorkers}</span>
                  {workerStatistic.map((s) => {
                    const color =
                      statusColors[s._id.toLowerCase()] || "secondary";
                    return (
                      <>
                        <span className="mr-1">{s._id}</span>
                        <span className={`mr-3 statistic-icon-${color}`}>
                          {s.count}
                        </span>
                      </>
                    );
                  })}
                </div>
              </div>
              <form className="form-inline">
                <div className="col-sm-12 mb-2 col-md-6 col-lg-6">
                  <input
                    className="form-control w-100"
                    style={{
                      padding: "10px",
                      borderColor: "hsl(0,0%,80%)",
                      fontSize: "14px",
                    }}
                    placeholder="Serial Number"
                    type="text"
                    name="serial"
                    onChange={this.onchange}
                    value={filter.serial}
                  />
                </div>
                <div className="col-sm-12 mb-2 col-md-6 col-lg-6">
                  <Select
                    className="w-100"
                    value={
                      filter.status && {
                        value: filter.status,
                        label: filter.status,
                      }
                    }
                    name="status"
                    options={[
                      { value: "Delivered", label: "Delivered" },
                      { value: "Installed", label: "Installed" },
                      { value: "Repairing", label: "Repairing" },
                      { value: "In Transit", label: "In Transit" },
                      { value: "Configuring", label: "Configuring" },
                      { value: "Stop Loss", label: "Stop Loss" },
                    ]}
                    placeholder="Select Status"
                    onChange={this.onStatusChange}
                    isClearable={true}
                  />
                </div>
                <div className="col-sm-12 mt-2 col-md-12 col-lg-12">
                  <button
                    className="btn btn-primary  "
                    type="button"
                    onClick={this.doFilterRecords}
                  >
                    Filter
                  </button>
                  <button
                    className="btn btn-danger ml-2"
                    type="button"
                    onClick={this.clearFilterRecords}
                  >
                    Clear
                  </button>
                </div>
              </form>
            </div>

            <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
              {tableLoading && (
                <div className="text-center">
                  <span id="loading" color="#047bf8" />
                </div>
              )}
              <div className="earnings-table table-responsive">
                {workerData && (
                  <WorkerRemoteAll
                    data={workerData}
                    page={inventoryPage}
                    sizePerPage={inventorySizePerPage}
                    totalSize={inventoryTotalSize}
                    onTableChange={this.handleTableChange}
                  />
                )}
              </div>
            </div>

            <StopLossTable />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  workerStatistic: state.miner.workerStatistic,
});

export default connect(mapStateToProps)(WorkerInventory);
