import React, { Component } from "react";
import axios from "axios";
import Api from "../../services/api";
import {
  niceNumberDecimalDisplay,
  niceNumberNoDecimalDisplay,
} from "../../utils/Util";
import {
  API_ROOT,
  API_NETWORK,
  API_CONTRACTADDRESS,
} from "../../services/api-config";
import investorLogoImg from "../../assets/img/miner-w.png";
import walletImg from "../../assets/img/WhiteWallet.svg";
import decLogo from "../../assets/img/DecNewImg.png";
import dollarImg from "../../assets/img/DollarBalanceImg.png";
import btcLogo from "../../assets/img/Orangebitcoin.png";
import { Link } from "react-router-dom";
import auth from "../../utils/auth";
import { connect } from "react-redux";
import { fetchUserDetails } from "../../store/actions/UserAction";
import { DEC_TO_USD } from "../../utils/constant";

let api;

class CustomHeader extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.interval = "";
    this.state = {
      ethUnitUsdPrice: 0,
      btcUnitUsdPrice: 0,
      btcBalance: "",
      decBalance: "",
      budgetBalance: "",
      budgetBtcBalance: "",
    };
    this.getRecords = this.getRecords.bind(this);
  }

  componentDidMount() {
    this.setXprUnitPrice();

    this.props.fetchUserDetails(async (userDetails) => {
      await this.getRecords(userDetails);
    });
  }

  async setXprUnitPrice() {
    const geminiPricingResponse = await axios.get(
      `${API_ROOT}/geminiPricing.json`
    );
    let btcPriceUsd = "";
    let ethPriceUsd = "";

    if (geminiPricingResponse) {
      const parseJson = geminiPricingResponse.data;
      if (parseJson.btc) {
        btcPriceUsd = parseJson.btc;
      }
      if (parseJson.eth) {
        ethPriceUsd = parseJson.eth;
      }
      this.setState({
        btcUnitUsdPrice: btcPriceUsd,
        ethUnitUsdPrice: ethPriceUsd,
      });
    }

    if (!geminiPricingResponse || !btcPriceUsd || !ethPriceUsd) {
      const response = await axios.get(
        `https://api.gemini.com/v1/pubticker/ethusd`
      );
      if (response) {
        const priceDetails = response.data;
        ethPriceUsd = priceDetails.last;
      }
    }
  }

  async getRecords(authUserInfo) {
    try {
      if (authUserInfo && authUserInfo.btcWalletAddress) {
        const response = await api.get("miner/checkBalanceBtc");
        if (response.code === 200 && response.data && response.data.Balance) {
          this.setState({
            btcBalance: response.data.Balance.available,
          });
        }
      }
      if (authUserInfo && authUserInfo.isHavingBudget) {
        const budgetResponse = await api.get("miner/viewBudget", {
          type: "budget",
        });
        if (
          budgetResponse.code === 200 &&
          budgetResponse.data &&
          budgetResponse.data.budgetedMiner
        ) {
          this.setState({
            budgetBalance: budgetResponse.data.budgetedMiner.amountLeft,
            budgetBtcBalance: budgetResponse.data.budgetedMiner.amountLeftinBTC,
          });
        }
      }
      if (authUserInfo && authUserInfo.receivingEthAddress) {
        const userEthBalance = await axios.get(
          `${API_NETWORK}?module=account&action=tokenbalance&contractaddress=${API_CONTRACTADDRESS}&address=${authUserInfo.receivingEthAddress}&tag=latest&apikey=APJX4Q44UGSK2EUNQYUY31YSW8UGPY2D6V`,
          {
            headers: "",
          }
        );

        if (userEthBalance.status === 200) {
          let finalTokenValue = Math.ceil(Number(userEthBalance.data.result));
          finalTokenValue = finalTokenValue / Math.pow(10, 18);
          this.setState({
            decBalance: finalTokenValue,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const {
      ethUnitUsdPrice,
      btcUnitUsdPrice,
      decBalance,
      btcBalance,
      budgetBalance,
      budgetBtcBalance,
    } = this.state;
    return (
      <div>
        <div className="top-bar custom-top-bar padding-10">
          <div>
            <Link to="/">
              <img
                className="customLogo"
                src={investorLogoImg}
                height={70}
                alt="Investor"
              />
            </Link>
          </div>
          <span className="text-white" style={{ margin: "auto" }}>
            ETH/USD : ${niceNumberDecimalDisplay(ethUnitUsdPrice)} &nbsp;&nbsp;
            | &nbsp;&nbsp; BTC/USD : $
            {niceNumberDecimalDisplay(btcUnitUsdPrice)}{" "}
          </span>
          <div className="text-white mr-3">
            <div className="shopping-icon">
              <a
                href={`https://shop.permianchain.com?user=${auth.getToken()}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-cart-plus"></i>
              </a>
            </div>
          </div>
          <div className="logged-user-w avatar-inline ">
            <div className="logged-user-i walletHover">
              <div className="row align-items-center mr-3">
                <img
                  className="headerWallet"
                  src={walletImg}
                  style={{
                    width: "45px",
                    height: "45px",
                  }}
                  alt="Budget icon"
                />
                <div className="ml-2">
                  <div
                    className="text-white"
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                  >
                    Balance
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#cdcdcd",
                      fontWeight: "bold",
                    }}
                  >
                    Summary
                  </div>
                </div>
                <span
                  className="os-icon os-icon-chevron-down text-white ml-1"
                  // style={{position:"rel"}}
                ></span>
              </div>
              <div className="balance-summary-container logged-user-menu color-style-bright">
                <div className="bg-icon">
                  <i className="os-icon os-icon-walactiveRoutelet-loaded"></i>
                </div>
                <ul>
                  <li className="">
                    <Link to="/wallets" className="hoverUp">
                      <div className="">
                        <div className="row justify-content-between ">
                          <div className="row align-items-center">
                            <img
                              className="color-white svg-white"
                              style={{
                                width: "65px",
                                height: "65px",
                                borderRadius: "50%",
                                marginLeft: "18px",
                                marginRight: "16px",
                              }}
                              src={dollarImg}
                              alt="token"
                            />
                            <div className="">
                              <div className="text-black text-bold">
                                ASIC BUDGET
                              </div>
                              <div className="text-black text-bold">(USD)</div>
                            </div>
                          </div>
                          <div
                            className="row align-items-center"
                            style={{ marginRight: "10px" }}
                          >
                            <div className="fs-sub">
                              <div className="text-black text-bold">
                                ${" "}
                                {budgetBalance
                                  ? niceNumberNoDecimalDisplay(budgetBalance, 2)
                                  : 0}
                              </div>
                              <div className="text-black">
                                {budgetBtcBalance
                                  ? niceNumberNoDecimalDisplay(
                                      budgetBtcBalance,
                                      8
                                    )
                                  : "0"}{" "}
                                BTC
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="fancy-selector-options"></div>
                      </div>
                    </Link>
                    <hr />
                  </li>

                  <li className="">
                    <Link to="/wallets" className="hoverUp">
                      <div className="">
                        <div className="row justify-content-between ">
                          <div className="row align-items-center">
                            <img
                              style={{
                                width: "75px",
                                height: "75px",
                                marginLeft: "14px",
                                marginRight: "12px",
                              }}
                              className="color-white svg-white "
                              src={btcLogo}
                              alt="token"
                            />
                            <div className="">
                              <div className="text-black text-bold">
                                BITCOIN
                              </div>
                              <div className="text-black text-bold">(BTC)</div>
                            </div>
                          </div>
                          <div
                            className="row align-items-center"
                            style={{ marginRight: "10px" }}
                          >
                            <div className="fs-sub">
                              <div className="text-black text-bold">
                                {btcBalance
                                  ? niceNumberNoDecimalDisplay(btcBalance, 8)
                                  : "0"}{" "}
                                BTC
                              </div>
                              <div className="text-black">
                                ${" "}
                                {btcBalance
                                  ? niceNumberNoDecimalDisplay(
                                      this.state.btcUnitUsdPrice * btcBalance,
                                      2
                                    )
                                  : "0"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="fancy-selector-options"></div>
                      </div>
                    </Link>
                    <hr />
                  </li>
                  <li className="">
                    <Link to="/wallets" className="hoverUp">
                      <div className="">
                        <div className="row justify-content-between ">
                          <div className="row align-items-center">
                            <img
                              className="color-white svg-white "
                              style={{
                                width: "68px",
                                height: "68px",
                                marginLeft: "17px",
                                marginRight: "12px",
                                borderRadius: "50%",
                              }}
                              src={decLogo}
                              alt="token"
                            />
                            <div className="" style={{ marginRight: "10px" }}>
                              <div className="text-black text-bold">
                                Digital Energy Currency
                              </div>
                              <div className="text-black text-bold">(DEC)</div>
                            </div>
                          </div>
                          <div
                            className="row align-items-center"
                            style={{ marginRight: "10px" }}
                          >
                            <div className="fs-sub">
                              <div className="text-black text-bold">
                                {decBalance
                                  ? niceNumberNoDecimalDisplay(decBalance, 2)
                                  : "0"}{" "}
                                DEC
                              </div>
                              <div className="text-black">
                                ${" "}
                                {decBalance
                                  ? niceNumberNoDecimalDisplay(
                                      decBalance * DEC_TO_USD,
                                      2
                                    )
                                  : "0"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="fancy-selector-options"></div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUserInfo: state.user.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserDetails: (success) => dispatch(fetchUserDetails(success)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomHeader);
