import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Input,
  Grid,
  Divider,
  ListItem,
  List,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { niceNumberDecimalDisplay } from "../../utils/Util";
import Api from "../../services/api";

const useStyles = (theme) => ({
  card: {
    width: "100%",
    boxShadow: "0px 0px 16px rgb(0 0 0 / 22%)",
  },
  image: {
    width: 100,
  },
  divide: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  avatar: {
    backgroundColor: red[500],
  },
  circular: {
    marginTop: "60px",
  },
});

let api;

class Checkout extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      selectedProduct: props.selectedProduct,
      quantity: props.quantity,
      monthlyElectricityCost: props.monthlyElectricityCost,
      totalAmount: props.totalAmount,
      isLoading: false,
    };
  }

  async createCart(selectedProduct, quantity, monthlyElectricityCost) {
    this.setState({ isLoading: true });
    try {
      const response = await api.create("eshop/cart/add", {
        productId: selectedProduct,
        quantity: quantity,
        electricityDeposit: monthlyElectricityCost * 2,
        fromMiner: true,
      });
      console.log("createCart", response);
    } catch (error) {
      console.log("createCart", error);
    }
    this.setState({ isLoading: false });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      monthlyElectricityCost: nextProps.monthlyElectricityCost,
      totalAmount: nextProps.totalAmount,
    });
    if (
      nextProps.monthlyElectricityCost !== this.state.monthlyElectricityCost
    ) {
      this.createCart(
        this.props.selectedProduct._id,
        this.props.quantity,
        nextProps.monthlyElectricityCost
      );
    }
  }

  componentDidMount() {
    this.createCart(
      this.props.selectedProduct._id,
      this.props.quantity,
      this.props.monthlyElectricityCost
    );
  }

  render() {
    const { classes, quantity, selectedProduct, monthlyElectricityCost } =
      this.props;
    const totalCost = quantity * Number(selectedProduct.priceInUsd);

    return (
      <Grid container spacing={3}>
        <Grid container item xs={12} md={8}>
          <Card className={classes.card}>
            {this.state.isLoading ? (
              <CardContent>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  <CircularProgress className={classes.circular} />
                </Grid>
              </CardContent>
            ) : (
              <CardContent>
                <List>
                  <ListItem>
                    <Grid container spacing={3}>
                      <Box
                        component={Grid}
                        item
                        md={3}
                        display={{ xs: "none", md: "block" }}
                        textAlign={{ md: "left" }}
                      >
                        Image
                      </Box>
                      <Box
                        component={Grid}
                        item
                        md={3}
                        display={{ xs: "none", md: "block" }}
                        textAlign={{ md: "left" }}
                      >
                        Product
                      </Box>
                      <Box
                        component={Grid}
                        item
                        md={3}
                        display={{ xs: "none", md: "block" }}
                        textAlign={{ md: "left" }}
                      >
                        Unit Price
                      </Box>
                      <Box
                        component={Grid}
                        item
                        md={3}
                        display={{ xs: "none", md: "block" }}
                        textAlign={{ md: "left" }}
                      >
                        Qty
                      </Box>
                    </Grid>
                  </ListItem>
                  <Divider className={classes.divide} />

                  <ListItem>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <img
                          className={classes.image}
                          src={`${selectedProduct.imageUrl}`}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        {selectedProduct.model}
                      </Grid>
                      <Grid item xs={12} md={2}>
                        $
                        {niceNumberDecimalDisplay(
                          selectedProduct.priceInUsd,
                          2
                        )}
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input
                          type="number"
                          InputProps={{
                            inputProps: { min: "0", max: "10", step: "1" },
                          }}
                          onChange={(e) => {
                            this.props.onCheckOutQuantity(e.target.value);
                            this.setState({ quantity: e.target.value });
                          }}
                          value={quantity}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                  <Divider className={classes.divide} />
                  <ListItem>
                    {" "}
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <img
                          className={classes.image}
                          src={`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuJ_9nWUGLhRViyNemyDH6FNUrv3xb9jQNrg&usqp=CAU`}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        Electricity Deposit (2-months)
                      </Grid>
                      <Grid item xs={12} md={2}>
                        ${niceNumberDecimalDisplay(monthlyElectricityCost, 2)}
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Input type="number" value={2} disabled />
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </CardContent>
            )}
          </Card>
        </Grid>
        <Grid container item xs={12} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <List>
                <ListItem>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                      {`Total products (${quantity} items)`}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      ${niceNumberDecimalDisplay(totalCost, 2)}
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider className={classes.divide} />

                <ListItem>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                      2 months electricity deposit
                    </Grid>
                    <Grid item xs={12} md={4}>
                      ${niceNumberDecimalDisplay(monthlyElectricityCost * 2, 2)}
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider className={classes.divide} />

                <ListItem>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                      Grand Total
                    </Grid>
                    <Grid item xs={12} md={4}>
                      ${niceNumberDecimalDisplay(this.state.totalAmount, 2)}
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(Checkout);
