import React, { Component } from "react";
import Modal from "react-responsive-modal";
import DocLogo from "../../assets/img/Document-icon.png";
class ViewBudgetModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.openDocument = this.openDocument.bind(this);
  }

  openDocument() {
    window.open(this.props.docLink, "_blank");
  }
  render() {
    const {
      viewBudgetModalOpen,
      viewBudgetModalClose,
      adminNote,
      userNote,
      status,
    } = this.props;

    return (
      <Modal
        open={viewBudgetModalOpen}
        onClose={viewBudgetModalClose}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <h4
                      className="text-center"
                      style={{ alignSelf: "flex-start !important" }}
                    >
                      Wire Transfer Details
                    </h4>
                    <p
                      className="text-center"
                      style={{ color: "gray", marginTop: "-10px" }}
                    >
                      ( Click the icon to view document.)
                    </p>
                    {status === "r" && (
                      <div className="row justify-content-center">
                        <h5 className="mr-3">Admin Note :</h5>
                        <span>{adminNote}</span>
                      </div>
                    )}
                    <div className="row justify-content-center">
                      <h5 className="mr-3">User Note :</h5>
                      <span>{userNote}</span>
                    </div>

                    <div className="row justify-content-center mt-3">
                      <img
                        style={{
                          width: "80px",
                          height: "80px",
                          cursor: "pointer",
                        }}
                        src={DocLogo}
                        alt=""
                        onClick={this.openDocument}
                      />
                    </div>
                    <div className="row justify-content-center mt-4">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          viewBudgetModalClose();
                        }}
                      >
                        Okay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default ViewBudgetModal;
