import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import moment from "moment";
import { toast } from "react-toastify";

import btcLogo from "../../assets/img/Orangebitcoin.png";
import bitcoinImg from "../../assets/img/bitcoin.svg?v3";
import dollarLogo from "../../assets/img/DollarBalanceImg.png";
import infoIcon from '../../assets/img/infoIcon.svg'

import * as messageConstants from "../../utils/Messages";
import {
  getBtcUsd,
  niceNumberDecimalDisplay,
  pageProgress,
} from "../../utils/Util";
import Api from "../../services/api";
import TableHeaderTitle from "./TableHeaderTitle";
import * as stakingStatus from "./stakingStatus";
import ShowPowerInfo from './ShowPowerInfo';

const defaultPagination = {
  totalSize: 0,
  page: 1,
  sizePerPage: 20,
};

const iconStyle = {
  marginTop: "5px",
  marginLeft: "11px",
  borderRadius: "50%",
  height: "20px",
  width: "20px",
};

const statusText = {
  STAKING: "off-take",
  QUIT: "unstake",
};

const statusColors = {
  STAKING: "#24b314",
  QUIT: "#9a9a9a",
};

const commonColumnProps = {
  headerClasses: "custom-table-th",
  headerStyle: { verticalAlign: "top" },
};

function MinerPowerSites() {
  const [isAllSites, setIsAllSite] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [loading, setLoading] = useState(false);
  const [totalEnergyOfftake, setTotalOfftake] = useState(0.0);
  const [totalExercisableEnergyOfftake, setTotalExercisableOfftake] = useState(0.0);
  const [totalEnergyOfftakeUSD, setTotalOfftakeUSD] = useState(0.0);
  const [totalExercisableEnergyOfftakeUSD, setTotalExercisableOfftakeUSD] = useState(0.0);
  const [totalEnergyOfftakeDEC, setTotalOfftakeDEC] = useState(0.0);
  const [totalExercisableEnergyOfftakeDEC, setTotalExercisableOfftakeDEC] = useState(0.0);
  const [powerCostCurrency, setPowerCostCurrency] = useState("USD");
  const [btcUsd, setBtcUsd] = useState(1);
  const [popupOpen, setPopupState] = useState(false);
  const [decTokenPrice, setDecTokenPrice] = useState(0.05);

  useEffect(() => {
    document.title =
      "Power Sites" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
  }, []);

  useEffect(() => {
    fetchData();
  }, [pagination.page, pagination.sizePerPage, isAllSites]);

  const togglePopup = () => {
    setPopupState(!popupOpen);
  };

  const getTotalOfftakeDEC = async (totalUSD, totalExUSD) => {
    const api = new Api();
    const priceResponse = await api.get("miner/StripePrice", {
      type: "DEC",
    });
    let totalOTDEC = 0.0;
    let totalExOTDEC = 0.0;
    if (
      priceResponse.code === 200 &&
      priceResponse.data &&
      priceResponse.data.stripePrice
    ) {
      totalOTDEC = totalUSD / Number(priceResponse.data.stripePrice.unitPrice);
      totalExOTDEC = totalExUSD / Number(priceResponse.data.stripePrice.unitPrice);
    } else {
      toast.error("Failed to get current DEC price");
    }
    return {totalOTDEC, totalExOTDEC};
  }

  const computeTotalOfftake = (records) => {
    let totalOT = 0.0, totalExOT = 0.0;
    let totalOTUSD = 0.0, totalExOTUSD = 0.0, idx = 0;
    for (idx = 0; idx < records.length; idx++) {
      totalOT += (records[idx].currentOfftake ?
        records[idx].currentOfftake : 0.0);
      totalExOT += (records[idx].exercisedOfftake ?
        records[idx].exercisedOfftake : 0.0);
      if (records[idx].currentOfftake > 0.0 && records[idx].listingId.powerPrice > 0.0) {
        totalOTUSD += (records[idx].currentOfftake * records[idx].listingId.powerPrice);
      }
      if (records[idx].exercisedOfftake > 0.0 && records[idx].listingId.powerPrice > 0.0) {
        totalExOTUSD += (records[idx].exercisedOfftake * records[idx].listingId.powerPrice);
      }
    }
    return {totalOT, totalExOT, totalOTUSD, totalExOTUSD};
  }

  const getPriceDEC = async () => {
    let decPrice = 0.05;
    const api = new Api();
    const priceResponse = await api.get("miner/StripePrice", {
      type: "DEC",
    });
    if (
      priceResponse.code === 200 &&
      priceResponse.data
    ) {
      if (priceResponse.data.stripePrice) {
        decPrice = Number(priceResponse.data.stripePrice.unitPrice);
      } else {
        decPrice = Number(priceResponse.data.unitPrice);
      }
    } else {
      toast.error("Failed to get current DEC price using default $0.05");
    }
    return(decPrice);
  }

  const fetchData = async () => {
    const btcUsd = await getBtcUsd();
    const decPrice = await getPriceDEC();
    setDecTokenPrice(decPrice);
    setBtcUsd(btcUsd);
    const api = new Api();
    const { page, sizePerPage } = pagination;
    setLoading(true);
    try {
      const params = {
        page,
        sizePerPage,
      };
      if (!isAllSites) {
        params.status = stakingStatus.STAKING;
      }
      const { code, data, message } = await api.get(
        "miner/minerPowerSites",
        params
      );
      if (code === 200) {
        let { total, records } = data;
        records = records.map((row) => {
          const { dateOnline, dateOffline } = row.listingId;
          if (row.listingId && dateOnline && dateOffline) {
            row.listingId.stakingPeriod = moment(dateOffline).diff(
              moment(dateOnline),
              "days"
            );
            row.listingId.apr = Math.random() * 100;
          }
          return row;
        });
        const {totalOT, totalExOT, totalOTUSD, totalExOTUSD} = computeTotalOfftake(records);
        setTotalOfftake(totalOT);
        setTotalExercisableOfftake(totalOT - totalExOT);
        setTotalOfftakeUSD(totalOTUSD);
        setTotalExercisableOfftakeUSD(totalOTUSD - totalExOTUSD);
        const {totalOTDEC, totalExOTDEC} =
            await getTotalOfftakeDEC(totalOTUSD, (totalOTUSD - totalExOTUSD));
        setTotalOfftakeDEC(totalOTDEC);
        setTotalExercisableOfftakeDEC(totalExOTDEC);
        setTableData(records);
        setPagination({
          ...pagination,
          totalSize: total,
        });
        setLoading(false);
        return pageProgress("remove");
      }
      throw new Error(message);
    } catch (err) {
      setLoading(false);
      pageProgress("force_remove");
      toast.error(err.message);
    }
  };

  const notImplemented = async () => {
    toast("NOT YET IMPLMENTED");
  }

  const onTableChange = (type, { page, sizePerPage }) => {
    if (type === "pagination" && !loading) {
      setPagination({
        ...pagination,
        page,
        sizePerPage,
      });
    }
  };

  const changePowerCostCurrency = () => {
    const currency = powerCostCurrency === "USD" ? "BTC" : "USD";
    setPowerCostCurrency(currency);
  };

  const isUsdCurrency = powerCostCurrency === "USD";

  const columns = [
    {
      ...commonColumnProps,
      text: "SUPPLIER ID",
      dataField: "listingId.userId._id",
    },
    {
      ...commonColumnProps,
      text: "LOCATION",
      dataField: "listingId.location",
    },
    {
      ...commonColumnProps,
      text: "ENERGY SOURCE",
      align: "center",
      dataField: "listingId.energySource",
      formatter: (cell, row) => {
        const { dailyProductionMcf } = row.listingId;
        if (cell) {
          return (
            <span>
              {cell}
            </span>
          );
        } else {
          if (dailyProductionMcf && dailyProductionMcf > 0.0) {
            return "Gas";
          }
          return "Unspecified";
        }
      },
    },
    /*
    {
      ...commonColumnProps,
      text: "PRODUCTION RATE",
      align: "center",
      dataField: "listingId.dailyProductionMcf",
      headerFormatter() {
        return <TableHeaderTitle title="PRODUCTION RATE" unit="Mcf/d" />;
      },
    },
    */
    {
      ...commonColumnProps,
      text: "POWER CAPACITY",
      align: "center",
      dataField: "listingId.estimatedPoweCapacity",
      headerFormatter() {
        return <TableHeaderTitle title="CAMPAIGN CAPACITY" unit="Still Available - kWh" />;
      },
      formatter(cell) {
        if (cell) {
          return niceNumberDecimalDisplay(cell, 2);
        }
        return "";
      },
    },
    {
      ...commonColumnProps,
      text: "CURRENT OFF-TAKE",
      align: "center",
      dataField: "currentOfftake",
      headerFormatter() {
        return <TableHeaderTitle title="CURRENT OFF-TAKE" unit="kWh" />;
      },
      formatter(cell) {
        if (cell) {
          return niceNumberDecimalDisplay(cell, 2);
        }
        return "";
      },
    },
    {
      ...commonColumnProps,
      text: "POWER COST",
      align: "center",
      dataField: "listingId.powerPrice",
      headerFormatter() {
        const icon = isUsdCurrency ? dollarLogo : btcLogo;
        const alt = isUsdCurrency ? "convert to BTC" : "convert to USD";
        return (
          <div className="flex justify-content-center">
            <TableHeaderTitle
              title="DEC/kWh POWER COST"
              unit={"Eq. " + powerCostCurrency + "/kWh"}
            />
            <img
              src={icon}
              alt={alt}
              style={iconStyle}
              onClick={changePowerCostCurrency}
            />
          </div>
        );
      },
      formatter(cell, row) {
        if (cell) {
          let decPrice = 0.0;
          let usdPrice = 0.0;
          // Handle legacy case of USD price set and incomplete listing
          // Legacy is fixed at the start DEC price of 0.05 DEC/$
          if (row.listingId.powerPriceUnits &&
            row.listingId.powerPriceUnits == "dec") {
            usdPrice = row.listingId.powerPrice * decTokenPrice;
            decPrice = row.listingId.powerPrice;
          } else {
            // Legacy, fixed DEC price
            usdPrice = row.listingId.powerPrice;
            decPrice = Math.ceil(row.listingId.powerPrice / 0.05);
          }
          const currencyText = " (" + (isUsdCurrency ? usdPrice + " USD" :
                                      usdPrice / btcUsd + " BTC") + ")";
          const costText = "" + decPrice + " " + currencyText;
          return costText;
        }
        return "";
      },
    },
    /*
    {
      ...commonColumnProps,
      text: "NOTIONAL VALUE",
      align: "center",
      dataField: "listingId.powerPrice",
      formatter(cell, row) {
        const { powerPrice, stakingPeriod } = row.listingId;
        if (powerPrice && stakingPeriod) {
          return 24 * stakingPeriod * powerPrice;
        }
        return "";
      },
    },
    */
    {
      ...commonColumnProps,
      text: "STATUS",
      dataField: "status",
      formatter: (cell) => {
        if (cell) {
          const badgeColor = statusColors[cell.toUpperCase()];
          const txt = statusText[cell.toUpperCase()];
          return (
            <span
              style={{
                borderRadius: 8,
                display: "inline-block",
                padding: "0.25em 0.4em",
                fontSize: "0.81em",
                fontWeight: 500,
                textAlign: "center",
                lineHeight: 1,
                color: "#fff",
                backgroundColor: badgeColor,
              }}
            >
              {txt}
            </span>
          );
        }
      },
    },
    {
      ...commonColumnProps,
      text: "ACTION",
      dataField: "action",
      formatter(cell, row) {
        return (
          <div className="text-left">
            <div className="d-inline-block">
              <div className="btn-group mr-1 mb-1">
                <button
                  aria-expanded="false"
                  aria-haspopup="true"
                  className="btn default-border-btn dropdown-toggle"
                  data-toggle="dropdown"
                  id="dropdownMenuButton6"
                  type="button"
                >
                  Action
                </button>
                <div
                  aria-labelledby="dropdownMenuButton6"
                  className="dropdown-menu"
                >
                  {(row.listingId.powerPrice &&
                    (row.listingId.estimatedPoweCapacity > 0)) &&
                    (
                    <Link
                      to={{
                        pathname: "/buy_energy",
                        state: {
                          listingId: row.listingId._id,
                          supplierId: (row.listingId.userId ? row.listingId.userId._id : ""),
                          currentOfftake: row.currentOfftake,
                          currentDuration: row.offtakeDuration,
                          availEnergy: row.listingId.estimatedPoweCapacity,
                          energyCost: isUsdCurrency ? row.powerPrice : row.powerPrice / btcUsd,
                          returnState: pagination,
                        }
                      }}
                    >
                      <button
                        className="dropdown-item"
                        type="button"
                        disabled={loading}
                      >
                        Increase Off-take
                      </button>
                    </Link>
                  )}
                  {row.currentOfftake > 0 && row.listingId.exerciseEnabled && (
                    <Link
                      to={{
                        pathname: "/journey",
                        state: {
                          userEntryId: row._id,
                          supplierId: (row.listingId.userId ? row.listingId.userId._id : ""),
                          currentOfftake: row.currentOfftake,
                          offtakeDuration: row.offtakeDuration,
                          returnState: pagination,
                       }
                      }}
                    >
                      <button
                        className="dropdown-item"
                        type="button"
                        disabled={loading}
                      >
                        Exercise Off-take
                      </button>
                    </Link>
                  )}
                  <button
                    className="dropdown-item"
                    type="button"
                    disabled={loading}
                    onClick={() => notImplemented()}
                  >
                    Terms & Conditions
                  </button>
                  <Link
                      to={{
                        pathname: "/powersitehistory",
                        state: {
                          offtakeId: row._id,
                          listingId: row.listingId._id,
                          supplierId: (row.listingId.userId ? row.listingId.userId._id : ""),
                          offtakeHistory: row.offTakeHistory,
                          returnState: pagination,
                        }
                      }}
                    >
                      <button
                        className="dropdown-item"
                        type="button"
                        disabled={loading}
                      >
                        Transaction History
                      </button>
                    </Link>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const NoActiveSitesIndication = () => {
    if (loading) {
      return (
        <div className="text-center p-3">
         <div style={{ fontSize: 18 }}>
          Loading data, please wait...
         </div>
        </div>
      );
    }
    return (
      <div className="text-center p-3">
        <div style={{ fontSize: 18 }}>
          You have no {!isAllSites ? "active" : "power"} sites
        </div>
        <div className="font-15 mt-2">
          To find a home for your miners, you can visit
          <Link to="/powersitesMarket">
            <span href="#" className="underline ml-1">
              Offtake Market
            </span>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className="buy-xpr-container">
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <div className="col-md-12">
              <div className="dark-blue-theme-color card-inner-padding">
                <h4 className="card-title text-xl text-default-color">
                  <span className="pull-left color:red">
                    OFF-TAKE BALANCE
                  </span>
                  <span>
                   <sup>
                    <img alt="usd"
                      src={infoIcon}
                      style={{
                        marginLeft: "2px",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={togglePopup}
                    />
                    {popupOpen && <ShowPowerInfo
                      handleClose={togglePopup}
                      handleOpen={togglePopup}
                    />}
                   </sup>
                  </span>
                </h4>
                <div className="clearfix"></div>

                <div className="row mt-3">
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex mb-1">
                    <img src={bitcoinImg} height="40" alt="bitcoin" />
                    <div className="dashboard_text_space Earnings-text ml-4">
                      <span className="color-red dashboard-text-bold">Offtake</span>
                      <br />
                      <span className="color-red dashboard-text-bold">
                        {niceNumberDecimalDisplay(totalEnergyOfftake, 2)} kWh
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex mb-1">
                    <img src={bitcoinImg} height="40" alt="bitcoin" />
                    <div className="dashboard_text_space Earnings-text ml-4">
                      <span className="color-red dashboard-text-bold">Exercisable Offtake</span>
                      <br />
                      <span className="color-red dashboard-text-bold">
                        {niceNumberDecimalDisplay(totalExercisableEnergyOfftake, 2)} kWh
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex xxs-earning-card-margin mb-1">
                    <img src={bitcoinImg} height="40" alt="bitcoin" />
                    <div className="dashboard_text_space Earnings-text ml-4">
                      <span className="color-red dashboard-text-bold">Offtake Balance</span>
                      <br />
                      <span className="color-red dashboard-text-bold">
                        {niceNumberDecimalDisplay(totalEnergyOfftakeDEC, 2)} DEC
                      </span>
                      <br />
                      <span className="color-red">
                        $ {niceNumberDecimalDisplay(totalEnergyOfftakeUSD, 2)}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex mgtp-md-3 mgtp-sm-3 mgtp-xs-3 mb-1">
                    <img src={bitcoinImg} height="40" alt="bitcoin" />
                    <div className="dashboard_text_space Earnings-text ml-4">
                      <span className="color-red dashboard-text-bold">Exercisable Balance</span>
                      <br />
                      <span className="color-red dashboard-text-bold">
                        {niceNumberDecimalDisplay(totalExercisableEnergyOfftakeDEC, 2)} DEC
                      </span>
                      <br />
                      <span className="color-red">
                        $ {niceNumberDecimalDisplay(totalExercisableEnergyOfftakeUSD, 2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h6>
             <span>
              <span className="pull-right">
                <br />
                <Link to="/powersitesMarket">
                  <button
                    type="button"
                    className="mr-2 mb-2 btn btn-primary"
                  >
                    Go To Offtake Market
                  </button>
                </Link>
              </span>
             </span>
            </h6>
              <div>
                <div className="table-responsive">
                  <BootstrapTable
                    remote
                    bordered={false}
                    keyField="_id"
                    data={tableData}
                    columns={columns}
                    pagination={
                      pagination.totalSize > 0
                        ? paginationFactory(pagination)
                        : undefined
                    }
                    onTableChange={onTableChange}
                    noDataIndication={NoActiveSitesIndication}
                    loading={loading}
                    overlay={overlayFactory({
                      spinner: true,
                      styles: {
                        overlay: (base) => ({
                          ...base,
                          background: "rgba(192, 192, 192, 0.3)",
                        }),
                      },
                    })}
                    classes="table table-striped table-lightfont dataTable"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default MinerPowerSites;
