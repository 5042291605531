import React, { Component } from "react";
import Modal from "react-responsive-modal";
import Api from "../../../services/api";
import _ from "lodash";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { niceNumberNoDecimalDisplay, getBtcUsd } from "../../../utils/Util";
import { toast } from "react-toastify";

let api;

class WithdrawBTCModal extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      address: "",
      amount: "",
      invoiceId: this.props.invoiceId,
      buttonDisabled: true,
      walletAddress: "",
      btcAmount: "",
      unitAmount: "",
      recieverAddress: "",
      totalBalance: "",
      paymentRequestButtonLoading: false,
      quantity: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.onChangeQuantity = this.onChangeQuantity.bind(this);
    this.getAllBalance = this.getAllBalance.bind(this);
    this.changecRecieverAddress = this.changecRecieverAddress.bind(this);
    this.openSuccess = this.openSuccess.bind(this);
    this.getPriceDetails = this.getPriceDetails.bind(this);
  }
  openSuccess() {
    const MySwal = withReactContent(Swal);
    MySwal.fire("Withdraw Successfully Requested!", "", "success");
  }
  changecRecieverAddress(event) {
    this.setState({ recieverAddress: event.target.value });
  }
  onChangeQuantity(event) {
    this.setState({ quantity: event.target.value }, () => {
      this.setState(
        {
          amount: this.state.quantity * this.state.unitAmount,
          totalBalance: niceNumberNoDecimalDisplay(
            this.state.quantity * this.state.unitAmount,
            2
          ),
        },
        async () => {
          const priceUSD = await getBtcUsd();

          const btcValue = niceNumberNoDecimalDisplay(
            this.state.amount / priceUSD,
            8
          );
          this.setState({
            btcAmount: btcValue,
          });
        }
      );
    });
  }

  async componentDidMount() {
    this.setState({ amount: this.props.amount });
    const response = await api.create("miner/getPaymentCredentials", {
      paymentMode: "btcBudget",
    });

    if (response.code === 200) {
      this.setState({ walletAddress: response.data[0].BTCWallet });
    }
    this.getPriceDetails();
  }

  getAllBalance(value) {
    this.setState({
      amount: value,
    });
  }
  onChangeAmount(e) {
    this.setState(
      {
        amount: e.target.value,
      },
      async () => {
        const priceUSD = await getBtcUsd();
        const btcValue = niceNumberNoDecimalDisplay(
          this.state.amount / priceUSD,
          8
        );
        this.setState({
          btcAmount: btcValue,
          totalBalance: niceNumberNoDecimalDisplay(this.state.amount, 2),
        });
        this.setState({
          quantity: this.state.amount / this.state.unitAmount,
        });
      }
    );
  }

  async onSubmit() {
    if (this.state.btcAmount > 0 && this.state.quantity) {
      this.props.withdrawOnCloseModal();
      this.props.onCloseModalBudget();
      const response = await api.create("miner/BtcBudgetPayment", {
        amount: this.state.btcAmount,
        unitPrice: this.state.unitAmount,
        qty: this.state.quantity,
      });
      if (!_.isUndefined(response) && response.code === 200) {
        this.openSuccess();
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Please make sure the quantity is greater than 0!");
    }
  }
  async getPriceDetails() {
    const { paymentSource } = this.props;
    const priceResponse = await api.get("miner/StripePrice", {
      type: paymentSource,
    });
    if (
      priceResponse.code === 200 &&
      priceResponse.data &&
      !_.isEmpty(priceResponse.data)
    ) {
      this.setState({
        unitAmount: Number(priceResponse.data.stripePrice.unitPrice),
      });
    }
  }
  render() {
    const { btcModalOpen, withdrawOnCloseModal, senderWalletAddress } =
      this.props;
    const { amount, walletAddress, quantity, btcAmount, totalBalance } =
      this.state;
    return (
      <Modal
        open={btcModalOpen}
        onClose={withdrawOnCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <div>
                      <h4
                        className="text-black"
                        style={{ alignSelf: "flex-start !important" }}
                      >
                        BTC Payments
                      </h4>
                      <div className="form-group text-black">
                        <label htmlFor="title">
                          Receiving Address
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control w-100"
                          id="address"
                          type="text"
                          placeholder="Enter Address"
                          value={walletAddress}
                          disabled={true}
                        />
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="title">
                          Sender Address
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control w-100"
                          id="address"
                          type="text"
                          placeholder="Enter Address"
                          value={senderWalletAddress}
                          disabled={true}
                        />
                      </div>

                      <div className="form-group text-black">
                        <label htmlFor="quantity">
                          Quantity<span className="required-field">*</span>
                        </label>
                        <div className="">
                          <input
                            className="form-control"
                            id="quantity"
                            type="number"
                            placeholder="Enter Quantity"
                            value={quantity}
                            onChange={this.onChangeQuantity}
                          />
                        </div>
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="title">
                          USD Amount<span className="required-field">*</span>
                        </label>
                        <div className="">
                          <input
                            className="form-control"
                            id="amount"
                            type="number"
                            placeholder="Enter Amount"
                            value={amount}
                            disabled={true}
                            onChange={this.onChangeAmount}
                          />
                        </div>
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="btcAmount">
                          BTC Amount<span className="required-field">*</span>
                        </label>
                        <div className="">
                          <input
                            className="form-control"
                            id="btcAmount"
                            type="number"
                            placeholder="Enter Amount"
                            value={btcAmount}
                            onChange={this.onChangeAmount}
                            disabled={true}
                          />
                        </div>
                      </div>

                      <div className="container-right">
                        <span className="gray">
                          Amount USD: $ {totalBalance}
                        </span>
                      </div>
                      <div className="d-flex justify-content-end mt-4 ">
                        <button
                          className="btn modal-button cancel-button mr-2"
                          type="button"
                          onClick={withdrawOnCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn modal-button update-button"
                          type="button"
                          onClick={this.onSubmit}
                        >
                          {" "}
                          Deposit{" "}
                          {this.state.paymentRequestButtonLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default WithdrawBTCModal;
