import React from "react";
import CreditCardInput from "react-credit-card-input";
import Select from "react-select";
const UpdateCard = ({
  optionRef,
  countriesList,
  handleCountryChange,
  setEmail,
  setCity,
  setLine1,
  email,
  city,
  line1,
  country,
  countryIdValue,
  state,
  setState,
  setPostalCode,
  setExpiry,
  postal_code,
  expiry,
  submitLoading,
  updatePaymentMethod,
}) => {
  return (
    <>
      <input
        placeholder="Email"
        className="mui-field mt-3"
        id="outlined-email-input"
        margin="normal"
        variant="outlined"
        type="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
      />
      <input
        placeholder="Address"
        className="mui-field mt-3"
        margin="normal"
        variant="outlined"
        type="text"
        required
        value={line1}
        onChange={(e) => setLine1(e.target.value)}
        fullWidth
      />
      <Select
        autoFocus={false}
        className="country-select4 w-100 mt-3"
        classNamePrefix="contry"
        options={countriesList}
        placeholder="Select Country"
        name="countryId"
        id="country"
        ref={optionRef}
        onChange={handleCountryChange}
        value={{
          label: country,
          value: countryIdValue,
        }}
      />
      <input
        placeholder="City"
        className="mui-field mt-3"
        margin="normal"
        variant="outlined"
        type="text"
        required
        value={city}
        onChange={(e) => setCity(e.target.value)}
        fullWidth
      />
      <input
        placeholder="State"
        className="mui-field mt-3"
        margin="normal"
        variant="outlined"
        type="text"
        required
        value={state}
        onChange={(e) => setState(e.target.value)}
        fullWidth
      />
      <input
        placeholder="Postal Code"
        className="mui-field mt-3"
        margin="normal"
        variant="outlined"
        type="text"
        required
        value={postal_code}
        onChange={(e) => {
          setPostalCode(e.target.value);
        }}
        fullWidth
      />
      <div>
        <CreditCardInput
          containerClassName="fieldContainer mt-2"
          cardImageStyle={{ display: "none" }}
          containerStyle={{ width: "100%" }}
          isDisabled={true}
          cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
            <input {...props} className="cardNo" />
          )}
          cardExpiryInputProps={{
            value: expiry,
            onError: (err) => {
              setExpiry("");
            },
            onChange: (e) => setExpiry(e.target.value),
          }}
          cardCVCInputProps={{
            style: {
              display: "none",
            },
          }}
          fieldClassName="input"
        />
      </div>
      <div className="row align-items-start justify-content-center">
        <button
          className="btn btn-primary mt-2 mr-2 "
          onClick={() => {
            updatePaymentMethod();
          }}
        >
          Submit
          {submitLoading && (
            <i className="fa-spin fa fa-spinner text-white ml-2" />
          )}
        </button>
      </div>
    </>
  );
};

export default UpdateCard;
