import React from "react";
const StripeTooltip = () => {
  return (
    <div className="stripeTooltip">
      <span>Credit Card</span>
    </div>
  );
};

export default StripeTooltip;
