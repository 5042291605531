import React from "react";
const DepositTooltip = ({ note }) => {
  return (
    <div className="earningTooltip1">
      <span className=".subInfo" style={{ fontSize: "12px" }}>
        {note}
      </span>
    </div>
  );
};

export default DepositTooltip;
