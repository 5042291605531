import React from "react";
const WalletTooltip = () => {
  return (
    <div className="walletTooltip1" style={{ whiteSpace: "normal" }}>
      <span>
        This is your BTC mining wallet where BTC earnings are deposited. Gross
        earnings are deposited into your BTC mining wallet on a recurring basis
        every time your BTC mining rewards reach an additional threshold of
        0.001 BTC.
      </span>
    </div>
  );
};

export default WalletTooltip;
