import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import { toast } from "react-toastify";

import * as messageConstants from "../../utils/Messages";
import {
  niceNumberDecimalDisplay,
  pageProgress,
} from "../../utils/Util";

const defaultPagination = {
  totalSize: 0,
  page: 1,
  sizePerPage: 20,
};

const iconStyle = {
  marginTop: "5px",
  marginLeft: "11px",
  borderRadius: "50%",
  height: "20px",
  width: "20px",
};

const commonColumnProps = {
  headerClasses: "custom-table-th",
  headerStyle: { verticalAlign: "top" },
};

function MinerPowerHistory(props) {
  const [tableData, setTableData] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [loading, setLoading] = useState(false);
  const [listing, setListing] = useState("");
  const [supplier, setSupplier] = useState("");

  if (props === undefined) {
    toast("Props is undef");
  }

  useEffect(() => {
    document.title =
      "Power Site History" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
  }, []);

  useEffect(() => {
    setData();
  }, [pagination.page, pagination.sizePerPage]);

  const setData = async () => {
    setListing(props.location.state.listingId);
    setSupplier(props.location.state.supplierId);
    toast();
    setLoading(true);
    const offtakeHistory = (props.location.state.offtakeHistory ?
                            props.location.state.offtakeHistory :
                            []);
    const total = offtakeHistory.length;

    console.log(offtakeHistory);
    setTableData(offtakeHistory);
    setPagination({
      ...pagination,
      totalSize: total,
    });
    setLoading(false);
    return pageProgress("remove"); 
  };

  const onTableChange = (type, { page, sizePerPage }) => {
    if (type === "pagination" && !loading) {
      setPagination({
        ...pagination,
        page,
        sizePerPage,
      });
    }
  };

  const columns = [
    {
      ...commonColumnProps,
      text: "kWh OffTake",
      dataField: "kwh",
    },
    {
      ...commonColumnProps,
      text: "DEC COST",
      dataField: "dec",
    },
    {
      ...commonColumnProps,
      text: "$ USD COST",
      dataField: "usd_cost",
      formatter: (cell) => {
        if (cell) {
          return niceNumberDecimalDisplay(cell, 2);
        }
      },
    },
    {
      ...commonColumnProps,
      text: "DATE",
      dataField: "when",
    },
  ];

  const NoActiveHistoryIndication = () => {
    if (loading) {
      return (
        <div className="text-center p-3">
         <div style={{ fontSize: 18 }}>
          Loading data, please wait...
         </div>
        </div>
      );
    }
    return (
      <div className="text-center p-3">
        <div style={{ fontSize: 18 }}>
          No history found for this site
        </div>
      </div>
    );
  };

  return (
    <div className="buy-xpr-container">
       <div className="content-i">
          <div>
            <h6>
              <span>
                <span className="pull-left">
                  <b>
                    History for Supplier: {supplier}, Campaign Listing: {listing}
                  </b>
                </span>
              </span>
              <span>
                <span className="pull-right">
                  <br />
                  <Link to="/powersites">
                    <button
                      type="button"
                      className="mr-2 mb-2 btn btn-primary"
                    >
                      Back To My Offtake Market
                    </button>
                  </Link>
                </span>
             </span>
            </h6>
              <div>
                <div className="table-responsive">
                  <BootstrapTable
                    remote
                    bordered={false}
                    keyField="_id"
                    data={tableData}
                    columns={columns}
                    pagination={
                      pagination.totalSize > 0
                        ? paginationFactory(pagination)
                        : undefined
                    }
                    onTableChange={onTableChange}
                    noDataIndication={NoActiveHistoryIndication}
                    loading={loading}
                    overlay={overlayFactory({
                      spinner: true,
                      styles: {
                        overlay: (base) => ({
                          ...base,
                          background: "rgba(192, 192, 192, 0.3)",
                        }),
                      },
                    })}
                    classes="table table-striped table-lightfont dataTable"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
  );
}

export default MinerPowerHistory;
