import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import PropTypes from "prop-types";
import _ from "lodash";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import Switch from "react-switch";

import Api from "../../services/api";
import { niceNumberNoDecimalDisplay, getBtcUsd } from "../../utils/Util";
import * as messageConstants from "../../utils/Messages";
import bitcoinImg from "../../assets/img/bitcoin.svg?v3";
import EarningModal from "./EarningModal";
import "./Earnings.css";
import EarningsTooltip from "./EarningsTooltip";
import { pageProgress } from "../../utils/Util";
let api;

class Earnings extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      earningsData: [],
      earningsPage: 1,
      earningsSizePerPage: 10,
      earningsTotalSize: 0,
      baseCurrency: "btc",
      modalOpen: false,
      timeHorizon: "24h",
      earningDetails: {},
      grossMiner7DaysData: null,
      grossMiner30DaysData: null,
      grossMinerYesterdaysData: null,
      grossMinerAllDaysData: null,
      decimalPoint: 8,
      isActual: false,
      priceUsd: 0,
    };
    pageProgress("force_remove");
  }
  showModal = () => {
    this.setState({ modalOpen: true });
  };

  onCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  async componentDidMount() {
    document.title =
      messageConstants.EARNINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    const priceUsd = await getBtcUsd();

    this.setState({ priceUsd });
    await this.setStatistics(priceUsd);
    await this.getRecords(priceUsd);
    await this.displayEarnings();
  }

  async componentDidUpdate(_prevProps, prevState) {
    const { sizePerPage, earningsPage, baseCurrency, timeHorizon, priceUsd } =
      this.state;
    if (
      sizePerPage !== prevState.sizePerPage ||
      earningsPage !== prevState.earningsPage
    ) {
      this.getRecords(priceUsd);
    }
    if (
      baseCurrency !== prevState.baseCurrency ||
      timeHorizon !== prevState.timeHorizon
    ) {
      this.displayEarnings();
    }
  }

  convertBTCToUSD = (data, rate) => {
    if (!data) {
      return {
        earnings: 0,
        poolFees: 0,
        powerCost: 0,
        netEarnings: 0,
      };
    }
    return {
      earnings: parseFloat(data.earnings) * rate,
      poolFees: parseFloat(data.poolFees) * rate,
      powerCost: parseFloat(data.powerCost) * rate,
      netEarnings: parseFloat(data.netEarnings) * rate,
    };
  };

  setStatistics = async (rate) => {
    try {
      this.setState({ statisticsLoading: true });
      const response = await api.get("user/miner/statistics");
      if (response.code === 200 && response.data) {
        this.setState({
          grossMiner7DaysData: {
            btc: response.data.grossMiner7DaysData,
            usd: this.convertBTCToUSD(response.data.grossMiner7DaysData, rate),
          },
          grossMiner30DaysData: {
            btc: response.data.grossMiner30DaysData,
            usd: this.convertBTCToUSD(response.data.grossMiner30DaysData, rate),
          },
          grossMinerYesterdaysData: {
            btc: response.data.grossMinerYesterdaysData,
            usd: this.convertBTCToUSD(
              response.data.grossMinerYesterdaysData,
              rate
            ),
          },
          grossMinerAllDaysData: {
            btc: response.data.grossMinerAllDaysData,
            usd: this.convertBTCToUSD(
              response.data.grossMinerAllDaysData,
              rate
            ),
          },
        });
      }
    } catch (err) {
      console.log("err", err);
    }
    this.setState({ statisticsLoading: false });
  };

  async getRecords(priceUsd) {
    try {
      const { earningsPage, earningsSizePerPage } = this.state;

      const response = await api.get("user/earnings", {
        page: earningsPage,
        sizePerPage: earningsSizePerPage,
      });

      if (response.code === 200) {
        let earningsData =
          response.data && response.data.earnings ? response.data.earnings : "";
        const totalEarnings =
          response.data && response.data.totalEarnings
            ? response.data.totalEarnings
            : "";
        earningsData = earningsData.map((d) => {
          return {
            ...d,
            actualRate: d.electricityCostInUsd / d.electricityCost,
          };
        });
        this.setState({
          earningsData,
          earningsTotalSize: totalEarnings,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  handleTableChange = (_type, { page, sizePerPage, filters }) => {
    if (
      this.state.sizePerPage !== sizePerPage ||
      this.state.earningsPage !== page
    ) {
      this.setState({ sizePerPage: sizePerPage, earningsPage: page });
    }
  };

  selectBaseCurrency = (value) => {
    this.setState({
      baseCurrency: value,
      decimalPoint: value === "usd" ? 2 : 8,
    });
  };

  selectTimeHorizon = (value) => {
    this.setState({ timeHorizon: value });
  };

  displayEarnings = async () => {
    const {
      timeHorizon,
      baseCurrency,
      grossMiner7DaysData,
      grossMiner30DaysData,
      grossMinerYesterdaysData,
      grossMinerAllDaysData,
    } = this.state;

    let data;
    if (grossMinerYesterdaysData) {
      if (timeHorizon === "24h") {
        data = grossMinerYesterdaysData[baseCurrency];
      } else if (timeHorizon === "7d") {
        data = grossMiner7DaysData[baseCurrency];
      } else if (timeHorizon === "30d") {
        data = grossMiner30DaysData[baseCurrency];
      } else if (timeHorizon === "all") {
        data = grossMinerAllDaysData[baseCurrency];
      }
    }
    this.setState({
      earningDetails: {
        decimal: baseCurrency === "btc" ? 8 : 2,
        ...data,
      },
    });
  };

  toggleActualAndLive = (e) => {
    this.setState({ isActual: !this.state.isActual });
  };

  renderNotionalEarnings = () => {
    const { baseCurrency, statisticsLoading, timeHorizon, earningDetails } =
      this.state;

    const { earnings, poolFees, powerCost, netEarnings, decimal } =
      earningDetails;

    return (
      <div className="row mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
        <div className="col-md-12">
          <div className="dark-blue-theme-color card-inner-padding">
            <h4 className="containEarning card-title text-xl text-default-color">
              <span className="title-horizonContainer">
                <div>
                  Notional Earnings
                  <span className="custom-tooltip-coin row justify-content-center ml-1 mr-1">
                    <i
                      className="icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
                      style={{
                        fontSize: "18px",
                        color: "#999999",
                        cursor: "pointer",
                      }}
                    ></i>
                    <EarningsTooltip />
                  </span>
                </div>

                <span
                  className="horizon ml-3 mt-1"
                  style={{ fontSize: "14px" }}
                >
                  <span
                    style={{
                      padding: "5px 15px",
                      borderRadius: "5px",
                    }}
                    className={timeHorizon === "24h" ? "active-currency" : ""}
                    onClick={() => this.selectTimeHorizon("24h")}
                  >
                    24H
                  </span>
                  <span
                    style={{ padding: "5px 15px", borderRadius: "5px" }}
                    className={timeHorizon === "7d" ? "active-currency" : ""}
                    onClick={() => this.selectTimeHorizon("7d")}
                  >
                    7D
                  </span>
                  <span
                    style={{ padding: "5px 15px", borderRadius: "5px" }}
                    onClick={() => this.selectTimeHorizon("30d")}
                    className={timeHorizon === "30d" ? "active-currency" : ""}
                  >
                    30D
                  </span>
                  <span
                    style={{ padding: "5px 15px", borderRadius: "5px" }}
                    onClick={() => this.selectTimeHorizon("all")}
                    className={timeHorizon === "all" ? "active-currency" : ""}
                  >
                    All
                  </span>
                </span>
              </span>
              <span className="btcDropDown">
                <select
                  className="earnings-select"
                  id="selectedCurrency"
                  name="selectedCurrency"
                  defaultValue="btc"
                  style={{
                    padding: "5px",
                  }}
                  onChange={(e) => this.selectBaseCurrency(e.target.value)}
                >
                  <option value="usd">USD</option>
                  <option value="btc">BTC</option>
                </select>
              </span>
            </h4>
            <div className="clearfix"></div>

            <div className="row mt-3">
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex">
                <img src={bitcoinImg} height="40" alt="bitcoin" />
                <div className="dashboard_text_space Earnings-text ml-4">
                  <span className="color-black">Gross Earnings</span>
                  <br />
                  <span className="color-black dashboard-text-bold">
                    {statisticsLoading && (
                      <i className="fa fa-spinner fa-spin"></i>
                    )}
                    {!statisticsLoading && (
                      <span>
                        {`${
                          earnings
                            ? niceNumberNoDecimalDisplay(earnings, decimal)
                            : "0"
                        } ${_.toUpper(baseCurrency)}`}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex xxs-earning-card-margin">
                <img src={bitcoinImg} height="40" alt="bitcoin" />
                <div className="dashboard_text_space Earnings-text ml-4">
                  <span className="color-black">Fees</span>
                  <br />
                  <span className="color-black dashboard-text-bold">
                    {statisticsLoading && (
                      <i className="fa fa-spinner fa-spin"></i>
                    )}
                    {!statisticsLoading && (
                      <span>
                        {`${
                          earningDetails.poolFees
                            ? niceNumberNoDecimalDisplay(poolFees, decimal)
                            : "0"
                        } ${_.toUpper(baseCurrency)}`}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex mgtp-md-3 mgtp-sm-3 mgtp-xs-3">
                <img src={bitcoinImg} height="40" alt="bitcoin" />
                <div className="dashboard_text_space Earnings-text ml-4">
                  <span className="color-black">Power Cost</span>
                  <br />
                  <span className="color-black dashboard-text-bold">
                    {statisticsLoading && (
                      <i className="fa fa-spinner fa-spin"></i>
                    )}
                    {!statisticsLoading && (
                      <span>
                        {`${
                          earningDetails.powerCost
                            ? niceNumberNoDecimalDisplay(powerCost, decimal)
                            : "0"
                        } ${_.toUpper(baseCurrency)}`}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-xxs-12 card-d-block d-flex mgtp-md-3 mgtp-sm-3 mgtp-xs-3">
                <img src={bitcoinImg} height="40" alt="bitcoin" />
                <div className="dashboard_text_space Earnings-text ml-4">
                  <span className="color-black">Net Earnings</span>
                  <br />

                  <span className="color-black dashboard-text-bold">
                    {statisticsLoading && (
                      <i className="fa fa-spinner fa-spin"></i>
                    )}
                    {!statisticsLoading && (
                      <span>
                        {`${
                          earningDetails.netEarnings
                            ? niceNumberNoDecimalDisplay(netEarnings, decimal)
                            : "0"
                        } ${_.toUpper(baseCurrency)}`}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      earningsData,
      earningsSizePerPage,
      earningsTotalSize,
      earningsPage,
      workerLoading,
      baseCurrency,
      decimalPoint,
      priceUsd,
      isActual,
    } = this.state;

    const isUSD = baseCurrency === "usd";

    const workerColumns = [
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "date",
        text: (
          <>
            DATE<span className="font-weight-normal ml-1">(YYYY/MM/DD)</span>
          </>
        ),
        formatter: function (cell, _row) {
          return <span>{cell ? moment(cell).format("YYYY/MM/DD") : ""}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "grossProfit",
        text: "Gross Earnings",
        formatter: function (_cell, row) {
          const { grossProfit, actualRate } = row;
          let value = !isUSD
            ? grossProfit
            : isActual
            ? grossProfit * actualRate
            : grossProfit * priceUsd;
          value = niceNumberNoDecimalDisplay(value, decimalPoint);
          return <span>{`${value} ${isUSD ? "USD" : "BTC"}`}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "poolFee",
        text: "Fees",
        formatter: function (_cell, row) {
          const { poolFee, actualRate } = row;
          let value = !isUSD
            ? poolFee
            : isActual
            ? poolFee * actualRate
            : poolFee * priceUsd;
          value = niceNumberNoDecimalDisplay(value, decimalPoint);
          return <span>{`${value} ${isUSD ? "USD" : "BTC"}`}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "",
        text: "Power Cost",
        isDummyField: true,
        formatter: function (_cell, row) {
          const { electricityCostInUsd, electricityCost, actualRate } = row;
          let value = isUSD
            ? electricityCostInUsd
            : !isActual
            ? (electricityCost * actualRate) / priceUsd
            : electricityCost;
          value = niceNumberNoDecimalDisplay(value, decimalPoint);
          return <span>{`${value} ${isUSD ? "USD" : "BTC"}`}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "",
        text: "Net Earnings",
        isDummyField: true,
        formatter: function (_cell, row) {
          const { netProfit, actualRate } = row;
          let value = !isUSD
            ? netProfit
            : isActual
            ? netProfit * actualRate
            : netProfit * priceUsd;

          value = niceNumberNoDecimalDisplay(value, decimalPoint);

          return <span>{`${value} ${isUSD ? "USD" : "BTC"}`}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "btcUsdRate",
        text: "BTC/USD",
        isDummyField: true,
        formatter: function (_cell, row) {
          const value = niceNumberNoDecimalDisplay(
            isActual ? row.actualRate : priceUsd,
            2
          );

          return <span>{`$ ${value}`}</span>;
        },
      },
    ];

    const WorkerRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="date"
          bordered={false}
          loading={workerLoading}
          data={data}
          columns={workerColumns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            {this.renderNotionalEarnings()}

            <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
              <div className="earnings-table table-responsive">
                {earningsData && (
                  <div>
                    <div className="earningtableHeading">
                      <div className="switch-actual-live">
                        <h5 className="mr-2">Daily Earnings</h5>
                        <Switch
                          onColor="#6485e1"
                          checked={this.state.isActual}
                          onChange={this.toggleActualAndLive}
                          onHandleColor="#2693e6"
                          handleDiameter={25}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          height={25}
                          width={42}
                          className="actual-live-switch"
                        />
                        <p className="ml-2 text-black">
                          {this.state.isActual ? "ACTUAL" : "LIVE"}
                        </p>
                      </div>

                      <button
                        className="btn btn-primary"
                        onClick={() => this.showModal()}
                      >
                        Export Data
                      </button>
                      <EarningModal
                        {...this.props}
                        {...this.state}
                        onCloseModal={this.onCloseModal}
                        onInputValueChange={this.onchange}
                      />
                    </div>
                    <WorkerRemoteAll
                      data={earningsData}
                      page={earningsPage}
                      sizePerPage={earningsSizePerPage}
                      totalSize={earningsTotalSize}
                      onTableChange={this.handleTableChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Earnings;
