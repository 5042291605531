import React from "react";
import { toast } from "react-toastify";
import MetaTags from "react-meta-tags";
import Api from "../../services/api";
import logoImg from "../../assets/img/miner-w.png?v3";
import capitalize from "./assets/images/capitalize_1.svg";
import conserve from "./assets/images/conserve_2.svg";
import consult from "./assets/images/consult_3.svg";
import convert from "./assets/images/convert_4.svg";
import minerIcon from "./assets/images/miner-tiny.svg?v3";
import powerdata from "./assets/images/power_data.svg?v3";
import digitalasset from "./assets/images/digital_asset.svg?v3";
import timetransaction from "./assets/images/time_transaction.svg";
import { pageProgress } from "../../utils/Util";

import "./assets/css/icomoon.css";
import "./assets/css/stylecustom.css";

let api;

class Home extends React.Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      email: "",
      buttonLoading: false,
      modalOpen: false,
      recaptchaResponse: "",
      addInterestLoading: false,
      otherInterestShow: false,
      otherinterest: "",
      termsAccepted: false,
    };
    pageProgress("remove");
    this.onChange = this.onChange.bind(this);
    this.subscribeNow = this.subscribeNow.bind(this);
    this.addInterest = this.addInterest.bind(this);
    this.onInterestCloseModal = this.onInterestCloseModal.bind(this);
    this.openInterestModal = this.openInterestModal.bind(this);
    this.handleCaptchaResponseChange =
      this.handleCaptchaResponseChange.bind(this);
    this.changeInterestOptions = this.changeInterestOptions.bind(this);
    this.acceptTerms = this.acceptTerms.bind(this);
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  openInterestModal() {
    this.setState({ modalOpen: true });
  }

  onInterestCloseModal() {
    this.setState({ modalOpen: false });
  }

  handleCaptchaResponseChange(response) {
    this.setState({
      recaptchaResponse: response,
    });
  }

  acceptTerms(event) {
    this.setState({
      termsAccepted: event.target.checked,
    });
  }

  async subscribeNow(event) {
    event.preventDefault();
    const { email } = this.state;
    if (email && email !== "") {
      this.setState({
        buttonLoading: true,
      });
      const response = await api.create("user/subscribe", {
        email,
      });
      if (response) {
        this.setState({
          buttonLoading: false,
        });
        if (response.code === 200) {
          this.setState({
            email: "",
          });
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } else {
      toast.error("Please enter valid email.");
    }
  }

  async addInterest() {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      interestedoption,
      country,
      companyName,
      website,
      otherinterest,
      termsAccepted,
    } = this.state;
    if (
      firstName !== "" &&
      email !== "" &&
      interestedoption &&
      termsAccepted === true
    ) {
      this.setState({
        addInterestLoading: true,
      });
      const response = await api.create("user/interest/add", {
        firstName,
        lastName,
        email,
        phoneNumber,
        interestedoption,
        country,
        companyName,
        website,
        usertype: "miner",
        otherinterest,
      });
      if (response) {
        this.setState({
          addInterestLoading: false,
        });
        if (response.code === 200) {
          this.setState({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            country: "",
            companyName: "",
            website: "",
            interestedoption: [],
            modalOpen: false,
          });
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } else {
      let msg =
        termsAccepted === false
          ? "Please accept terms & conditions."
          : "Please enter required details.";
      toast.error(msg);
    }
  }

  changeInterestOptions(e) {
    var options = e.target.options;
    var value = [];
    var otherinterestFlag = false;
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
        if (options[i].value === "other") {
          otherinterestFlag = true;
        }
      }
    }
    let stateObj = {
      interestedoption: value,
    };
    if (otherinterestFlag && otherinterestFlag === true) {
      stateObj.otherInterestShow = true;
    }
    this.setState(stateObj);
  }

  render() {
    const { email, buttonLoading } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;

    return (
      <div className="home-page-container home-page-main-container miner-home-page-container">
        <MetaTags>
          <title>
            Digital Securities issuance platform for exploration & production
            companies
          </title>
        </MetaTags>
        <div id="page" className="homepage">
          <nav className="colorlib-nav" role="navigation">
            <div className="top-menu">
              <div className="container">
                <div className="row">
                  <div className="col-md-2">
                    <div id="colorlib-logo">
                      <img src={logoImg} height={70} alt="Logo" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>

          <section
            id="home"
            className="video-hero home-background-image"
            data-section="home"
          >
            <div className="overlay"></div>
            <div className="display-t">
              <div className="display-tc">
                <div className="container">
                  <div className="col-md-12 col-md-offset-0">
                    <div className="animate-box mt-3">
                      <h2>
                        PermianChain Miner: enabling data mining companies
                        access to natural gas fields for a more efficient energy
                        source.
                      </h2>
                      <p className="home-heading-text">
                        With an integrated digital marketplace making it simple
                        for natural gas operators and data mining companies to
                        digitally trade field-generated electricity to power
                        onsite server farms.
                      </p>
                      <p>
                        <a
                          href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-lg btn-custom home-link-button"
                        >
                          Learn More
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="colorlib-services colorlib-bg-white">
            <div className="container">
              <div className="row">
                <div className="col-md-4 text-center animate-box">
                  <div className="services">
                    <span className="icon">
                      <img src={powerdata} width={84} alt="Power Data" />
                    </span>
                    <div className="desc">
                      <h3 className="step-text">
                        A world-first platform supplying power sites to data
                        miners
                      </h3>
                      <p className="body-text">
                        PermianChain Miner connects data mining companies with
                        oil and gas operators to satisfy mining power
                        consumption with ongoing access to economically viable
                        electricity sources that maintain profitable operations.
                      </p>
                      <p>
                        <a
                          href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-custom learn-more-link extra-button-padding"
                        >
                          Learn More
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center animate-box">
                  <div className="services">
                    <span className="icon">
                      <img
                        src={timetransaction}
                        width={84}
                        alt="Time Transaction"
                      />
                    </span>
                    <div className="desc">
                      <h3 className="step-text">
                        A just in time transaction protocol allowing consistent
                        power source
                      </h3>
                      <p className="body-text">
                        PermianChain Miner allows data miners (i.e. Bitcoin
                        miners) to benefit from digitally streamlined and
                        automated electricity purchases for consistent flow of
                        electricity from natural gas fields. Streamlining the
                        power-chain for onsite mining farms using Smart Off-Take
                        Agreements (SOTAs).
                      </p>
                      <p>
                        <a
                          href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-custom learn-more-link extra-button-padding"
                        >
                          Learn More
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 text-center animate-box">
                  <div className="services">
                    <span className="icon">
                      <img src={digitalasset} width={84} alt="Digital Asset" />
                    </span>
                    <div className="desc">
                      <h3 className="step-text">
                        An integrated solution for digital asset financing
                      </h3>
                      <p className="body-text">
                        Enabling data mining companies to work with registered
                        financial institutions and qualified and insured
                        custodians to access debt and equity facilities under a
                        compliant digital framework in the form of smart
                        contracts on the blockchain.
                      </p>
                      <p>
                        <a
                          href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn btn-primary btn-custom learn-more-link extra-button-padding"
                        >
                          Learn More
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="colorlib-featured">
            <div className="container">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="cubewrap">
                    <div className="cube">
                      <div className="front">
                        <img
                          src={minerIcon}
                          height={45}
                          className="lockerimage"
                          alt="Miner"
                        />
                      </div>
                      <div className="back"></div>
                      <div className="top"></div>
                      <div className="bottom"></div>
                      <div className="left"></div>
                      <div className="right"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <p className="mt-5 cube-details text-center colorlib-heading animate-box mb-3">
                    PermianChain Miner is a dedicated block of the PermianChain
                    platform that allows data mining companies and bitcoin
                    mining entrepreneurs to directly buy field-generated
                    electricity from oil and gas operators registered on the
                    PermianChain Supplier block. Miners can track the progress
                    of production, including rapid order processing, Smart
                    Off-Take Agreements (SOTA), make digital payments and ensure
                    steady source of energy for their data mining operations.
                  </p>
                  <p className="cube-details text-center cube-register-text">
                    Register today for a live demo with a PermianChain
                    representative.
                  </p>
                  <p className="text-center">
                    <a
                      href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary btn-lg btn-custom live-demo-button"
                    >
                      Request Live Demo
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="colorlib-work-featured colorlib-bg-white detailed-description">
            <div className="container homecontainer">
              <div className="row mobile-wrap">
                <div className="col-md-12 animate-box pull-left">
                  <div className="desc">
                    <h3>PermianChain’s 4-C’s for Oil & Gas 4.0</h3>
                    <div className="features">
                      <span className="icon">
                        <img src={consult} width={50} alt="Consult" />
                      </span>
                      <div className="f-desc">
                        <p>
                          <strong>Consult</strong>
                          <br />
                          EMD’s using the PermianChain technology can provide
                          exploration &amp; production companies ongoing
                          consulting on efficient digital frameworks to solve
                          asset owner communication, strengthen investor
                          relations and optimize cash-flows by adopting the
                          PermianChain pillars.
                          <br />
                          <a
                            href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-custom learn-more-link"
                          >
                            Learn More
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="features">
                      <span className="icon">
                        <img src={conserve} width={50} alt="Conserve" />
                      </span>
                      <div className="f-desc">
                        <p>
                          <strong>Conserve</strong>
                          <br />
                          Through the adoption of PermianChain’s Oil &amp; Gas
                          4.0 framework, we deploy power generation
                          infrastructure allowing E&amp;Ps to create an onsite
                          market for excess, stranded and/or wasted natural gas,
                          generating a higher netback per mcf.
                          <br />
                          <a
                            href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-custom learn-more-link"
                          >
                            Learn More
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="features">
                      <span className="icon">
                        <img src={convert} width={50} alt="Convert" />
                      </span>
                      <div className="f-desc">
                        <p>
                          <strong>Convert</strong>
                          <br />
                          When adopting PermianChain’s Oil &amp; Gas 4.0
                          framework we guide upstream companies through the
                          development stages of converting gas-to-bitcoin, which
                          translates into increased cash-flow and an auxiliary
                          source of revenue to fund drilling campaigns.
                          <br />
                          <a
                            href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-custom learn-more-link"
                          >
                            Learn More
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="features">
                      <span className="icon">
                        <img
                          src={capitalize}
                          width={50}
                          alt="Capitalize Icon"
                        />
                      </span>
                      <div className="f-desc">
                        <p>
                          <strong>Capitalize</strong>
                          <br />
                          As part of the Oil &amp; Gas 4.0 integration, our team
                          facilitates the potential to access new sources of
                          capital by adopting our technology with registered
                          EMDs, digital asset brokers and authorized placement
                          agents to structure and distribute digital securities
                          for improved liquidity and cash-flow.
                          <br />
                          <a
                            href="https://permianchain.us10.list-manage.com/subscribe/post?u=d89ec38fcdfffb1fd73475a9f&id=cee50e37f4"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-custom learn-more-link"
                          >
                            Learn More
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="colorlib-subscribe"
            className="colorlib-subscribe"
            data-stellar-background-ratio="0.5"
          >
            <div className="overlay"></div>
            <div className="homecontainer marginleftrightauto">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-10 text-center colorlib-heading animate-box">
                  <h2>Subscribe Newsletter</h2>
                  <p className="font-size-16">
                    If you’d like to hear more about how PermianChain is
                    boosting oil and gas trading and investment, join our
                    mailing list.
                  </p>
                </div>
              </div>
              <div className="row animate-box">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <form className="form-inline qbstp-header-subscribe">
                        <div className="col-three-forth">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              id="email"
                              placeholder="Enter your email"
                              onChange={this.onChange}
                              value={email}
                            />
                          </div>
                        </div>
                        <div className="col-one-third">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={(e) => this.subscribeNow(e)}
                              disabled={buttonDisabled}
                            >
                              Subscribe Now
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer id="colorlib-footer">
            <div className="copy">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <p>
                      Copyright © 2020 PermianChain Technologies, Inc. All right
                      reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
        <div className="gototop js-top">
          <a href="#" className="js-gotop">
            <i className="icon-arrow-up2"></i>
          </a>
        </div>
      </div>
    );
  }
}
export default Home;
