import React, { Component } from "react";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";

class PoolStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    pageProgress("force_remove");
    document.title =
      messageConstants.POOL_STATISTICS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
  }

  render() {
    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <h1 className="color-white text-center mt-2 pb-1">
              Pool Statistics
            </h1>

            <div className="element-box mt-4  mb-4">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="form-header form-table-header">
                    General Pool Data
                  </h4>
                </div>
                <div className="col-md-6 text-right">
                  <a href="Javascript:void(0)" className="form-table-link">
                    + add new pool
                  </a>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-td-no-border min-table">
                  <thead className="table-head">
                    <tr>
                      <th className="custom-table-th">pool name</th>
                      <th className="custom-table-th">algorithm</th>
                      <th className="custom-table-th">stratum</th>
                      <th className="custom-table-th">port</th>
                      <th className="custom-table-th">username</th>
                      <th className="custom-table-th">password</th>
                      <th className="custom-table-th">hashing power</th>
                      <th className="custom-table-th">api</th>
                      <th className="custom-table-th">action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td colSpan="9" className="no-padding">
                        <h3 className="black-color">No Data Here</h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PoolStatistics;
