import { combineReducers } from "redux";
import { DarkModeReducer } from "./DarkModeReducer";
import { UserReducer } from "./UserReducer";
import { MinerReducer } from "./MinerReducer";
import { CountryReducer } from "./CountryReducer";
import { connectRouter } from "connected-react-router";

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    darkMode: DarkModeReducer,
    user: UserReducer,
    miner: MinerReducer,
    country: CountryReducer,
  });
