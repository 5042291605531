import React, { Component } from "react";
import { Link } from "react-router-dom";
import Modal from "react-responsive-modal";
import stripeLogo from "../../../assets/img/stripe-seeklogo.com.svg";
import Api from "../../../services/api";
import wireTransferLogo from "../../../assets/img/bank.png";
import uniswapLogo from "../../../assets/img/uniswap-uni-logo.png";
import btcLogo from "../../../assets/img/BTCOrangeSquare.png";
import _ from "lodash";
import WithdrawBTCModal from "./WithdrawBTCModal";
import PaymentRequestModal from "./PaymentRequestModal";
import StripeTooltip from "./StripeTooltip";
import WireTooltip from "./WireTooltip";
import Uniswaptooltip from "./Uniswaptooltip";
import BtcTooltip from "./BtcTooltip";

let api;

class BudgetModal extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      address: "",
      amount: "",
      show: false,
      timeHorizon: "",
      email: "",
      btcModalOpen: false,
      stripeModalOpen: false,
      onOpened: false,
      paymentRequestButtonLoading: false,
      paymentRequestModalOpen: false,
      earningData: [],
      senderWalletAddress: "",
      starter: true,
      fileName: "MinerData",
      showInsideModal: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeAddress = this.onChangeAddress.bind(this);
    this.onChangeAmount = this.onChangeAmount.bind(this);
    this.withdrawOnCloseModal = this.withdrawOnCloseModal.bind(this);
    this.withdrawShowModal = this.withdrawShowModal.bind(this);
    this.setShow = this.setShow.bind(this);
    this.fileUpdated = this.fileUpdated.bind(this);
    this.stripeOnCloseModal = this.stripeOnCloseModal.bind(this);
    this.onChangeTimeHorizon = this.onChangeTimeHorizon.bind(this);
    this.onPaymentRequestCloseModal =
      this.onPaymentRequestCloseModal.bind(this);
    this.showPaymentRequestModal = this.showPaymentRequestModal.bind(this);
    this.clearTime = this.clearTime.bind(this);
    this.setShowInsideModal = this.setShowInsideModal.bind(this);
    this.setHideInsideModal = this.setHideInsideModal.bind(this);
    this.stripeShowModal = this.stripeShowModal.bind(this);
    this.uploadImageArray = {};
  }

  setShowInsideModal() {
    this.setState({ showInsideModal: false });
  }
  stripeOnCloseModal() {
    this.setState({ stripeModalOpen: false });
  }
  stripeShowModal() {
    this.setState({ stripeModalOpen: true });
  }

  setHideInsideModal() {
    this.setState({ showInsideModal: true });
  }

  async componentDidMount() {
    const response = await api.create("miner/getPaymentCredentials", {
      paymentMode: "stripe",
    });

    if (response.code === 200) {
      this.setState({ stripeKey: response.data[0].key });
    }

    const addreesResponse = await api.create("user/getUserDetails");

    if (addreesResponse.code === 200) {
      this.setState({
        senderWalletAddress: addreesResponse.data.btcWalletAddress,
        email: addreesResponse.data.email,
      });
    }
  }
  async setShow(startDate, endDate) {
    if (startDate && endDate) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  }
  async fileUpdated(fileItems) {
    if (!_.isUndefined(fileItems) && fileItems != null && fileItems !== "") {
      this.uploadImageArray["file"] = fileItems[0].file;
    }
  }
  withdrawOnCloseModal() {
    this.setState({ btcModalOpen: false });
  }
  showPaymentRequestModal() {
    this.setState({ paymentRequestModalOpen: true });
  }
  onPaymentRequestCloseModal() {
    this.setState({ paymentRequestModalOpen: false });
  }
  withdrawShowModal() {
    this.setState({
      btcModalOpen: true,
    });
  }
  async clearTime() {
    this.setState({ startDate: "" });
    this.setState({ endDate: "" });
    this.setState({ timeHorizon: "" });
  }

  async onChangeTimeHorizon(time) {
    this.setState({
      timeHorizon: time,
    });
  }
  async onChangeAddress(e) {
    this.setState(
      {
        address: e.target.value,
      },
      () => {
        this.setShow(this.state.address, this.state.endDate);
      }
    );
  }
  async onChangeAmount(e) {
    this.setState(
      {
        amount: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
      }
    );
  }
  async onSubmit() {}

  render() {
    const {
      BudgetModalOpen,
      getRecords,
      authToken,
      reference,
      onCloseModalBudget,
      paymentSource,
    } = this.props;
    const {
      starter,
      uploadImageArray,
      btcModalOpen,
      paymentRequestModalOpen,
      showInsideModal,
      senderWalletAddress,
      email,
    } = this.state;
    return (
      <Modal
        open={BudgetModalOpen}
        onClose={onCloseModalBudget}
        showCloseIcon={false}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: `instructon-modal-container billing-payment-modal newOverlay ${
            showInsideModal ? "d-none" : "d-block"
          }`,
        }}
      >
        <div className="onboarding-modal ">
          <div className="modal-dialog " role="document">
            <div className="modal-content ">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content onboarding-content2">
                    {starter && (
                      <div>
                        <h2
                          className="modal-heading text-center payHeading mr-4 text-black"
                          style={{
                            alignSelf: "flex-start !important",
                            color: "black",
                          }}
                        >
                          Please Select the Payment Method
                        </h2>
                        <div className=" d-flex  mt-4 justify-content-center align-items-center">
                          <span
                            className="custom-tooltip-coin2 row justify-content-center s-icon-margin"
                            tooltip-title={`import Wallet`}
                          >
                            <Link
                              to={{
                                pathname:
                                  paymentSource === "budget"
                                    ? "budgets"
                                    : "dec_stripe",
                                state: {
                                  type: paymentSource,
                                  paymentSource: paymentSource
                                },
                              }}
                            >
                              <img
                                className="paymentIcons hovershadow"
                                src={stripeLogo}
                                style={{
                                  borderRadius: "10px",
                                  background: "#e9e9e9",
                                }}
                                alt=""
                                onClick={() => {
                                  onCloseModalBudget();
                                }}
                              />
                            </Link>
                            <StripeTooltip />
                          </span>
                          {paymentSource === "budget" && (
                            <span
                              className="iconMargin custom-tooltip-coin2 row justify-content-center b-icon-margin"
                              tooltip-title={`import Wallet`}
                            >
                              <div
                                className="paymentIcons hovershadow text-center"
                                style={{
                                  background: "black",
                                  borderRadius: "10px",
                                }}
                              >
                                <img
                                  className="paymentIcons hovershadow"
                                  style={{
                                    position: "relative",
                                    left: "-5px",
                                    top: "-5px",
                                    filter: "invert(100%)",
                                    borderRadius: "10px",
                                  }}
                                  src={wireTransferLogo}
                                  alt=""
                                  onClick={() => {
                                    this.showPaymentRequestModal();
                                  }}
                                />
                              </div>

                              <WireTooltip />
                            </span>
                          )}

                          <PaymentRequestModal
                            {...this.props}
                            {...this.state}
                            paymentSource={paymentSource}
                            onCloseModal={this.onPaymentRequestCloseModal}
                            paymentRequestModalOpen={paymentRequestModalOpen}
                            onChangeCurrencyType={this.onChangeCurrencyType}
                            onAmountChange={this.onAmountChange}
                            authToken={authToken}
                            onCloseModalBudget={onCloseModalBudget}
                            onInputValueChange={this.onchange}
                            getRecords={getRecords}
                            email={email}
                            reference={reference}
                            onUpdateAmount={this.onUpdateTransactionAmount}
                            uploadImageArray={uploadImageArray}
                          />
                          {paymentSource === "budget" && (
                            <span
                              className="iconMargin custom-tooltip-coin2 row justify-content-center"
                              tooltip-title={`import Wallet`}
                            >
                              <img
                                className="paymentIcons1 hovershadow"
                                style={{
                                  borderRadius: "15px",
                                }}
                                src={btcLogo}
                                onClick={() => {
                                  this.withdrawShowModal();
                                }}
                                alt=""
                              />
                              <BtcTooltip />
                            </span>
                          )}
                          {paymentSource === "DEC" && (
                            <span
                              className="custom-tooltip-coin2 row justify-content-center "
                              tooltip-title={`import Wallet`}
                            >
                              <img
                                className="paymentIcons hovershadow"
                                src={uniswapLogo}
                                style={{
                                  borderRadius: "10px",
                                  background: "#e1dddd",
                                }}
                                onClick={() => {
                                  window.open(
                                    "https://app.uniswap.org/#/swap?exactField=inputCurrency=ETH&exactAmount=1000&outputCurrency=0xa6D7BCc3c352700d891DA2B5DCaf2fa7A2A0c383"
                                  );
                                }}
                                alt=""
                              />
                              <Uniswaptooltip />
                            </span>
                          )}
                        </div>
                        <div className="d-flex justify-content-center mt-4 ">
                          <button
                            className="btn modal-button cancel-button mr-2"
                            type="button"
                            onClick={() => {
                              onCloseModalBudget();
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <WithdrawBTCModal
          btcModalOpen={btcModalOpen}
          onCloseModalBudget={onCloseModalBudget}
          withdrawOnCloseModal={this.withdrawOnCloseModal}
          senderWalletAddress={senderWalletAddress}
          paymentSource={paymentSource}
          getRecords={getRecords}
          reference={reference}
          onInputValueChange={this.onchange}
        />
      </Modal>
    );
  }
}
export default BudgetModal;
