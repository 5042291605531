import React, { Component } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import * as messageConstants from "../../utils/Messages";
import _ from "lodash";
import Api from "../../services/api";
import { pageProgress } from "../../utils/Util";

let api;

class Orders extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      machinePage: 1,
      macineSizePerPage: 10,
      machineLoading: false,
      machineData: null,
      machineTotalSize: 0,
      eshopMachinePage: 1,
      eshopMacineSizePerPage: 10,
      eshopMachineLoading: false,
      eshopMachineData: null,
      eshopMachineTotalSize: 0,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  async componentDidMount() {
    pageProgress("force_remove");
    document.title =
      "My Orders" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.getMachineRecords();
    this.getMachineRecordsFromEShop();
  }

  async getMachineRecords() {
    const { macineSizePerPage, machinePage } = this.state;
    this.setState({ machineLoading: true });
    const response = await api.get("user/machines", {
      sizePerPage: macineSizePerPage,
      page: machinePage,
    });

    this.setState({
      machineLoading: false,
    });
    if (response.code !== 200) {
      return alert("Fail to fetch machines");
    }
    this.setState({
      machineData: response.data.machines,
      machineTotalSize: response.data.totalMachines,
    });
  }

  async getMachineRecordsFromEShop() {
    const { eshopMacineSizePerPage, eshopMachinePage } = this.state;
    this.setState({ machineLoading: true });
    const response = await api.get("eshop/machinesForMiner", {
      sizePerPage: eshopMacineSizePerPage,
      page: eshopMachinePage,
    });
    if (response.code === 200) {
      return this.setState({
        eshopMachineData: response.data.machines,
        eshopMachineTotalSize: response.data.totalMachines,
      });
    }
    this.setState({
      eshopMachineLoading: false,
    });
    alert("Fail to fetch machines from eShop");
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (
      this.state.sizePerPage !== sizePerPage ||
      this.state.machineData !== page
    ) {
      this.setState({ sizePerPage: sizePerPage, machineData: page }, () => {
        this.getMachineRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getMachineRecords();
        });
        return true;
      }
    }
  };

  render() {
    const {
      machineLoading,
      machineData,
      machinePage,
      macineSizePerPage,
      machineTotalSize,
      eshopMachineLoading,
      eshopMachineData,
      eshopMachinePage,
      eshopMacineSizePerPage,
      eshopMachineTotalSize,
    } = this.state;

    const machineColumns = [
      {
        headerClasses: "custom-table-th",
        style: { whiteSpace: "nowrap" },
        dataField: "invoiceNumber",
        text: "Invoice #",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        style: { whitSpace: "nowrap" },
        dataField: "manufacturer",
        text: "Manufacturer",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "model",
        text: "Model",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "algorithm",
        text: "Algorithm",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "hashrate",
        text: "Hashrate (TH)",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "powerConsumption",
        text: "power Consumption (W)",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "priceInUsd",
        text: "price in USD",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "quantity",
        text: "Quantity",
        sort: true,
      },
      {
        headerClasses: "custom-table-th",
        dataField: "status",
        text: "Status",
      },
      {
        headerClasses: "custom-table-th",
        dataField: "trackLink",
        text: "Operations",
        formatter: function (cell, row) {
          return (
            <div
              className="text-left"
              style={{ width: "150px", overflow: "hidden" }}
            >
              {!_.isUndefined(cell) && (
                <div>
                  <a
                    className="btn btn-primary btn-xs"
                    rel="noopener noreferrer"
                    href={row.trackLink}
                    target="_blank"
                  >
                    Track
                  </a>

                  <a
                    className="btn btn-info btn-xs ml-1"
                    rel="noopener noreferrer"
                    href="mailto:sales@permianchain.com"
                    target="_blank"
                  >
                    <i className="fa fa-mail"></i> Email
                  </a>
                </div>
              )}
              {(_.isUndefined(cell) || cell === "") && <div>N/A</div>}
            </div>
          );
        },
      },
    ];

    const MachineRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
      isLoading,
    }) => (
      <BootstrapTable
        remote
        keyField="_id"
        bordered={false}
        loading={isLoading}
        data={data}
        columns={machineColumns}
        pagination={
          machinePage > 1
            ? paginationFactory({ page, sizePerPage, totalSize })
            : undefined
        }
        onTableChange={onTableChange}
        noDataIndication="No results!"
        overlay={overlayFactory({
          spinner: true,
          background: "rgba(192,192,192,0.3)",
        })}
        classes="table table-striped table-lightfont dataTable"
      />
    );

    MachineRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
      isLoading: PropTypes.bool.isRequired,
    };

    return (
      <div className="buy-xpr-container ">
        <div className="content-i operation_page_main dashboard-page-main">
          <div className="content-box">
            <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
              <div className="card-header">
                <h4 className="card-title-font form-table-header">
                  Offline Orders
                </h4>
              </div>
              <div className="table-responsive">
                {!!machineData && (
                  <MachineRemoteAll
                    data={machineData}
                    page={machinePage}
                    sizePerPage={macineSizePerPage}
                    totalSize={machineTotalSize}
                    onTableChange={this.handleTableChange}
                    isLoading={machineLoading}
                  />
                )}
              </div>
            </div>

            <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4">
              <div className="card-header">
                <h4 className="card-title-font form-table-header">
                  Online Orders
                </h4>
              </div>
              <div className="table-responsive">
                {!!eshopMachineData && (
                  <MachineRemoteAll
                    data={eshopMachineData}
                    page={eshopMachinePage}
                    sizePerPage={eshopMacineSizePerPage}
                    totalSize={eshopMachineTotalSize}
                    onTableChange={this.handleTableChange}
                    isLoading={eshopMachineLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Orders;
