import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import Api from "../../services/api";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import * as messageConstants from "../../utils/Messages";
import { Link } from "react-router-dom";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";

let api;

class Listings extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      renderFlag: false,
      buttonLoading: false,
      page: 1,
      sizePerPage: 10,
      filters: "",
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentWillMount() {
    document.title =
      messageConstants.LISTINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("display");
    this.getRecords();
  }

  async getRecords() {
    const { sizePerPage, page } = this.state;
    let { filters } = this.state;
    let authenticationToken = this.props.authToken;
    filters = JSON.stringify(filters);
    try {
      const response = await api
        .setToken(authenticationToken)
        .get("user/listings/list", {
          sizePerPage: sizePerPage,
          page: page,
          filters: filters,
        });
      if (response.code === 200) {
        this.setState({
          renderFlag: true,
          data: response.data.listings,
          totalSize: response.data.totalListings,
        });
      }
      pageProgress("remove");
    } catch (e) {
      pageProgress("force_remove");
      this.setState({
        renderFlag: true,
        data: [],
        totalSize: 0,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  render() {
    const { data, sizePerPage, page, renderFlag } = this.state;
    const columns = [
      {
        headerClasses: "text-bold",
        style: { "white-space": "nowrap" },
        dataField: "listingId",
        text: "#ID",
      },
      {
        headerClasses: "text-bold",
        dataField: "location",
        text: "Location",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          var location = cell !== "" && cell !== null ? cell : "N/A";
          return <div>{location}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "averageDailyProductionInBBL",
        text: "Average Daily Production In BBL",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return <div className="text-center">{cell}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "averageDailyProductionInBOE",
        text: "Average Daily Production In BOE",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-center">
              {cell} {row.totalProbablePossibleReservesUnit}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        text: "Date",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          var date = moment(cell).format("DD/MM/YYYY HH:mm");
          return <div>{date}</div>;
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "operations",
        text: "Operations",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let redirectLink = "/listing_detail/" + row._id;
          let buyBarrelsLink = "/barrels/buy/" + row._id;
          return (
            <div>
              <div className="d-inline-block">
                <Link to={redirectLink} className="color-white">
                  <button className="mr-2 mb-2 btn btn-warning" type="button">
                    Details
                  </button>
                </Link>
                <Link
                  to={`/progress_reports/${row._id}`}
                  className="color-white"
                >
                  <button className="mr-2 mb-2 btn btn-info" type="button">
                    Progress Reports
                  </button>
                </Link>
                <Link to={buyBarrelsLink} className="color-white">
                  <button className="mr-2 mb-2 btn btn-success" type="button">
                    Buy
                  </button>
                </Link>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div>
              <div className="element-box">
                <h5 className="form-header">
                  <span>Listings</span>
                </h5>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={data}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={this.state.totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.token,
});

export default connect(mapStateToProps)(Listings);
