import {
  STORE_USER_INFO,
  STORE_AUTHENTICATION_TOKEN,
  CLEAR_USER,
  STORE_USER_DETAILS,
} from "../actions/types";
import auth from "../../utils/auth";

const INITIAL_STATE = {
  userInfo: auth.getUserInfo(),
  token: auth.getToken(),
  userDetails: {},
};

export const UserReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case STORE_USER_INFO:
      auth.setUserInfo(payload);
      return {
        ...state,
        userInfo: payload,
      };
    case STORE_AUTHENTICATION_TOKEN:
      auth.setToken(payload);
      return {
        ...state,
        token: payload,
      };
    case CLEAR_USER:
      auth.clearUserInfo();
      auth.clearToken();
      return {
        userInfo: {},
        token: null,
      };
    case STORE_USER_DETAILS:
      return {
        ...state,
        userDetails: payload,
      };
    default:
      return state;
  }
};
