const correctValue = (number) => {
  if (number) {
    var roundedNumber;
    roundedNumber = Math.round(1000 * number) / 1000;
    if (roundedNumber > 0) return roundedNumber;
    roundedNumber = Math.round(10000 * number) / 10000;
    if (roundedNumber > 0) return roundedNumber;
    roundedNumber = Math.round(100000 * number) / 100000;
    if (roundedNumber > 0) return roundedNumber;
    roundedNumber = Math.round(1000000 * number) / 1000000;
    if (roundedNumber > 0) return roundedNumber;
    var numberArray = String(number).split("e-");
    if (numberArray.length > 1) {
      var toFixedNum = parseInt(numberArray[1]) + 3;
      return number.toFixed(toFixedNum);
    } else {
      return number;
    }
  }
};

const initCorrectValue = (number) => {
  if (number) {
    var roundedNumber;
    roundedNumber = Math.round(1000 * number) / 1000;
    if (roundedNumber > 0) return roundedNumber;
    roundedNumber = Math.round(10000 * number) / 10000;
    if (roundedNumber > 0) return roundedNumber;
    roundedNumber = Math.round(100000 * number) / 100000;
    if (roundedNumber > 0) return roundedNumber;
    roundedNumber = Math.round(1000000 * number) / 1000000;
    if (roundedNumber > 0) return roundedNumber;
    var numberArray = String(number).split("e-");
    if (numberArray.length > 1) {
      var toFixedNum = parseInt(numberArray[1]) + 3;
      return number.toFixed(toFixedNum);
    } else {
      return number;
    }
  }
};

const hashrateConverter = (
  type,
  newHashRateType = "",
  value,
  flag = "display"
) => {
  const convertConstant = {
    hash: {
      hash: 1,
      kilohash: 0.001,
      megahash: 0.000001,
      gigahash: 0.000000001,
      terahash: 0.000000000001,
      petahash: 0.000000000000001,
      exahash: 0.000000000000000001,
    },
    kilohash: {
      hash: 1000,
      kilohash: 1,
      megahash: 0.001,
      gigahash: 0.000001,
      terahash: 0.000000001,
      petahash: 0.000000000001,
      exahash: 0.000000000000001,
    },
    megahash: {
      hash: 1000000,
      kilohash: 1000,
      megahash: 1,
      gigahash: 0.001,
      terahash: 0.000001,
      petahash: 0.000000001,
      exahash: 0.000000000001,
    },
    gigahash: {
      hash: 1000000000,
      kilohash: 1000000,
      megahash: 1000,
      gigahash: 1,
      terahash: 0.001,
      petahash: 0.000001,
      exahash: 0.000000001,
    },
    terahash: {
      hash: 1000000000000,
      kilohash: 1000000000,
      megahash: 1000000,
      gigahash: 1000,
      terahash: 1,
      petahash: 0.001,
      exahash: 0.000001,
    },
    petahash: {
      hash: 1000000000000000,
      kilohash: 1000000000000,
      megahash: 1000000000,
      gigahash: 1000000,
      terahash: 1000,
      petahash: 1,
      exahash: 0.001,
    },
    exahash: {
      hash: 1000000000000000000,
      kilohash: 1000000000000000,
      megahash: 1000000000000,
      gigahash: 1000000000,
      terahash: 1000000,
      petahash: 1000,
      exahash: 1,
    },
  };

  const hashValue = correctValue(convertConstant[type]["hash"] * value);
  const kilohashvalue = correctValue(convertConstant[type]["kilohash"] * value);
  const megahashvalue = correctValue(convertConstant[type]["megahash"] * value);
  const gigahashvalue = correctValue(convertConstant[type]["gigahash"] * value);
  const terahashvalue = correctValue(convertConstant[type]["terahash"] * value);
  const petahashvalue = correctValue(convertConstant[type]["petahash"] * value);
  const exahashvalue = correctValue(convertConstant[type]["exahash"] * value);

  if (flag === "display") {
    return newHashRateType === "hash"
      ? hashValue
      : newHashRateType === "kilohash"
      ? kilohashvalue
      : newHashRateType === "megahash"
      ? megahashvalue
      : newHashRateType === "gigahash"
      ? gigahashvalue
      : newHashRateType === "terahash"
      ? terahashvalue
      : newHashRateType === "petahash"
      ? petahashvalue
      : exahashvalue;
  }
  return hashValue;
};

const powerConsumptionConverter = (
  type,
  newPowerConverterType,
  value,
  flag = "display"
) => {
  const convertConstant = {
    watt: {
      watt: 1,
      kilowatt: 1000,
      megawatt: 1000000,
      gigawatt: 1000000000,
      terawatt: 1000000000000,
    },
    kilowatt: {
      watt: 0.001,
      kilowatt: 1,
      megawatt: 1000,
      gigawatt: 1000000,
      terawatt: 1000000000,
    },
    megawatt: {
      watt: 0.000001,
      kilowatt: 0.001,
      megawatt: 1,
      gigawatt: 1000,
      terawatt: 1000000,
    },
    gigawatt: {
      watt: 0.000000001,
      kilowatt: 0.000001,
      megawatt: 0.001,
      gigawatt: 1,
      terawatt: 1000,
    },
    terawatt: {
      watt: 0.000000000001,
      kilowatt: 0.000000001,
      megawatt: 0.000001,
      gigawatt: 0.001,
      terawatt: 1,
    },
  };

  const wattValue = correctValue(value / convertConstant[type]["watt"]);
  const kilowattValue = correctValue(value / convertConstant[type]["kilowatt"]);
  const megawattValue = correctValue(value / convertConstant[type]["megawatt"]);
  const gigawattValue = correctValue(value / convertConstant[type]["gigawatt"]);
  const terawattValue = correctValue(value / convertConstant[type]["terawatt"]);

  if (flag === "display") {
    return newPowerConverterType === "watt"
      ? wattValue
      : newPowerConverterType === "kilowatt"
      ? kilowattValue
      : newPowerConverterType === "megawatt"
      ? megawattValue
      : newPowerConverterType === "gigawatt"
      ? gigawattValue
      : newPowerConverterType === "terawatt"
      ? terawattValue
      : "";
  }
  return wattValue;
};

const initHashrateConverter = (type, value) => {
  let terahashvalue = 0;
  if (type === "hash") {
    terahashvalue = initCorrectValue(value / 1000000000000);
  } else if (type === "kilohash") {
    terahashvalue = initCorrectValue(value / 1000000000);
  } else if (type === "megahash") {
    terahashvalue = initCorrectValue(value / 1000000);
  } else if (type === "gigahash") {
    terahashvalue = initCorrectValue(value / 1000);
  } else if (type === "terahash") {
    terahashvalue = initCorrectValue(value);
  } else if (type === "petahash") {
    terahashvalue = initCorrectValue(value * 1000);
  } else if (type === "exahash") {
    terahashvalue = initCorrectValue(value * 1000000);
  }
  return terahashvalue;
};

module.exports = {
  hashrateConverter,
  powerConsumptionConverter,
  initHashrateConverter,
};
