import React, { Component } from "react";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import { pageProgress } from "../../utils/Util";
let api;

class BuyBarrels extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      totalUnlockedValue: "",
      popoverOpen: false,
      listingDetail: "",
      listingFiles: "",
      objectIdRef: "",
    };
  }

  async componentWillMount() {
    document.title =
      messageConstants.DASHBOARD_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("display");
    let listingid = this.props.match.params.listingid;
    let kycVerifiedResponse = await this.props.checkKycVerified();
    if (kycVerifiedResponse.status === true) {
      const reserveDetails = await api.get("user/listing/details", {
        listingId: listingid,
      });
      if (reserveDetails.code === 200) {
        this.setState({
          listingDetail: reserveDetails.data.listingDetail,
          listingFiles: reserveDetails.data.listingFiles,
          objectIdRef: reserveDetails.data.objectIdRef,
        });
      }
    } else {
      this.props.history.push(kycVerifiedResponse.redirectUrl);
    }
    pageProgress("force_remove");
  }

  render() {
    const { listingDetail } = this.state;
    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            <h2 className="color-white text-center">Buy Barrels</h2>
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="mt-3 color-white">
                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-3">
                      <div className="text-left">
                        <span>Location</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-left">
                        <span>: ArRayn Station</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-3">
                      <div className="text-left">
                        <span>Location Id</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-left">
                        <span>: {listingDetail.listingId}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-3">
                      <div className="text-left">
                        <span>Average Daily Production In BBL</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-left">
                        <span>
                          : {listingDetail.averageDailyProductionInBBL}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-3">
                      <div className="text-left">
                        <span>Average Daily Production In BOE</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="text-left">
                        <span>
                          : {listingDetail.averageDailyProductionInBOE}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3"></div>
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <span className="xpr-balance-display color-white">
                  Balance: 53,763,3365 XPR Tokens
                </span>
                <button className="btn btn-primary ml-3">Sell</button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <span>1 Barrel = $55</span>

                <span className="pull-left pr-3 buy-xpr-label">Amount:</span>

                <input
                  className="pl-2 buy-xpr-input dark-blue-font-color"
                  type="text"
                  id="fullName"
                  name="contributionAmount"
                />

                <select
                  className="buy-xpr-currencey-select"
                  name="contributionCurrency"
                >
                  <option value="">BBL</option>
                  <option value="bitcoin">MBBL</option>
                  <option value="litecoin">MMBBL</option>
                </select>

                <span> = </span>
                <input
                  className="pl-2 buy-xpr-input dark-blue-font-color"
                  type="text"
                  id="fullName"
                  name="contributionAmount"
                />
                <select
                  className="buy-xpr-currencey-select"
                  name="contributionCurrency"
                >
                  <option value="USD">USD</option>
                  <option value="BTC">BTC</option>
                  <option value="ETH">ETH</option>
                  <option value="USDT">USDT</option>
                </select>

                <span>You will reserve: </span>
                <span>14 MBBL</span>

                <button className="btn btn-primary">Back</button>

                <button className="btn btn-primary">Checkout</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BuyBarrels;
