import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import { niceNumberNoDecimalDisplay } from "../../utils/Util";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { API_NETWORK } from "../../services/api-config";
import { fetchUserDetails } from "../../store/actions/UserAction";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import { toast } from "react-toastify";
import * as messageConstants from "../../utils/Messages";
import Api from "../../services/api";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";

let api;

class ViewPageEth extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      buttonLoading: false,
      page: 1,
      sizePerPage: 20,
      filters: "",
      decWalletAddress: "",
      name: "",
      tableLoading: true,
      email: "",
      status: "",
      statusText: "",
      totalSize: "",
      ordersRenderFlag: false,
      ordersButtonLoading: false,
      ordersPage: 1,
      ordersSizePerPage: 10,
      ordersFilters: "",
      recordData: [],
      transactionData: [],
      renderFlag: true,
    };
  }

  copyDepositAddress = () => {
    let msg = "Address successfully copied!";
    toast.success(msg);
  };

  getTransaction = async (email) => {
    const { page, sizePerPage } = this.state;
    const response = await api.get("miner/viewDecToken", {
      sizePerPage,
      page,
      filterByEmail: email,
      filterByStatus: "",
      filterBySource: "DEC",
    });

    if (response.code === 200) {
      this.setState({
        recordData: response.data.decRequests,
        renderFlag: true,
        tableLoading: false,
        totalSize: response.data.totalDecRequestCount,
      });
    }
  };

  toPlainString = (num) => {
    return ("" + +num).replace(
      /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + "0." + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0);
      }
    );
  };

  setTableData = () => {
    const { sizePerPage, page, transactionData } = this.state;
    let data = [];
    if (page !== "") {
      for (let i = 0; i < page * sizePerPage; i++) {
        data.push(transactionData[i]);
      }
      data = data.slice(-sizePerPage);
    }
    const filterData = data.filter((data) =>
      data
        ? data.timeStamp && data.hash && data.to && data.value
          ? data
          : ""
        : ""
    );
    this.setState({
      recordData: filterData,
    });
  };

  componentDidMount() {
    document.title =
      messageConstants.EARNINGS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    const { decAddress } = this.props.location.state;
    pageProgress("remove");
    const { fetchUserDetails } = this.props;
    fetchUserDetails(async (userDetails) => {
      const { email } = userDetails;
      await this.getTransaction(email);
      this.setState({
        email,
      });
    });
    this.setState({ decWalletAddress: decAddress });
  }

  async getRecords() {
    const { decWalletAddress } = this.state;

    try {
      const response = await axios.get(
        `${API_NETWORK}?module=account&action=txlist&address=${decWalletAddress}&startblock=0&endblock=99999999&page=1&sort=asc&apikey=APJX4Q44UGSK2EUNQYUY31YSW8UGPY2D6V`
      );
      if (
        response.status === 200 &&
        response.data &&
        !_.isEmpty(response.data.result)
      ) {
        this.setState(
          {
            transactionData: response.data.result,
            totalSize: _.size(response.data.result),
            tableLoading: false,
          },
          () => {
            this.setTableData();
          }
        );
      }
      if (_.isEmpty(response.data.result)) {
        this.setState({ transactionData: [], tableLoading: false }, () => {
          this.setTableData();
        });
      }
    } catch (e) {
      pageProgress("force_remove");
      this.setState({
        renderFlag: true,
        data: [],
        totalSize: 0,
      });
    }
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    const { email } = this.state;
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getTransaction(email);
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getTransaction(email);
        });
        return true;
      }
    }
  };

  render() {
    const {
      renderFlag,
      tableLoading,
      recordData,
      totalSize,
      page,
      sizePerPage,
    } = this.state;
    const _this = this;
    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        headerFormatter: () => {
          return (
            <span className="ml-1">
              <span style={{ fontWeight: "bold" }}>DATE </span>
              <span style={{ fontWeight: "400" }}>(YYYY-MM-DD)</span>
            </span>
          );
        },
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div> {cell ? moment(cell).format("YYYY-MM-DD") : ""}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "hash",
        text: "TRANSACTION HASH",
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let smallAddress = "";
          if (row.txHash) {
            smallAddress = row.txHash;
            smallAddress = `${smallAddress.slice(0, 3)}...${smallAddress.slice(
              smallAddress.length - 3,
              smallAddress.length
            )}`;
          }
          return (
            <div>
              {row.txHash ? (
                <CopyToClipboard
                  text={row.txHash}
                  onCopy={() => _this.copyDepositAddress()}
                >
                  <span>
                    {smallAddress}
                    <i
                      className="fa fa-clipboard cursor-pointer ml-2 standardColor"
                      aria-hidden="true"
                    ></i>
                  </span>
                </CopyToClipboard>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "outstandingTokens",
        text: "Tokens",
        sort: true,
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <div>
              {row.status === "a"
                ? niceNumberNoDecimalDisplay(row.issuedTokens, 2)
                : niceNumberNoDecimalDisplay(row.outstandingTokens, 2)}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "",
        text: "Deposit Address",
        sort: true,
        formatter: function (cell, row) {
          let smallAddress = row.userId.receivingEthAddress;
          if (row.userId) {
            smallAddress = row.userId.receivingEthAddress;
            smallAddress = smallAddress
              ? `${smallAddress.slice(0, 3)}...${smallAddress.slice(
                  smallAddress.length - 3,
                  smallAddress.length
                )}`
              : "";
          }
          return (
            <div>
              {row.userId ? (
                <CopyToClipboard
                  text={row.userId.receivingEthAddress}
                  onCopy={() => _this.copyDepositAddress()}
                >
                  <span>
                    {smallAddress}
                    <i
                      className="fa fa-clipboard cursor-pointer ml-2 standardColor "
                      aria-hidden="true"
                    ></i>
                  </span>
                </CopyToClipboard>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-center text-bold",
        dataField: "paymentStatus",
        text: "STATUS",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let label = "Pending";
          let className = "badge-warning";
          if (row.status === "a") {
            label = "Complete";
            className = "badge-success";
          }
          return (
            <div>
              <div className="text-center">
                <div className="d-inline-block">
                  <span className={"badge1 " + className}>{label}</span>
                </div>
              </div>
            </div>
          );
        },
      },
    ];
    // I will need the columns array for next PR

    // const columns = [
    //   {
    //     headerClasses: "text-bold",
    //     dataField: "timeStamp",
    //     text: "TRANSACTION DATE",
    //     formatter: function (cell, row, rowIndex, formatExtraData) {
    //       let date = new Date(cell * 1000);
    //       return (
    //         <div className="text-left">
    //           <div>
    //             {" "}
    //             {moment(date.toUTCString()).format("DD,MMM YYYY HH:mm")}
    //           </div>
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     headerClasses: "text-bold",
    //     dataField: "hash",
    //     text: "TRANSACTION HASH",
    //     formatter: function (cell, row, rowIndex, formatExtraData) {
    //       let smallAddress = "";
    //       if (row.hash) {
    //         smallAddress = row.hash;
    //         smallAddress = `${smallAddress.slice(0, 3)}...${smallAddress.slice(
    //           smallAddress.length - 3,
    //           smallAddress.length
    //         )}`;
    //       }
    //       return (
    //         <div>
    //           {row.hash ? (
    //             <CopyToClipboard
    //               text={row.hash}
    //               onCopy={() => _this.copyDepositAddress()}
    //             >
    //               <span>
    //                 {smallAddress}
    //                 <i
    //                   className="fa fa-clipboard cursor-pointer ml-2 standardColor"
    //                   aria-hidden="true"
    //                 ></i>
    //               </span>
    //             </CopyToClipboard>
    //           ) : (
    //             ""
    //           )}
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     headerClasses: "text-bold",
    //     dataField: "value",
    //     text: "Amount",
    //     sort: true,
    //     isDummyField: true,
    //     formatter: function (cell, row) {
    //       let finalTokenValue = Math.ceil(Number(row.value));
    //       finalTokenValue = finalTokenValue / Math.pow(10, 18);
    //       return <div>{finalTokenValue}</div>;
    //     },
    //   },
    //   {
    //     headerClasses: "text-bold",
    //     dataField: "to",
    //     text: "Deposit Address",
    //     sort: true,
    //     formatter: function (cell, row) {
    //       let smallAddress = "";
    //       if (row.to) {
    //         smallAddress = row.to;
    //         smallAddress = `${smallAddress.slice(0, 3)}...${smallAddress.slice(
    //           smallAddress.length - 3,
    //           smallAddress.length
    //         )}`;
    //       }
    //       return (
    //         <div>
    //           {row.to ? (
    //             <CopyToClipboard
    //               text={row.to}
    //               onCopy={() => _this.copyDepositAddress()}
    //             >
    //               <span>
    //                 {smallAddress}
    //                 <i
    //                   className="fa fa-clipboard cursor-pointer ml-2 standardColor "
    //                   aria-hidden="true"
    //                 ></i>
    //               </span>
    //             </CopyToClipboard>
    //           ) : (
    //             ""
    //           )}
    //         </div>
    //       );
    //     },
    //   },
    // ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="_id"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper mt-4">
              <div className="element-box card-inner-padding">
                <div className="row justify-content-between">
                  <h5 className="text-xl">Transactions</h5>
                  <span>
                    <Link to="/wallets">
                      <i
                        className="fa fa-arrow-left standardColorIcon mr-4"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </Link>
                  </span>
                </div>
                <div className="clearfix"></div>
                <div>
                  {tableLoading && (
                    <div className="text-center">
                      <span id="loading" color="#047bf8" />
                    </div>
                  )}
                </div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={recordData}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUserInfo: state.user.userInfo,
  userDetails: state.user.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserDetails: (success) => dispatch(fetchUserDetails(success)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPageEth);
