import React, { Component } from "react";
import Modal from "react-responsive-modal";
class UpgradeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      show: false,
      timeHorizon: "",
      paymentRequestButtonLoading: false,
      earningData: [],
      fileName: "MinerData",
    };
  }
  setShow = async (startDate, endDate) => {
    if (startDate && endDate) {
      this.setState({ show: true });
    } else {
      this.setState({ show: false });
    }
  };
  clearTime = () => {
    this.setState({ startDate: "" });
    this.setState({ endDate: "" });
    this.setState({ timeHorizon: "" });
  };

  onChangeTimeHorizon = (time) => {
    this.setState({
      timeHorizon: time,
    });
  };
  onChangeAddress = (e) => {
    this.setState(
      {
        address: e.target.value,
      },
      () => {
        this.setShow(this.state.address, this.state.endDate);
      }
    );
  };

  render() {
    let {
      upgradeModalOpen,
      upgradeModalClose,
      onQuantityChange,
      upgradeSubscription,
      upgradeAmount,
      quantity,
    } = this.props;
    return (
      <Modal
        open={upgradeModalOpen}
        onClose={upgradeModalClose}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <h4
                      className="text-black"
                      style={{ alignSelf: "flex-start !important" }}
                    >
                      Upgrade Subscription
                    </h4>
                    <div className="form-group text-black">
                      <label htmlFor="title">
                        Quantity<span className="required-field">*</span>
                      </label>
                      <input
                        className="form-control w-100 "
                        id="Quantity"
                        type="number"
                        placeholder="Enter Quantity"
                        value={quantity}
                        onChange={onQuantityChange}
                      />
                    </div>
                    <div className="form-group text-black">
                      <label htmlFor="title">
                        Amount<span className="required-field">*</span>
                      </label>
                      <input
                        className="form-control w-100 "
                        id="amount"
                        type="text"
                        placeholder="Enter Amount"
                        disabled={true}
                        value={`$ ${upgradeAmount}`}
                      />
                    </div>
                    <div className="d-flex justify-content-end mt-4">
                      <button
                        className="btn modal-button mr-2 cancel-button"
                        type="button"
                        onClick={upgradeModalClose}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn modal-button update-button"
                        type="button"
                        disabled={quantity ? false : true}
                        onClick={upgradeSubscription}
                      >
                        {" "}
                        Confirm{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default UpgradeModal;
