import React, { Component } from "react";
import Modal from "react-responsive-modal";

class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      changePasswordModalOpen,
      onCloseModal,
      onInputValueChange,
      changePasswordButtonLoading,
      updatePassword,
    } = this.props;
    let buttonDisabled = changePasswordButtonLoading === true ? true : false;
    return (
      <Modal
        open={changePasswordModalOpen}
        onClose={onCloseModal}
        showCloseIcon={false}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog changePassword" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title text-large">
                      Change Password
                    </h4>
                    <div className="text-left">
                      <ul className="features-list">
                        <li>
                          Pick a strong password. Use a combination of numbers,
                          letters & special characters.
                        </li>
                        <li>
                          It should be different from other passwords you use
                          elsewhere on the internet. Change your password
                          regularly.
                        </li>
                      </ul>
                    </div>
                    <form>
                      <div className="form-group">
                        <label for="transactionAmount">
                          Current Password
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Enter current password"
                          type="password"
                          name="oldPassword"
                          id="oldPassword"
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="form-group">
                        <label for="transactionAmount">
                          New Password<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Enter current password"
                          type="password"
                          name="newPassword"
                          id="newPassword"
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="form-group">
                        <label for="transactionAmount">
                          Confirm New Password
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Enter current password"
                          type="password"
                          name="confirmNewPassword"
                          id="confirmNewPassword"
                          onChange={onInputValueChange}
                        />
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn standardColor account-button my-account-button"
                          type="button"
                          onClick={onCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary permian-button custom-button-padding"
                          type="button"
                          disabled={buttonDisabled}
                          onClick={updatePassword}
                        >
                          Update{" "}
                          {changePasswordButtonLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default ChangePasswordModal;
