import React from "react";
import { Link } from "react-router-dom";
const NotSubscribedTooltip = () => {
  return (
    <div className="earningTooltip1">
      <span className=".subInfo" style={{ fontSize: "12px" }}>
        Subscribe For a Power Pack to enable Auto Payments,{" "}
        <Link to="/subscriptions">Click here</Link>
      </span>
    </div>
  );
};

export default NotSubscribedTooltip;
