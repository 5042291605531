import React from "react";
import Select from "react-select";
import CardInput from "./CardInput";

const DefaultCard = ({
  usercountry,
  countriesList,
  optionRef,
  handleCountryChange,
  countryIdValue,
  email,
  city,
  unitAmount,
  amount,
  line1,
  stripeCustomerId,
  paymentSource,
  state,
  upgradeBudget,
  depositLoading,
  qty,
  isFixedOfftake,
  setEmail,
  setCity,
  setAmount,
  setLine1,
  setState,
  setQty,
  handleSubmitPay,
}) => {
  if (qty) {
    setQty(qty);
    setAmount(Number(qty * unitAmount).toFixed(2));
  }
  return (
    <div>
      {!stripeCustomerId && (
        <>
          <input
            placeholder="Email"
            className="w-100 mui-field mt-3"
            id="outlined-email-input"
            margin="normal"
            variant="outlined"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <input
            placeholder="Address"
            className="w-100 mui-field mt-3"
            margin="normal"
            variant="outlined"
            type="text"
            required
            value={line1}
            onChange={(e) => setLine1(e.target.value)}
            fullWidth
          />
          <Select
            autoFocus={false}
            className="country-select4 w-100 mt-3"
            classNamePrefix="contry"
            options={countriesList}
            placeholder="Select Country"
            name="countryId"
            id="country"
            ref={optionRef}
            onChange={handleCountryChange}
            value={{
              label: usercountry,
              value: countryIdValue,
            }}
            // onMenuOpen={onMenuOpen}
          />
          <input
            placeholder="City"
            className="w-100 mui-field mt-3"
            margin="normal"
            variant="outlined"
            type="text"
            required
            value={city}
            onChange={(e) => setCity(e.target.value)}
            fullWidth
          />
          <input
            placeholder="State"
            className="w-100 mui-field mt-3"
            margin="normal"
            variant="outlined"
            type="text"
            required
            value={state}
            onChange={(e) => setState(e.target.value)}
            fullWidth
          />
        </>
      )}
      {isFixedOfftake && (
        <span>
          <br/>Tokens:
        </span>
      )}
      <input
        min="0"
        className="w-100 mui-field mt-3"
        margin="normal"
        variant="outlined"
        type="number"
        placeholder={paymentSource === "budget" ? "Quantity" : "Tokens"}
        disabled={isFixedOfftake ? true : false}
        required
        value={qty}
        onChange={(e) => {
          setQty(e.target.value);
          setAmount(Number(e.target.value * unitAmount).toFixed(2));
        }}
        fullWidth
      />
      {isFixedOfftake && (
        <span>
          <br/><br/>Amount:
        </span>
      )}
      <input
        min="0"
        placeholder={paymentSource === "budget" ? "Budget" : "Amount"}
        className="w-100 mui-field mt-3"
        margin="normal"
        variant="outlined"
        type="number"
        disabled={paymentSource === "budget" || 
                  isFixedOfftake ? true : false}
        required
        value={amount}
        onChange={(e) => {
          if (!paymentSource || paymentSource === "DEC") {
            setAmount(e.target.value);
            setQty(Math.round(e.target.value / unitAmount));
          }
        }}
        fullWidth
      />

      {!stripeCustomerId && <CardInput />}
      <div className="row align-items-start justify-content-center">
        <button
          className="btn btn-primary mt-3 ml-1"
          onClick={() => {
            if (!stripeCustomerId) {
              handleSubmitPay();
            } else {
              upgradeBudget();
            }
          }}
        >
          Deposit
          {depositLoading && (
            <i className="fa-spin fa fa-spinner text-white ml-2" />
          )}
        </button>
      </div>
    </div>
  );
};

export default DefaultCard;
