import React, { Component } from "react";
import * as messageConstants from "../../utils/Messages";
import _ from "lodash";
import Api from "../../services/api";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";
import CalculatorComponent from "./MiningCalculatorComponent";
import { toast } from "react-toastify";
import { hashrateConverter, powerConsumptionConverter } from "./helper";

let api;

class Calculator extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      selectedProduct: props.selectedProduct,
      quantity: props.quantity,
      readOnly: !!props.readOnly,
    };
    this.getStats = this.getStats.bind(this);
    this.defaultMining = this.defaultMining.bind(this);
  }

  getStats = async () => {
    const response = await api.get("miner/getFeeAndRateofElectricity");
    if (response.code === 200 && response.data && response.data.feeAndROE) {
      this.setState({
        poolFees: response.data.feeAndROE.poolFee,
        powerCost: response.data.feeAndROE.rateOfElectricity,
        costPerTeraHash: response.data.feeAndROE.costPerTeraHash,
        transactionFeeRate: response.data.feeAndROE.transactionFeeRate,
      });
      pageProgress("force_remove");
    }
  };

  defaultMining = async () => {
    const response = await api.get("user/mining/default");
    if (response.code === 200 && response && response.data) {
      const { blockReward, difficultyRateDisplay, exchangeRate } =
        response.data;
      this.setState({
        blockReward,
        difficultyRate: difficultyRateDisplay,
        exchangeRate,
      });
      pageProgress("force_remove");
    }
  };

  calculate = async () => {
    const {
      hashRateType,
      hashRate,
      powerConsumptionType,
      powerConsumption,
      powerCost,
      blockReward,
      difficultyRate,
      exchangeRate,
      poolFees,
      budget,
      costPerTeraHash,
      transactionFeeRate,
    } = this.state;

    if (
      budget &&
      hashRate &&
      powerConsumption &&
      powerCost &&
      poolFees &&
      exchangeRate &&
      costPerTeraHash
    ) {
      this.setState({
        buttonLoading: true,
      });
      const calculatedHashRate = hashrateConverter(
        hashRateType,
        "",
        hashRate,
        "server"
      );
      const calculatePowerConsumption = powerConsumptionConverter(
        powerConsumptionType,
        "",
        powerConsumption,
        "server"
      );
      const response = await api.create("user/mining/calculate", {
        hashRate: calculatedHashRate,
        powerConsumption: calculatePowerConsumption,
        powerCost,
        blockReward,
        difficultyRate,
        exchangeRate,
        poolFees,
        budget,
        costPerTeraHash,
        transactionFeeRate,
      });
      if (response.code === 200) {
        this.setState(
          {
            buttonLoading: false,
            calculationData: response.data,
            electricityCostUsd: response.data[1]?.electricityCostUsd,
          },
          () => {
            if (this.props.onCalculate) {
              this.props.onCalculate({
                hashRateType,
                hashRate,
                powerConsumptionType,
                powerConsumption,
                powerCost,
                blockReward,
                difficultyRate,
                exchangeRate,
                poolFees,
                budget,
                costPerTeraHash,
                transactionFeeRate,
                monthlyElectricityCost: response.data[1]?.electricityCostUsd,
              });
            }
          }
        );
      }
    } else {
      let errorMsg = "Please enter valid details for required fields.";
      if (!budget) {
        errorMsg = "Please enter valid budget.";
      } else if (!costPerTeraHash) {
        errorMsg = "Please enter valid cost per terahash.";
      } else if (!hashRate) {
        errorMsg = "Please enter valid hash rate.";
      } else if (!powerConsumption) {
        errorMsg = "Please enter valid power consumption.";
      } else if (!powerCost) {
        errorMsg = "Please enter valid power cost.";
      } else if (!poolFees) {
        errorMsg = "Please enter valid pool fees.";
      } else if (!blockReward) {
        errorMsg = "Please enter valid block reward.";
      } else if (!exchangeRate) {
        errorMsg = "Please enter valid exchange rate.";
      }
      toast.error(errorMsg);
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedProduct: nextProps.selectedProduct,
      quantity: nextProps.quantity,
      readOnly: !!nextProps.readOnly,
    });
  }

  componentDidMount() {
    document.title =
      messageConstants.MINING_CALCULATOR_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.defaultMining();
    this.getStats();
  }

  render() {
    let {
      blockReward,
      difficultyRate,
      exchangeRate,
      poolFees,
      powerCost,
      quantity,
      selectedProduct,
      readOnly,
      calculationData,
      costPerTeraHash,
      transactionFeeRate,
      budget,
      powerConsumption,
      hashRate,
      totalAsics,
    } = this.state;

    if (selectedProduct) {
      budget = quantity * Number(selectedProduct.priceInUsd);
      powerConsumption = Number(selectedProduct.powerConsumption);
      hashRate = Number(selectedProduct.hashrate);
      totalAsics = quantity;
    }

    return (
      <CalculatorComponent
        blockReward={blockReward}
        difficultyRate={difficultyRate}
        exchangeRate={exchangeRate}
        poolFees={poolFees}
        powerCost={powerCost}
        costPerTeraHash={costPerTeraHash}
        transactionFeeRate={transactionFeeRate}
        budget={budget}
        totalAsics={totalAsics}
        powerConsumption={powerConsumption}
        hashRate={hashRate}
        readOnly={readOnly}
        calculationData={calculationData}
        onCalculate={(data) => {
          console.log(3333, data);
          this.setState({ ...data }, () => {
            this.calculate();
          });
        }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.token,
});

export default connect(mapStateToProps)(Calculator);
