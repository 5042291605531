export const TOGGLE_DARK_MODE = "TOGGLE_DARK_MODE";

export const STORE_USER_INFO = "STORE_USER_INFO";
export const STORE_AUTHENTICATION_TOKEN = "STORE_AUTHENTICATION_TOKEN";
export const CLEAR_USER = "CLEAR_USER";
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const STORE_USER_DETAILS = "STORE_USER_DETAILS";

export const STORE_COUNTRIES = "STORE_COUNTRIES";

export const STORE_WORKER_STATISTIC = "STORE_WORKER_STATISTIC ";
