import React from "react";
const Uniswaptooltip = () => {
  return (
    <div className="wireTooltip">
      <span>Uniswap</span>
    </div>
  );
};

export default Uniswaptooltip;
