import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActions,
  Input,
  Grid,
  Divider,
  Checkbox,
  Box,
} from "@material-ui/core";

import { niceNumberDecimalDisplay } from "../../utils/Util";

const useStyles = (theme) => ({
  card: {
    width: "100%",
    boxShadow: "0px 0px 16px rgb(0 0 0 / 22%)",
  },
  media: {
    height: 200,
    width: "100%",
  },
  header: {
    fontWeight: "600 !important",
    fontSize: "1.2rem",
    marginTop: "20px",
    marginBottom: "20px",
  },
  item: {
    fontWeight: "600 !important",
  },
  divide: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  price: {
    fontWeight: "600",
    fontSize: "1rem",
    color: "#111",
  },
  imageContainer: {
    position: "relative",
    textAlign: "center",
  },
  imageText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "rgba(255, 0, 0, 0.5)",
    fontSize: "3.5rem",
    fontWeight: "600",
  },
});

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
    };
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  handleCheckBox = (event) => {
    if (event.target.checked) {
      this.props.onSelected(event.target.name);
    } else {
      this.props.onSelected(null);
    }
  };

  render() {
    const { classes, products, selectedProduct } = this.props;
    const description = [
      {
        key: "algorithm",
        label: "Algorithm",
        format: "",
      },
      {
        key: "hashrate",
        label: "Hashrate",
        format: "T",
      },
      {
        key: "powerConsumption",
        label: "Power (W):",
        format: "W",
      },
      {
        key: "powerWth",
        label: "Efficiency (W/T)",
        format: "",
      },
      {
        key: "costPerTerahash",
        label: "Cost ($/T)",
        format: "/T",
        dollarSign: true,
      },
    ];

    return (
      <Grid container spacing={3}>
        {products.map((product, index) => {
          let shouldDisable = false;
          if (selectedProduct && selectedProduct !== product._id) {
            shouldDisable = true;
          }
          if (product.quantity === 0) {
            shouldDisable = true;
          }

          return (
            <Grid container item xs={12} md={3} key={product._id}>
              <Card key={product._id} className={classes.card}>
                <div className={classes.imageContainer}>
                  <img
                    className={classes.media}
                    src={`${product.imageUrl}`}
                    title={product.model}
                  />
                  {product?.model?.includes("SOLD OUT") && (
                    <div className={classes.imageText}>SOLD OUT</div>
                  )}
                </div>

                <Typography
                  variant="body1"
                  align="center"
                  className={classes.header}
                >
                  {product.model}
                </Typography>

                <Divider className={classes.divide} />

                <CardContent>
                  {description.map((desc) => {
                    return (
                      <Typography
                        variant="body1"
                        component="p"
                        key={desc}
                        className={classes.item}
                      >
                        {desc.label}: {desc.dollarSign && "$"}
                        {product[desc.key]}
                        {desc.format}
                      </Typography>
                    );
                  })}
                  <Divider className={classes.divide} />

                  <p className={classes.price}>
                    Price: ${niceNumberDecimalDisplay(product.priceInUsd, 2)}
                  </p>
                </CardContent>
                <CardActions disableSpacing>
                  <Checkbox
                    onClick={this.handleCheckBox}
                    name={product._id}
                    checked={
                      selectedProduct ? selectedProduct === product._id : false
                    }
                    disabled={shouldDisable}
                  />

                  {selectedProduct === product._id && (
                    <>
                      <span>Qty: </span>
                      <span>
                        {" "}
                        <Input
                          placeholder="How many you want?"
                          type="number"
                          InputProps={{
                            inputProps: { min: "1", max: "10", step: "1" },
                          }}
                          onChange={(e) =>
                            this.props.onQuantity(e.target.value)
                          }
                          value={this.props.quantity || 1}
                        />
                      </span>
                    </>
                  )}
                </CardActions>
              </Card>
            </Grid>
          );
        })}{" "}
      </Grid>
    );
  }
}

export default withStyles(useStyles)(Product);
