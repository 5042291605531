import React from "react";

const EarningsTooltip = () => {
  return (
    <div className="earningTooltip">
      <span>
        Notional Earnings means the hypothetical income, gain and/or loss deemed
        earned by receiving bitcoin as mining rewards. Notional Earnings are
        calculated from daily Gross Earnings (bitcoin rewards deposited in your
        bitcoin mining wallet). You will recognize a difference in the value of
        your notional Net Earnings as compared to the balance of your Bitcoin
        mining wallet depending on the method used to pay your invoices and the
        billing cycle which occurs monthly and not daily as recorded on this
        page.
        <br />
        The USD illustration is based on the BTC/USD price on each day the
        notional earnings are recorded (see below).
      </span>
    </div>
  );
};

export default EarningsTooltip;
