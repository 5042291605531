import {
  STORE_USER_INFO,
  STORE_AUTHENTICATION_TOKEN,
  CLEAR_USER,
  STORE_USER_DETAILS,
} from "./types";
import Api from "../../services/api";
import * as userApi from "../../api/userApi";

export const storeUserInfo = (user) => {
  return {
    type: STORE_USER_INFO,
    payload: user,
  };
};

export const storeToken = (token) => {
  return {
    type: STORE_AUTHENTICATION_TOKEN,
    payload: token,
  };
};

export const clearUser = () => {
  return {
    type: CLEAR_USER,
  };
};

export const fetchUserDetails = (success, error) => {
  return async (dispatch, state) => {
    const api = new Api();
    const { token } = state().user;
    try {
      if (token) {
        const response = await api
          .setToken(token)
          .create(userApi.GET_USER_DETAILS);
        if (response.code === 200) {
          const userDetails = response.data;
          dispatch(storeUserDetails(userDetails));
          if (success && typeof success == "function") {
            success(userDetails);
          }
        }
      }
    } catch (err) {
      if (error && typeof error == "function") {
        error(err);
      }
    }
  };
};

export const storeUserDetails = (userDetails) => {
  return {
    type: STORE_USER_DETAILS,
    payload: userDetails,
  };
};
