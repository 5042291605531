export const SIGNUP_PAGE_TITLE = "Signup";
export const SIGNIN_PAGE_TITLE = "Signin";
export const FORGOT_PAGE_TITLE = "Forgot Password";
export const RESET_PASSWORD_PAGE_TITLE = "Reset Password";
export const KYC_BASIC_DETAILS_PAGE_TITLE = "Identity & Contact Details";
export const KYC_UPLOAD_FILES_PAGE_TITLE = "Upload KYC files";
export const KYC_SUBMIT_PAGE_TITLE = "Submit KYC";
export const KYC_STATUS_PAGE_TITLE = "KYC Status";
export const DASHBOARD_PAGE_TITLE = "Dashboard";
export const RESOURCE_RECORD_NOT_FOUND = "There are no resources to display.";
export const OPERATIONS_PAGE_TITLE = "Operations";
export const POOL_STATISTICS_PAGE_TITLE = "Pool Statistics";
export const POWER_SITES_PAGE_TITLE = "Power Sites";
export const MINING_CALCULATOR_PAGE_TITLE = "Mining Calculator";
export const MY_ACCOUNT_PAGE_TITLE = "My Account";
export const TERMS_CONDITIONS_PAGE_TITLE = "Terms & Conditions";
export const RESOURCES_PAGE_TITLE = "Resources";
export const MY_ADDRESSES_PAGE_TITLE = "My Addresses";
export const PAGE_TITLE_SEPERATOR = " - ";
export const PERMIAN_LABEL = "PermianChain";
export const LABEL_SELECT_COUNTRY = "Select country";
export const SOMETHING_WENT_WRONG =
  "Something went wrong. Please try again later.";
export const REQUIRED_FIELDS_VALID_MSG =
  "Please enter valid details for required fields.";
export const PLEASE_ACCEPT_DECLARATION = "Please accept all declaration.";
export const KYC_DOCUMENTS_VERIFIED_SUCCESS =
  "Your KYC documents verified successfully.";
export const KYC_DOCUMENTS_REJECTED =
  "Your KYC documents has been rejected. Please submit it again.";
export const KYC_DOCUMENTS_UNDER_REVIEW =
  "Your KYC details are under review. We will notify you once it is completed.";
export const ERROR_MSG_VALID_FILES = "Please upload valid files.";
export const ERROR_MSG_REQUIRED_FILES = "Please upload all required files.";
export const ERROR_MSG_VALID_RECEIVING_ETH_ADDRESS =
  "Please enter valid receiving ETH address.";
export const ERROR_MSG_VALID_TWO_FA_CODE =
  "Please enter valid two factor code.";
export const VALID_TWO_FACTOR_CODE_MSG = "Please enter valid two factor code.";
export const VALID_PASSWORD_MSG = "Please enter valid password.";
export const REQUIRED_FIELD_ERROR_MSG = "Please fill required fields.";
export const UPLOAD_ALL_FILES_ERROR_MSG = "Please upload required files.";
export const ERROR_MSG_VALID_PROGRESS_REPORT_TITLE =
  "Please enter valid report title.";
export const ERROR_MSG_VALID_PROGRESS_FILE =
  "Please enter valid progress file.";
export const LISTINGS_PAGE_TITLE = "Listings";
export const LISTING_DETAIL_PAGE_TITLE = "Listing Details";
export const LISTING_PROGRESS_REPORTS_PAGE_TITLE = "Listing Progress Reports";
export const PROGRESS_REPORTS_RECORD_NOT_FOUND = "No progress reports found.";
export const NO_RECORDS_FOUND = "No records found.";
export const WORKERS_PAGE_TITLE = "Workers";
export const EARNINGS_PAGE_TITLE = "Earnings";
export const WORKER_INVENTORY_PAGE_TITLE = "Worker Inventory";
export const USER_JOURNEY_PAGE_TITLE = "User Journey";
