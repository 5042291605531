import "icheck/skins/all.css";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import "react-datetime/css/react-datetime.css";
import React from "react";
import _ from "lodash";
import { Checkbox, Radio, RadioGroup } from "react-icheck";
import { toast } from "react-toastify";
import Select from "react-select";
import IntlTelInput from "react-intl-tel-input";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import validators from "../../validators";
import "react-intl-tel-input/dist/main.css";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import { Link } from "react-router-dom";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";

let api;

class Kyc extends React.Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      fullName:
        !_.isUndefined(this.props.authUserInfo) &&
        this.props.authUserInfo !== null
          ? this.props.authUserInfo.fullName
          : "",
      gender: "m",
      countryId: "",
      countryIdValue: null,
      countryName: "Select country",
      state: "",
      city: "",
      postalCode: "",
      addressLine1: "",
      addressLine2: "",
      email:
        !_.isUndefined(this.props.authUserInfo) &&
        this.props.authUserInfo !== null
          ? this.props.authUserInfo.email
          : "",
      contactNo: "",
      insiderOrReportingIssuer: "",
      error: null,
      buttonLoading: false,
      apiError: "",
      recaptchaResponse: "",
      agreeCheck: false,
    };
    this.optionRef = React.createRef();
    this.validators = validators;
    this.onchange = this.onchange.bind(this);
    this.displayValidationErrors = this.displayValidationErrors.bind(this);
    this.updateValidators = this.updateValidators.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleDobChange = this.handleDobChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.saveKycBasicDetails = this.saveKycBasicDetails.bind(this);
    this.setConteactNo = this.setConteactNo.bind(this);
    this.handleAgreeChange = this.handleAgreeChange.bind(this);
    this.onContactChange = this.onContactChange.bind(this);
    this.onMenuOpen = this.onMenuOpen.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  onMenuOpen = () => {
    const { countries } = this.props;
    const { countryIdValue } = this.state;
    setTimeout(() => {
      this.optionRef.current.select.scrollToFocusedOptionOnUpdate = true;
      this.optionRef.current.select.setState({
        focusedOption: countries.find(
          (option) => option._id === countryIdValue
        ),
      });
    }, 0);
  };

  async handleRadioChange(e) {
    this.setState({
      insiderOrReportingIssuer: e.target.value,
    });
  }
  setConteactNo(value) {
    this.setState({
      contactNo: value,
    });
  }

  onContactChange(event) {
    this.setState({
      contactNo: event.target.value,
    });
  }
  async componentWillMount() {
    document.title =
      messageConstants.KYC_BASIC_DETAILS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("display");
    try {
      const userResponse = await api.create("user/getUserDetails");
      if (userResponse.code === 200) {
        if (
          userResponse.data.kycStatus === "u" ||
          (userResponse.data.kycStatus === "a" &&
            userResponse.data.isKycVerified === true)
        ) {
          let redirectUrl =
            userResponse.data.kycStatus === "u"
              ? "kyc_status/pending"
              : "kyc_status/approved";
          this.props.history.push(redirectUrl);
        } else {
          if (userResponse.data.userKycDetail) {
            this.setState({
              addressLine1: userResponse.data.userKycDetail.addressLine1,
              addressLine2: userResponse.data.userKycDetail.addressLine2,
              contactNo: userResponse.data.userKycDetail.contactNo,
              postalCode: userResponse.data.userKycDetail.postalCode,
              insiderOrReportingIssuer:
                userResponse.data.userKycDetail.insiderOrReportingIssuer,
              city: userResponse.data.userKycDetail.city,
              state: userResponse.data.userKycDetail.state,
              gender: userResponse.data.userKycDetail.gender,
              countryId: userResponse.data.userKycDetail.countryId._id,
              countryIdValue: userResponse.data.userKycDetail.countryId._id,
              countryName: userResponse.data.userKycDetail.countryId.name,
              agreeCheck: true,
            });
          }
        }
      } else {
        toast.error(userResponse.message);
      }
      pageProgress("remove");
    } catch (error) {
      pageProgress("remove");
    }
  }

  handleGenderChange(event, value) {
    this.setState({
      gender: value,
    });
  }

  handleAgreeChange(e, checked) {
    this.setState({
      agreeCheck: checked,
    });
  }

  handleDobChange(date) {
    this.setState({
      dob: date,
    });
  }

  handleCountryChange(selectedOption) {
    this.setState({
      countryIdValue: selectedOption.value,
      countryName: selectedOption.label,
      countryId: selectedOption.value,
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  updateValidators(fieldName, value) {
    if (!this.validators[fieldName]) {
      this.validators[fieldName] = {};
    }
    this.validators[fieldName].errors = [];
    this.validators[fieldName].state = value;
    this.validators[fieldName].valid = true;
    this.validators[fieldName].rules.forEach((rule) => {
      if (rule.test instanceof RegExp) {
        if (!rule.test.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      } else if (typeof rule.test === "function") {
        if (!rule.test(value)) {
          this.validators[fieldName].errors.push(rule.message);
          this.validators[fieldName].valid = false;
        }
      }
    });
  }

  isFormValid() {
    let status = true;
    const validationFields = ["fullName", "contactNo", "email"];
    validationFields.forEach((field) => {
      this.updateValidators(field, this.state[field]);
      if (!this.validators[field].valid) {
        status = false;
      }
    });
    return status;
  }

  displayValidationErrors(fieldName) {
    const validator = this.validators[fieldName];
    const result = "";
    if (validator && !validator.valid) {
      const errors = validator.errors.map((info, index) => {
        return (
          <span className="error" key={index}>
            * {info}
            <br />
          </span>
        );
      });
      return (
        <div className="row text-left mt-1">
          <div className="col">
            <div className="s12 ">{errors}</div>
          </div>
        </div>
      );
    }
    return result;
  }

  async saveKycBasicDetails(event) {
    event.preventDefault();
    const {
      fullName,
      countryId,
      state,
      city,
      postalCode,
      contactNo,
      insiderOrReportingIssuer,
      addressLine1,
      addressLine2,
      email,
      agreeCheck,
    } = this.state;

    let newName = fullName
      ? fullName.length > 0
        ? fullName
        : undefined
      : undefined;
    let newCountryId = countryId
      ? countryId.length > 0
        ? countryId
        : undefined
      : undefined;
    let newPostalCode = postalCode
      ? postalCode.length > 0
        ? postalCode
        : undefined
      : undefined;
    let newContactNo = contactNo
      ? contactNo.length > 0
        ? contactNo
        : undefined
      : undefined;
    let newState = state ? (state.length > 0 ? state : undefined) : undefined;
    let newInsiderOrReportingIssuer = insiderOrReportingIssuer
      ? insiderOrReportingIssuer.length > 0
        ? insiderOrReportingIssuer
        : undefined
      : undefined;
    let newCity = city ? (city.length > 0 ? city : undefined) : undefined;
    let newAddressLine1 = addressLine1
      ? addressLine1.length > 0
        ? addressLine1
        : undefined
      : undefined;
    let newAddressLine2 = addressLine2
      ? addressLine2.length > 0
        ? addressLine2
        : undefined
      : undefined;
    let newEmail = email ? (email.length > 0 ? email : undefined) : undefined;

    const isFormValid = this.isFormValid();
    if (
      !_.isUndefined(agreeCheck) &&
      agreeCheck === true &&
      isFormValid &&
      fullName !== "" &&
      contactNo !== "" &&
      countryId !== "" &&
      city !== "" &&
      state !== "" &&
      email !== ""
    ) {
      this.setState({
        buttonLoading: true,
      });
      const response = await api.create("user/saveMinerKycBasicDetails", {
        fullName: newName,
        countryId: newCountryId,
        state: newState,
        insiderOrReportingIssuer: newInsiderOrReportingIssuer,
        addressLine1: newAddressLine1,
        addressLine2: newAddressLine2,
        city: newCity,
        postalCode: newPostalCode,
        contactNo: newContactNo,
        email: newEmail,
      });

      if (response) {
        this.setState({
          buttonLoading: false,
        });
        if (response.code === 200) {
          this.props.history.push("/kyc_upload");
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      }
    } else {
      if (!isFormValid) {
        toast.error("Please enter valid details for required fields.");
      } else {
        toast.error("Please accept terms & conditions to proceed.");
      }
    }
  }

  render() {
    let {
      fullName,
      contactNo,
      email,
      postalCode,
      city,
      state,
      buttonLoading,
      agreeCheck,
      countryName,
      countryIdValue,
      termsOfEngagementDataAdded,
      socialSecurityNumber,
      insiderOrReportingIssuer,
      addressLine1,
      addressLine2,
      role,
      natureOfBusiness,
      hidePassword,
    } = this.state;
    let buttonDisabled = buttonLoading === true ? true : false;
    let { countries: countryList } = this.props;
    countryList = [
      { value: "", label: "Select Country" },
      ...countryList.map((country) => ({
        value: country._id,
        label: country.name,
      })),
    ];
    return (
      <div className="kyc-container">
        <h3 className="text-center kyc-header standardColorIcon">
          {" "}
          Submit KYC{" "}
        </h3>{" "}
        <div className="row">
          <div className="col-md-12">
            <div className="row mt-3 progress-steps-container">
              <div className="col-md-2"> </div>{" "}
              <div className="col-md-8">
                <ul className="progressbar d-flex p-0 justify-content-center">
                  <li className="standardColorIcon active"> Basic Details </li>
                  <li className="standardColorIcon"> Upload </li>{" "}
                  <li className="standardColorIcon"> Submit </li>{" "}
                  {termsOfEngagementDataAdded && <li> Terms Of Engagement </li>}{" "}
                </ul>{" "}
              </div>{" "}
              <div className="col-md-2"> </div>{" "}
            </div>{" "}
            <div className="row row-eq-height card-horizontal-padding">
              <div className="col-lg-6 col-md-12">
                <div className="element-wrapper custom-element-box h-100 card-inner-padding">
                  <form>
                    <h5 className="form-header"> Identity Details </h5>{" "}
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-default-color">
                            Full Name <span className="required-sign"> * </span>{" "}
                          </label>{" "}
                          <input
                            className="form-control"
                            type="text"
                            name="fullName"
                            id="fullName"
                            value={fullName}
                            onChange={this.onchange}
                          />{" "}
                          {fullName && this.displayValidationErrors("fullName")}{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="text-default-color">
                            {" "}
                            Email Address
                            <span className="required-sign"> * </span>
                          </label>{" "}
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            id="email"
                            value={email}
                            onChange={this.onchange}
                          />{" "}
                          {email && this.displayValidationErrors("email")}{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label className="text-default-color">
                            Confirm whether you are an insider of a reporting
                            issuer or any other issuer whose securities are
                            publicly traded:
                          </label>{" "}
                          <div className="insiderRadio col-md-12 col-sm-12 mt-2">
                            <RadioGroup
                              className="kyc-button-group"
                              name="insiderOrReportingIssuer"
                              value={insiderOrReportingIssuer}
                              // style={{marginLeft:'-10px'}}
                              onChange={(e) =>
                                this.handleRadioChange(
                                  e,
                                  "insiderOrReportingIssuer"
                                )
                              }
                              id="insiderOrReportingIssuer"
                            >
                              <Radio
                                value="Yes"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "Yes"}
                              />
                              <Radio
                                value="No"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "No"}
                                style={{ marginLeft: "20px" }}
                              />
                              <Radio
                                value="Not Applicable"
                                radioClass="iradio_square-blue text-default"
                                increaseArea="20%"
                                label={" " + "Not Applicable"}
                                style={{ marginLeft: "20px" }}
                              />
                            </RadioGroup>
                          </div>
                        </div>{" "}
                      </div>{" "}
                    </div>
                    {role === "ci" && (
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label> Nature of Business </label>{" "}
                            <input
                              className="form-control"
                              type="text"
                              name="natureOfBusiness"
                              id="natureOfBusiness"
                              onChange={this.onchange}
                              value={natureOfBusiness}
                            />{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>
                    )}{" "}
                  </form>{" "}
                </div>{" "}
              </div>{" "}
              <div className="col-lg-6 col-md-12 mgtp-md-4 mgtp-sm-4">
                <div className="element-wrapper custom-element-box h-100 card-inner-padding">
                  <h5 className="form-header standardColorIcon">
                    {" "}
                    Contact Information{" "}
                  </h5>{" "}
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          Country <span className="required-sign"> * </span>{" "}
                        </label>{" "}
                        <Select
                          autoFocus
                          className="country-select2"
                          classNamePrefix="cntry"
                          options={countryList}
                          placeholder="Select Country"
                          name="countryId"
                          id="country"
                          ref={this.optionRef}
                          onChange={this.handleCountryChange}
                          value={{
                            label: countryName,
                            value: countryIdValue,
                          }}
                          onMenuOpen={this.onMenuOpen}
                        />{" "}
                      </div>{" "}
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          {" "}
                          State / Province{" "}
                          <span className="required-sign"> * </span>
                        </label>{" "}
                        <input
                          className="form-control"
                          type="text"
                          name="state"
                          id="state"
                          onChange={this.onchange}
                          value={state}
                        />{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          {" "}
                          City
                          <span className="required-sign"> * </span>
                        </label>{" "}
                        <input
                          className="form-control"
                          type="text"
                          name="city"
                          id="city"
                          onChange={this.onchange}
                          value={city}
                        />{" "}
                      </div>{" "}
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          {" "}
                          Postal Code / Zip Code{" "}
                        </label>{" "}
                        <input
                          className="form-control"
                          type="text"
                          name="postalCode"
                          id="postalCode"
                          onChange={this.onchange}
                          value={postalCode}
                        />{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          Address Line 1{" "}
                        </label>{" "}
                        <input
                          className="form-control"
                          type="text"
                          id="addressLine1"
                          name="addressLine1"
                          onChange={this.onchange}
                          placeholder="Address Line 1"
                          value={addressLine1}
                        />{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          Address Line 2{" "}
                        </label>{" "}
                        <input
                          className="form-control"
                          type="text"
                          id="addressLine2"
                          name="addressLine2"
                          onChange={this.onchange}
                          placeholder="Address Line 2"
                          value={addressLine2}
                        />{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="text-default-color">
                          Contact Number{" "}
                          <span className="required-sign"> * </span>{" "}
                        </label>{" "}
                        <br />
                        <IntlTelInput
                          preferredCountries={["tw"]}
                          onPhoneNumberChange={(validate, value, countryData) =>
                            this.setConteactNo(value)
                          }
                          numberType="MOBILE"
                          containerClassName="intl-tel-input"
                          inputClassName="form-control"
                          // name="contactNo"
                          value={contactNo}
                          // onChange={this.onContactChange}
                          style={{ width: "100%" }}
                        />{" "}
                        {contactNo && this.displayValidationErrors("contactNo")}{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                  {_.toLower(countryName) === "canada" && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group dob-container">
                          <label className="text-default-color">
                            Social Insurance / Security Number{" "}
                            <span className="required-sign"> * </span>&nbsp;{" "}
                            <i
                              className="fa fa-info-circle"
                              id="socialSecurityNumberInformation"
                            ></i>{" "}
                            <UncontrolledPopover
                              trigger="hover"
                              placement="bottom"
                              target="socialSecurityNumberInformation"
                            >
                              <PopoverBody>
                                Your Social Insurance / Security Number is
                                encrypted and can only be seen by your Exempt
                                market Dealer{" "}
                              </PopoverBody>{" "}
                            </UncontrolledPopover>{" "}
                          </label>{" "}
                          <input
                            className="form-control"
                            type={hidePassword}
                            name="socialSecurityNumber"
                            id="socialSecurityNumber"
                            onChange={this.onchange}
                            value={socialSecurityNumber}
                          />{" "}
                          <span
                            style={{
                              position: "absolute",
                              top: 40,
                              right: 26,
                            }}
                            onClick={() => this.displaySecurityNumberToggle()}
                          >
                            <i
                              className={`fa cursor-pointer ${
                                hidePassword === "text"
                                  ? "fa-eye-slash"
                                  : "fa-eye"
                              }`}
                            ></i>{" "}
                          </span>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>
                  )}{" "}
                  <div className="form-check mt-1 kyc-agree-container">
                    <Checkbox
                      id="agreeCheck"
                      checkboxClass="icheckbox_square-blue"
                      increaseArea="20%"
                      name="agreeCheck"
                      onChange={this.handleAgreeChange}
                      checked={agreeCheck}
                    />{" "}
                    <label
                      htmlFor="agreeCheck"
                      className="ml-2 text-default-color m-0"
                    >
                      I agree to{" "}
                      <Link
                        to="/terms_conditions"
                        className="text-underline"
                        target="_blank"
                      >
                        Terms & Conditions{" "}
                      </Link>{" "}
                    </label>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <div className="row card-inner-padding">
              <div className="col-md-12 text-right d-flex justify-content-end">
                <Link to="/dashboard">
                  <span className="btn cancel-button mr-2">Do it later</span>
                </Link>{" "}
                <button
                  className="btn update-button"
                  type="submit"
                  onClick={this.saveKycBasicDetails}
                  disabled={buttonDisabled}
                >
                  {" "}
                  Next{" "}
                  {buttonLoading && (
                    <i className="fa-spin fa fa-spinner text-white ml-1" />
                  )}{" "}
                </button>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  countries: state.country.countries,
  authUserInfo: state.user.userInfo,
});

export default connect(mapStateToProps)(Kyc);
