import React from "react";
import CreditCardInput from "react-credit-card-input";
import Select from "react-select";

const NewCard = ({
  optionRef,
  countriesList,
  handleCountryChange,
  setEmail,
  setCity,
  setNewExpiry,
  setCvc,
  setLine1,
  email,
  city,
  line1,
  country,
  countryIdValue,
  setCardNumber,
  state,
  postal_code,
  setState,
  setPostalCode,
  newexpiry,
  submitLoading,
  cvc,
  cardNumber,
  handleChangeCard,
}) => {
  return (
    <>
      <input
        placeholder="Email"
        className="mui-field mt-3"
        id="outlined-email-input"
        margin="normal"
        variant="outlined"
        type="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
      />
      <input
        placeholder="Address"
        className="mui-field mt-3"
        margin="normal"
        variant="outlined"
        type="text"
        required
        value={line1}
        onChange={(e) => setLine1(e.target.value)}
        fullWidth
      />
      <Select
        autoFocus={false}
        className="country-select4 w-100 mt-3"
        classNamePrefix="contry"
        options={countriesList}
        placeholder="Select Country"
        name="countryId"
        id="country"
        ref={optionRef}
        onChange={handleCountryChange}
        value={{
          label: country,
          value: countryIdValue,
        }}
      />
      <input
        placeholder="City"
        className="mui-field mt-3"
        margin="normal"
        variant="outlined"
        type="text"
        required
        value={city}
        onChange={(e) => setCity(e.target.value)}
        fullWidth
      />
      <input
        placeholder="State"
        className="mui-field mt-3"
        margin="normal"
        variant="outlined"
        type="text"
        required
        value={state}
        onChange={(e) => setState(e.target.value)}
        fullWidth
      />
      <input
        placeholder="Postal Code"
        className="mui-field mt-3"
        margin="normal"
        variant="outlined"
        type="text"
        required
        value={postal_code}
        onChange={(e) => {
          setPostalCode(e.target.value);
        }}
        fullWidth
      />
      <div>
        <CreditCardInput
          containerClassName="changeCardContainer mt-3"
          cardNumberInputProps={{
            value: cardNumber,
            onError: (err) => {
              setCardNumber("");
            },
            onChange: (e) => {
              setCardNumber(e.target.value);
            },
          }}
          cardExpiryInputProps={{
            value: newexpiry,
            onError: (err) => {
              setNewExpiry("");
            },
            onChange: (e) => setNewExpiry(e.target.value),
          }}
          cardCVCInputProps={{
            value: cvc,
            onError: (err) => {
              setCvc("");
            },
            onChange: (e) => setCvc(e.target.value),
          }}
          fieldClassName="input"
        />
      </div>
      <div className="row align-items-start justify-content-center">
        <button
          className="btn btn-primary mt-2 mr-2 "
          onClick={() => {
            handleChangeCard();
          }}
        >
          Submit
          {submitLoading && (
            <i className="fa-spin fa fa-spinner text-white ml-2" />
          )}
        </button>
      </div>
    </>
  );
};

export default NewCard;
