import React, { Component } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from "react-bootstrap-table2-overlay";
import "react-confirm-alert/src/react-confirm-alert.css";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import BudgetTooltip from "./BudgetTooltip";
import Api from "../../services/api";
import { toast } from "react-toastify";
import Select from "react-select";
import ExportBudgetModal from "./ExportBudgetModal";
import * as messageConstants from "../../utils/Messages";
import ViewBudgetModal from "./ViewBudgetModal";
import DepositTooltip from "./DepositTooltip";
import {
  niceNumberDecimalDisplay,
  niceNumberNoDecimalDisplay,
} from "../../utils/Util";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";
import { fetchUserDetails } from "../../store/actions/UserAction";

let api;

class ViewBudget extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      isHavingBudget: "",
      sizePerPage: 10,
      page: 1,
      totalSize: "",
      renderFlag: false,
      adminNote: "",
      userNote: "",
      status: "",
      docLink: "",
      modalOpen: false,
      modeList: [
        {
          value: "stripe",
          label: "Stripe",
        },
        {
          value: "wireTransfer",
          label: "Wire Transfer",
        },
        {
          value: "BTC",
          label: "BTC",
        },
      ],
      statusList: [
        {
          value: "p",
          label: "Pending",
        },
        {
          value: "a",
          label: "Paid",
        },
        {
          value: "r",
          label: "Rejected",
        },
        {
          value: "c",
          label: "Cancelled",
        },
      ],
      filterByEndDate: "",
      filterByStartDate: "",
      filterByMode: "",
      filterByStatusLabel: "Select Status",
      filterByStatus: "",
      viewBudgetModalOpen: false,
      tableLoading: true,
      recordData: [],
      allData: [],
    };
    this.getRecords = this.getRecords.bind(this);
    this.clearFilterRecords = this.clearFilterRecords.bind(this);
    this.filterRecords = this.filterRecords.bind(this);
    this.viewBudgetModalClose = this.viewBudgetModalClose.bind(this);
    this.viewBudgetModalShow = this.viewBudgetModalShow.bind(this);
    this.getDocument = this.getDocument.bind(this);
    this.handleChangeMode = this.handleChangeMode.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.onchange = this.onchange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.allData = this.allData.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.showModal = this.showModal.bind(this);
  }
  onCloseModal() {
    this.setState({ modalOpen: false });
  }
  showModal() {
    this.setState({ modalOpen: true });
  }

  allData() {
    const { totalSize } = this.state;
    api
      .get("miner/viewBudgetTransactions", {
        sizePerPage: totalSize,
        page: 1,
      })
      .then((data) => {
        let usdDataArr = [];
        data.data.budgetedMiner.forEach((data) => {
          let dataObj = {};
          dataObj.date = data.createdOnUTC
            ? moment(data.createdOnUTC).format("YYYY/MM/DD")
            : "";
          // dataObj.date = data.createdOnUTC?moment(data.createdOnUTC).format("DD/MM/YYYY"):"";
          dataObj.email = data.email;
          dataObj.account = data.account;
          dataObj.quantity = data.qty;
          dataObj.deposit =
            data.paymentStatus === "r" || data.paymentStatus === "c"
              ? -niceNumberNoDecimalDisplay(data.amountSubmitted, 2)
              : niceNumberNoDecimalDisplay(data.amountSubmitted, 2);
          dataObj.widthrawal =
            data.paymentStatus === "r" || data.paymentStatus === "c"
              ? -niceNumberNoDecimalDisplay(data.amountWithdrawal, 2)
              : niceNumberNoDecimalDisplay(data.amountWithdrawal, 2);
          dataObj.balance = niceNumberDecimalDisplay(data.balance, 2);
          dataObj.mode = data.mode;
          dataObj.status = data.paymentStatus
            ? data.paymentStatus === "p"
              ? "PENDING"
              : data.paymentStatus === "r"
              ? "REJECTED"
              : data.paymentStatus === "a"
              ? "PAID"
              : data.paymentStatus === "c"
              ? "CANCELLED"
              : ""
            : "";
          usdDataArr.push(dataObj);
        });
        this.setState({
          allData: usdDataArr,
        });
      });
  }

  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    //const currentIndex = (page - 1) * sizePerPage;
    if (this.state.sizePerPage !== sizePerPage || this.state.page !== page) {
      this.setState({ sizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    }
  };

  filterRecords() {
    this.getRecords();
  }
  clearFilterRecords() {
    this.setState(
      {
        filterByStatus: "",
        filterByStatusLabel: "",
        filterByMode: "",
        filterByStartDate: "",
        filterByEndDate: "",
      },
      async () => {
        this.getRecords();
      }
    );
  }
  handleChangeMode(e) {
    this.setState({ filterByMode: e.value });
  }
  handleChangeStatus(e) {
    this.setState({
      filterByStatus: e.value,
      filterByStatusLabel: e.label,
    });
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  async getDocument(paymentFileKey, userId) {
    const response = await api.create("miner/getMinerBudgetWirePaymentDoc", {
      paymentFileKey: paymentFileKey,
      minerId: userId,
    });
    if (response.code === 200) {
      let object;
      if (response.data && response.data.minerPaymentDetail) {
        object = response.data.minerPaymentDetail.paymentDocument;
        this.setState({
          docLink: object[Object.keys(object)[0]],
        });
      }
    }
  }

  viewBudgetModalClose() {
    this.setState({
      status: "",
      adminNote: "",
      userNote: "",
      viewBudgetModalOpen: false,
    });
  }
  viewBudgetModalShow(adminNote, userNote, userId, paymentFileKey, status) {
    if (status === "r") {
      this.setState({
        status: status,
      });
    }
    this.setState({
      adminNote: adminNote,
      userNote: userNote,
      viewBudgetModalOpen: true,
    });
    this.getDocument(paymentFileKey, userId);
  }

  componentDidMount() {
    document.title =
      "View Budget" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("remove");
    this.getRecords();
  }

  async getRecords() {
    const { fetchUserDetails } = this.props;
    fetchUserDetails((userDetails) => {
      this.setState(
        {
          isHavingBudget: userDetails.isHavingBudget,
        },
        () => {
          if (this.state.isHavingBudget) {
            this.viewBudgetDetails();
          } else {
            this.setState({
              renderFlag: true,
              tableLoading: false,
            });
          }
        }
      );
    });
  }

  async viewBudgetDetails() {
    try {
      let stringEndDate = "";
      let stringStartDate = "";
      const {
        sizePerPage,
        page,
        filterByStatus,
        filterByMode,
        filterByStartDate,
        filterByEndDate,
      } = this.state;

      if (filterByStartDate) {
        stringStartDate = filterByStartDate.concat("T00:00:00.000Z");
      }
      if (filterByEndDate) {
        stringEndDate = filterByEndDate.concat("T23:59:59.000Z");
      }

      if (!filterByStartDate && filterByEndDate) {
        toast.error("Please mention the start date also!");
      } else if (filterByStartDate && filterByEndDate) {
        if (moment(filterByStartDate) >= moment(filterByEndDate)) {
          toast.error(
            "Please make sure the end date must be greater then start date!"
          );
        } else {
          this.setState({ tableLoading: true });
          const response = await api.get("miner/viewBudgetTransactions", {
            sizePerPage,
            page,
            filterByStatus,
            filterByMode,
            filterByStartDate: stringStartDate,
            filterByEndDate: stringEndDate,
          });

          if (response.code === 200) {
            this.setState(
              {
                recordData: response.data.budgetedMiner,
                renderFlag: true,
                tableLoading: false,
                totalSize: response.data.total,
              },
              () => {
                this.allData();
              }
            );
          } else {
            this.setState({
              renderFlag: true,
              tableLoading: false,
            });
          }
        }
      } else {
        this.setState({ tableLoading: true });
        const response = await api.get("miner/viewBudgetTransactions", {
          sizePerPage,
          page,
          filterByStatus,
          filterByMode,
          filterByStartDate: stringStartDate,
          filterByEndDate: stringEndDate,
        });

        if (response.code === 200) {
          this.setState(
            {
              recordData: response.data.budgetedMiner,
              renderFlag: true,
              tableLoading: false,
              totalSize: response.data.total,
            },
            () => {
              this.allData();
            }
          );
        } else {
          this.setState({
            renderFlag: true,
            tableLoading: false,
          });
        }
      }
    } catch (e) {
      console.log(e);
      this.setState({
        renderFlag: true,
        tableLoading: false,
      });
    }
  }

  render() {
    const {
      filterByEndDate,
      filterByStartDate,
      filterByMode,
      modeList,
      page,
      sizePerPage,
      totalSize,
      statusList,
      filterByStatusLabel,
      filterByStatus,
      renderFlag,
      tableLoading,
      recordData,
      allData,
    } = this.state;
    const _this = this;
    const tableData = recordData.map((data, index) => ({
      key: index,
      ...data,
    }));

    const columns = [
      {
        headerClasses: "text-bold",
        dataField: "createdOnUTC",
        headerFormatter: () => {
          return (
            <span className="ml-1">
              DATE <span style={{ fontWeight: "normal" }}>(YYYY-MM-DD)</span>
            </span>
          );
        },
        text: "",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              {row.createdOnUTC ? (
                <div>{moment(row.createdOnUTC).format("YYYY-MM-DD")}</div>
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "qty",
        text: "QUANTITY",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>{cell}</div>
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amountSubmitted",
        text: "DEPOSIT",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              {row.paymentStatus === "r" ? (
                <div style={{ color: "red" }}>
                  $ {niceNumberNoDecimalDisplay(cell, 2)}
                </div>
              ) : (
                <div>
                  $ {niceNumberNoDecimalDisplay(cell, 2)}
                  {row.deletedMachineNote && (
                    <span className="custom-tooltip-coin2  justify-content-center ml-1 mr-1">
                      <i
                        className=" icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
                        style={{
                          color: "#999999",
                          cursor: "pointer",
                        }}
                      ></i>
                      <DepositTooltip note={row.deletedMachineNote} />
                    </span>
                  )}
                </div>
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-bold",
        dataField: "amountWithdrawal",
        text: "WITHDRAWAL",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              {row.paymentStatus === "c" ? (
                <div style={{ color: "red" }}>
                  $ {niceNumberNoDecimalDisplay(cell, 2)}
                </div>
              ) : (
                <div>$ {niceNumberNoDecimalDisplay(cell, 2)}</div>
              )}
            </div>
          );
        },
      },

      {
        headerClasses: "text-bold",
        dataField: "balance",
        text: "BALANCE",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              <div>$ {niceNumberNoDecimalDisplay(cell, 2)}</div>
            </div>
          );
        },
      },

      {
        headerClasses: "text-bold",
        dataField: "mode",
        text: "MODE",
        sort: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          return (
            <div className="text-left">
              {row.mode === "wireTransfer" ? (
                <div
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    _this.viewBudgetModalShow(
                      row.adminNote,
                      row.userNote,
                      row.userId,
                      row.paymentFileKey,
                      row.paymentStatus
                    );
                  }}
                >
                  wire
                </div>
              ) : (
                <div>{cell}</div>
              )}
            </div>
          );
        },
      },
      {
        headerClasses: "text-center text-bold",
        dataField: "paymentStatus",
        text: "STATUS",
        isDummyField: true,
        formatter: function (cell, row, rowIndex, formatExtraData) {
          let label, className;
          if (row.paymentStatus === "a") {
            label = "PAID";
            className = "badge-success";
          } else if (row.paymentStatus === "r") {
            label = "REJECTED";
            className = "badge-danger";
          } else if (row.paymentStatus === "c") {
            label = "CANCELLED";
            className = "badge-danger";
          } else if (row.paymentStatus === "p") {
            label = "PENDING";
            className = "badge-warning";
          }
          return (
            <div>
              <div className="text-center">
                <div className="d-inline-block">
                  <span className={"badge1 " + className}>{label}</span>
                </div>
              </div>
            </div>
          );
        },
      },
    ];

    const RemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          bordered={false}
          keyField="key"
          data={data}
          columns={columns}
          filter={filterFactory()}
          pagination={
            totalSize > sizePerPage
              ? paginationFactory({ page, sizePerPage, totalSize })
              : ""
          }
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped dataTable"
        />
      </div>
    );

    RemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="adminDashboardContainer">
        <div className="content-i">
          <div className="content-box">
            <div className="element-wrapper filter-element-wrapper kyc-filter-element-wrapper">
              <div className="element-box element-overflow-box pr-0">
                <h5 className="form-header">Filter Transactions</h5>
                <form className="form-inline">
                  <div className="row w-100">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <input
                          style={{
                            border: "1px solid #ebebeb",
                            borderRadius: "8px",
                            padding: "8px 11px",
                            background: "transparent",
                          }}
                          className={
                            !filterByStartDate
                              ? "filterStartDate w-100  mb-2 mr-sm-2 mb-sm-2"
                              : " mb-2 mr-sm-2 w-100 mb-sm-2"
                          }
                          name="filterByStartDate"
                          id="filterByStartDate"
                          placeholder="Start Date"
                          type="date"
                          onChange={this.onchange}
                          value={filterByStartDate}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="form-group">
                        <input
                          style={{
                            border: "1px solid #ebebeb",
                            borderRadius: "8px",
                            padding: "8px 11px",
                            background: "transparent",
                          }}
                          className={
                            !filterByEndDate
                              ? "filterEndDate  w-100 mb-2 mr-sm-2 mb-sm-2"
                              : "w-100 mb-2 mr-sm-2 mb-sm-2"
                          }
                          name="filterByEndDate"
                          id="filterByEndDate"
                          placeholder="End Date"
                          type="date"
                          onChange={this.onchange}
                          value={filterByEndDate}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 selectpad mb-2">
                      <div className="form-group">
                        <Select
                          className="w-100 only-black"
                          value={{
                            value: filterByMode,
                            label: filterByMode ? filterByMode : "Select Mode",
                          }}
                          options={modeList}
                          placeholder="Select Mode"
                          onChange={this.handleChangeMode}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 selectpad">
                      <div className="form-group">
                        <Select
                          className="w-100 only-black"
                          value={{
                            value: filterByStatus,
                            label: filterByStatusLabel
                              ? filterByStatusLabel
                              : "Select Status",
                          }}
                          options={statusList}
                          placeholder="Select Status"
                          onChange={this.handleChangeStatus}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <button
                      className="btn btn-primary  "
                      type="button"
                      onClick={this.filterRecords}
                    >
                      {" "}
                      Filter
                    </button>
                    <button
                      className="btn btn-danger ml-2"
                      type="button"
                      onClick={this.clearFilterRecords}
                    >
                      {" "}
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="element-wrapper mt-4">
              <div className="element-box card-inner-padding">
                <div className="row justify-content-between">
                  <h5 className="text-xl">
                    Budget Transactions
                    <span className="custom-tooltip-coin1 row justify-content-center ml-1 mr-1">
                      <i
                        className="icon-info ml-md-1 mr-1 ml-sm-1 ml-xs-1 "
                        style={{
                          fontSize: "18px",
                          color: "#999999",
                          cursor: "pointer",
                        }}
                      ></i>
                      <BudgetTooltip />
                    </span>
                  </h5>
                  <span>
                    {/* <Link to="/wallets">
                      <i
                        className="fa fa-arrow-left standardColor mr-4"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </Link> */}
                    <button
                      className="btn btn-primary"
                      onClick={() => this.showModal()}
                    >
                      Export Data
                    </button>
                  </span>
                </div>
                <div className="clearfix"></div>
                <div>
                  {tableLoading && (
                    <div className="text-center">
                      <span id="loading" color="#047bf8" />
                    </div>
                  )}
                </div>
                <div>
                  {renderFlag === true && (
                    <RemoteAll
                      data={tableData}
                      page={page}
                      sizePerPage={sizePerPage}
                      totalSize={totalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ViewBudgetModal
          viewBudgetModalOpen={this.state.viewBudgetModalOpen}
          viewBudgetModalClose={this.viewBudgetModalClose}
          docLink={this.state.docLink}
          status={this.state.status}
          adminNote={this.state.adminNote}
          userNote={this.state.userNote}
        />
        <ExportBudgetModal
          allData={allData}
          onCloseModal={this.onCloseModal}
          onInputValueChange={this.onchange}
          modalOpen={this.state.modalOpen}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetails: state.user.userDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserDetails: (success) => dispatch(fetchUserDetails(success)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewBudget);
