import React, { Component } from "react";
import Modal from "react-responsive-modal";
import Select from "react-select";

class NewAddressModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { countries } = this.props;
    countries = [
      { value: "", label: "Select Country" },
      ...countries.map((country) => ({
        value: country._id,
        label: country.name,
      })),
    ];
    const {
      openModal,
      onCloseModal,
      onInputValueChange,
      addNewAddressRequest,
      buttonLoading,
      address1,
      address2,
      address3,
      landmark,
      pinCode,
      city,
      state,
      countryIdValue,
      countryName,
      handleCountryChange,
    } = this.props;
    let buttonDisabled = buttonLoading === true ? true : false;
    return (
      <Modal
        open={openModal}
        onClose={onCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title">
                      Add new shipping address
                    </h4>
                    <form>
                      <div className="form-group">
                        <label htmlFor="address1">
                          Shipping Address 1
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          id="address1"
                          placeholder="Enter address line 1"
                          type="text"
                          value={address1}
                          name="address1"
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="address2">Shipping Address 2</label>
                        <input
                          className="form-control"
                          id="address2"
                          placeholder="Enter address line 2"
                          type="text"
                          value={address2}
                          name="address2"
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="address3">Shipping Address 3</label>
                        <input
                          className="form-control"
                          id="address3"
                          placeholder="Enter address line 3"
                          type="text"
                          value={address3}
                          name="address3"
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="landmark">Landmark</label>
                        <input
                          className="form-control"
                          id="landmark"
                          placeholder="Enter landmark"
                          type="text"
                          value={landmark}
                          name="landmark"
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="city">City/Province</label>
                            <input
                              className="form-control"
                              id="city"
                              placeholder="Enter City"
                              type="text"
                              value={city}
                              name="city"
                              onChange={onInputValueChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="city">State</label>
                            <input
                              className="form-control"
                              id="state"
                              placeholder="Enter State"
                              type="text"
                              value={state}
                              name="state"
                              onChange={onInputValueChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="country">
                              Country<span className="required-field">*</span>
                            </label>
                            <Select
                              className="country-select2"
                              options={countries}
                              placeholder="Select Country"
                              name="countryId"
                              id="country"
                              onChange={handleCountryChange}
                              value={{
                                label: countryName,
                                value: countryIdValue,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="pinCode">
                              Pin Code<span className="required-field">*</span>
                            </label>
                            <input
                              className="form-control"
                              id="pinCode"
                              placeholder="Enter pin code"
                              type="text"
                              value={pinCode}
                              name="pinCode"
                              onChange={onInputValueChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <button
                          className="btn btn-primary permian-button"
                          type="button"
                          onClick={addNewAddressRequest}
                          disabled={buttonDisabled}
                        >
                          {" "}
                          Submit{" "}
                          {buttonLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default NewAddressModal;
