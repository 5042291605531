import { STORE_COUNTRIES } from "./types";
import Api from "../../services/api";
import * as userApi from "../../api/userApi";

export const fetchCountries = (success, error) => {
  const api = new Api();
  return async (dispatch, state) => {
    const { token } = state().user;
    try {
      if (token) {
        const response = await api.setToken(token).get(userApi.GET_COUNTRIES);
        if (response.code === 200 && response.data) {
          let { countries = [] } = response.data;
          dispatch(storeCountries(countries));
          if (success && typeof success == "function") {
            success(countries);
          }
        }
      }
    } catch (err) {
      if (error && typeof error == "function") {
        error(err);
      }
    }
  };
};

export const storeCountries = (countries) => {
  return {
    type: STORE_COUNTRIES,
    payload: countries,
  };
};
