import React, { Component } from "react";
import Modal from "react-responsive-modal";
import Collapsible from "react-collapsible";
import Api from "../../services/api";
import Select from "react-select";
import CreditCardInput from "react-credit-card-input";
import { toast } from "react-toastify";

import "./Subscription.css";

let api;

class UpdatePaymentModal extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      confirmLoading: false,
      expiryDate: this.props.cardExpiryDate,
      billingDetails: false,
      cardDetails: false,
      paymentRequestButtonLoading: false,
    };
    this.updatePaymentMethod = this.updatePaymentMethod.bind(this);
    this.toggleBillingDetails = this.toggleBillingDetails.bind(this);
    this.toggleCardDetails = this.toggleCardDetails.bind(this);
    this.handleChangeExpiry = this.handleChangeExpiry.bind(this);
  }

  handleChangeExpiry(e) {
    this.setState({ expiryDate: e.target.value });
  }

  toggleBillingDetails() {
    if (this.state.billingDetails === true) {
      this.setState({
        billingDetails: false,
      });
    } else if (this.state.billingDetails === false) {
      this.setState({
        billingDetails: true,
      });
    }
  }
  toggleCardDetails() {
    if (this.state.cardDetails === true) {
      this.setState({
        cardDetails: false,
      });
    } else if (this.state.cardDetails === false) {
      this.setState({
        cardDetails: true,
      });
    }
  }
  async updatePaymentMethod() {
    try {
      this.setState({ confirmLoading: true });
      const { expiryDate } = this.state;
      const { email, city, line1, state, postalCode, userName } = this.props;

      let month, year;
      if (expiryDate) {
        let expiryArr = expiryDate.split("/");
        month = expiryArr[0].trim();
        year = expiryArr[1].trim();
      }
      if (expiryDate) {
        const date = new Date();
        let stringYear = date.getFullYear().toString();
        stringYear = stringYear.slice(-2);
        const currentYear = Number(stringYear);

        if (month && year && Number(year) >= currentYear) {
          if (
            email &&
            line1 &&
            userName &&
            this.props.countryIdValue &&
            state &&
            city
          ) {
            const userResponse = await api.create("miner/updatePaymentMethod", {
              email: email,
              line1: line1,
              userName: userName,
              type: "subscribe",
              country: this.props.countryIdValue,
              state: state,
              city: city,
              expi_month: month,
              expi_year: year,
              postal_code: postalCode,
            });
            this.setState({ confirmLoading: false });

            if (userResponse.code === 200) {
              toast.success(userResponse.message);
              this.props.updateModalOnCloseModal();
            } else {
              this.props.updateModalOnCloseModal();
              toast.error(userResponse.message);
            }
          } else {
            toast.error("Please fill the billing details!");
            this.setState({ confirmLoading: false });
          }
        } else {
          toast.error("Please mention the expiry date properly!");
          this.setState({ confirmLoading: false });
        }
      } else if (
        !expiryDate &&
        email &&
        line1 &&
        userName &&
        this.props.countryIdValue &&
        state &&
        city
      ) {
        const userResponse = await api.create("miner/updatePaymentMethod", {
          email: email,
          line1: line1,
          userName: this.props.userName,
          country: this.props.countryIdValue,
          state: state,
          city: city,
          expi_month: month,
          expi_year: year,
          postal_code: postalCode,
        });

        if (userResponse.code === 200) {
          toast.success(userResponse.message);
          this.props.updateModalOnCloseModal();
          this.setState({ confirmLoading: false });
        } else {
          this.props.updateModalOnCloseModal();
          toast.error(userResponse.message);
          this.setState({ confirmLoading: false });
        }
      } else {
        toast.error("Please fill the required details!");
        this.setState({ confirmLoading: false });
      }
    } catch (e) {
      this.props.updateModalOnCloseModal();
      this.setState({ confirmLoading: false });
      console.log(e);
    }
  }

  async onChangeAddress(e) {
    this.setState(
      {
        address: e.target.value,
      },
      () => {
        this.setShow(this.state.address, this.state.endDate);
      }
    );
  }

  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  render() {
    const {
      updateModalOpen,
      updateModalOnCloseModal,
      optionRef,
      handleCountryChange,
      onMenuOpen,
      countryIdValue,
      country,
      countriesList,
      city,
      state,
      postalCode,
      line1,
      email,
      onchange,
    } = this.props;

    const { cardDetails, billingDetails, expiryDate, confirmLoading } =
      this.state;
    return (
      <Modal
        open={updateModalOpen}
        onClose={updateModalOnCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <h4
                      className="text-black"
                      style={{ alignSelf: "flex-start !important" }}
                    >
                      Update the Payment Method
                    </h4>

                    <Collapsible
                      className="text-bold billingCollap mb-3 text-black"
                      trigger={
                        <div
                          className="row p-2 justify-content-between"
                          onClick={() => this.toggleBillingDetails()}
                        >
                          <span
                            className="text-bold text-black"
                            style={{ cursor: "pointer" }}
                          >
                            Billing Details
                          </span>
                          {!billingDetails && (
                            <i className="ml-3 fa fa-plus-circle fa-lg cursor-pointer text-black"></i>
                          )}
                          {billingDetails && (
                            <i className="ml-3 fa fa-minus-circle fa-lg cursor-pointer text-black"></i>
                          )}
                        </div>
                      }
                      // trigger="Billing Details"
                      containerElementProps={{
                        style: {
                          border: "1px solid #DFE1E6",
                          borderRadius: "5px",
                          padding: "10px",
                          marginBottom: "5px",
                        },
                      }}
                    >
                      <div className=" mt-3 form-group text-black">
                        <label htmlFor="title">
                          Email<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control w-100 "
                          type="text"
                          name="email"
                          placeholder="Enter Email"
                          value={email}
                          onChange={onchange}
                        />
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="title">
                          Country<span className="required-field">*</span>
                        </label>
                        <Select
                          autoFocus={false}
                          className="country-select3"
                          classNamePrefix="contry"
                          options={countriesList}
                          placeholder="Select Country"
                          name="countryId"
                          id="country"
                          ref={optionRef}
                          onChange={handleCountryChange}
                          value={{
                            label: country,
                            value: countryIdValue,
                          }}
                          onMenuOpen={onMenuOpen}
                        />
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="title">
                          Address<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control w-100 "
                          id="city"
                          name="line1"
                          type="text"
                          placeholder="Enter Address"
                          value={line1}
                          onChange={onchange}
                        />
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="title">
                          City<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control w-100 "
                          id="city"
                          name="city"
                          type="text"
                          placeholder="Enter City"
                          value={city}
                          onChange={onchange}
                        />
                      </div>

                      <div className="form-group text-black">
                        <label htmlFor="title">
                          State<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control w-100 "
                          type="text"
                          name="state"
                          placeholder="Enter State"
                          value={state}
                          onChange={onchange}
                        />
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="title">Postal Code</label>
                        <input
                          className="form-control w-100 "
                          type="text"
                          name="postalCode"
                          placeholder="Enter Postal Code"
                          value={postalCode}
                          onChange={onchange}
                        />
                      </div>
                    </Collapsible>
                    <Collapsible
                      className="text-bold billingCollap"
                      trigger={
                        <div
                          className="row p-2 justify-content-between text-black"
                          onClick={() => this.toggleCardDetails()}
                        >
                          <span
                            className="text-bold"
                            style={{ cursor: "pointer" }}
                          >
                            Card Details
                          </span>
                          {!cardDetails && (
                            <i className="ml-3 fa fa-plus-circle fa-lg cursor-pointer"></i>
                          )}
                          {cardDetails && (
                            <i className="ml-3 fa fa-minus-circle fa-lg cursor-pointer"></i>
                          )}
                        </div>
                      }
                      containerElementProps={{
                        style: {
                          border: "1px solid #DFE1E6",
                          borderRadius: "5px",
                          padding: "10px",
                        },
                      }}
                    >
                      <div className="mt-2 form-group text-black">
                        <div htmlFor="title">
                          Expiry Date<span className="required-field">*</span>
                        </div>
                        <CreditCardInput
                          containerClassName="fieldContainer"
                          cardImageStyle={{ display: "none" }}
                          containerStyle={{ width: "100%" }}
                          cardNumberInputRenderer={({
                            handleCardNumberChange,
                            props,
                          }) => <input {...props} className="cardNo" />}
                          cardExpiryInputProps={{
                            value: expiryDate,
                            onError: (err) => {
                              this.setState({ expiryDate: "" });
                            },
                            onChange: (e) => {
                              this.handleChangeExpiry(e);
                            },
                          }}
                          cardCVCInputProps={{
                            style: {
                              display: "none",
                            },
                          }}
                          fieldClassName="input"
                        />
                      </div>
                    </Collapsible>
                    <div className="d-flex justify-content-start mt-4">
                      <button
                        className="btn modal-button mr-2 cancel-button"
                        type="button"
                        onClick={updateModalOnCloseModal}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn modal-button update-button"
                        type="button"
                        onClick={this.updatePaymentMethod}
                      >
                        {" "}
                        Confirm{" "}
                        {confirmLoading && (
                          <i className="fa-spin fa fa-spinner text-white ml-2" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default UpdatePaymentModal;
