import React, { Component } from "react";
import Modal from "react-responsive-modal";

class EditProfileModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      editProfileModalOpen,
      onCloseModal,
      onInputValueChange,
      updateProfile,
      editProfileButtonLoading,
      fullName,
      phoneNumber,
      email,
      accountId,
    } = this.props;
    let buttonDisabled = editProfileButtonLoading === true ? true : false;
    return (
      <Modal
        open={editProfileModalOpen}
        onClose={onCloseModal}
        showCloseIcon={false}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content text-center">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div className="onboarding-content">
                    <h4 className="onboarding-title text-large text-black">
                      Edit Profile
                    </h4>
                    <form>
                      <div className="form-group text-black">
                        <label htmlFor="transactionAmount">Account ID</label>
                        <input
                          className="form-control"
                          placeholder="Enter Account"
                          type="text"
                          name="account"
                          id="fullName"
                          value={accountId ? accountId : "N/A"}
                          disabled
                        />
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="transactionAmount">Full Name</label>
                        <input
                          className="form-control"
                          placeholder="Enter name"
                          type="text"
                          name="fullName"
                          id="fullName"
                          value={fullName}
                          onChange={onInputValueChange}
                          disabled
                        />
                      </div>

                      <div className="form-group text-black">
                        <label htmlFor="transactionAmount">
                          Email<span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          disabled={true}
                          placeholder="Enter email"
                          type="text"
                          name="email"
                          id="email"
                          value={email}
                          onChange={onInputValueChange}
                        />
                      </div>
                      <div className="form-group text-black">
                        <label htmlFor="transactionAmount">
                          Contact Number
                          <span className="required-field">*</span>
                        </label>
                        <input
                          className="form-control"
                          placeholder="Enter contact number"
                          type="text"
                          name="phoneNumber"
                          id="phoneNumber"
                          value={phoneNumber}
                          onChange={onInputValueChange}
                        />
                      </div>

                      <div className="d-flex justify-content-end">
                        <button
                          className="btn standardColor account-button my-account-button"
                          type="button"
                          onClick={onCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary permian-button"
                          type="button"
                          disabled={buttonDisabled}
                          onClick={updateProfile}
                        >
                          {" "}
                          Update{" "}
                          {editProfileButtonLoading && (
                            <i className="fa-spin fa fa-spinner text-white m-1" />
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default EditProfileModal;
