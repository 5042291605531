import React from "react";

const EarningsTooltip = () => {
  return (
    <div className="earningTooltip">
      <span>
        Subscribe for a monthly Power Pack to enjoy uninterrupted mining
        services. Power Pack subscribers will receive the following benefits:
      </span>
      <ul>
        <li>Automated monthly electricity payments</li>
        <li>priority service on maintenance and repair</li>
        <li>earn rewards in the form of Digital Energy Currency ($DEC)</li>
      </ul>
      <span>
        Power Pack subscribers will receive $DEC for any difference between the
        Power Pack subscription price and actual power cost as recorded in the
        Electricity Fee invoices on the Invoices section.
      </span>
    </div>
  );
};

export default EarningsTooltip;
