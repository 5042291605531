import React from "react";
const BtcTooltip = () => {
  return (
    <div className="btcTooltip">
      <span>BTC Payment</span>
    </div>
  );
};

export default BtcTooltip;
