import React, { Component } from "react";
import moment from "moment";
import Modal from "react-responsive-modal";
import Switch from "react-switch";
import { ExportCSV } from "./ExportCSV";
import "./Earnings.css";
import Api from "../../services/api";
import { niceNumberNoDecimalDisplay, getBtcUsd } from "../../utils/Util";

let api;
class EarningModal extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      startDate: "",
      endDate: "",
      show: false,
      timeHorizon: "",
      fileName: "MinerData",
      isActual: false,
    };
  }

  toggleActualAndLive = (e) => {
    this.setState({ isActual: !this.state.isActual });
  };

  setShow = (startDate, endDate) => {
    this.setState({ show: startDate && endDate ? true : false });
  };
  clearTime = () => {
    this.setState({ startDate: "", endDate: "", timeHorizon: "" });
  };

  onChangeTimeHorizon = (time) => {
    this.setState({
      timeHorizon: time,
    });
  };
  onChangeStartDate = (e) => {
    this.setState(
      {
        startDate: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
      }
    );
  };
  onChangeEndDate = (e) => {
    this.setState(
      {
        endDate: e.target.value,
      },
      () => {
        this.setShow(this.state.startDate, this.state.endDate);
      }
    );
  };

  getEarnings = async () => {
    const { earningsTotalSize } = this.props;
    const { isActual } = this.state;

    try {
      const response = await api.get("user/earnings", {
        page: 1,
        sizePerPage: earningsTotalSize,
      });
      if (response.code !== 200) {
        alert("Fail to fetch data");
      }
      const priceUsd = await getBtcUsd();
      const earningsData =
        response.data && response.data.earnings ? response.data.earnings : [];
      const earningsArr = earningsData.map((data) => {
        const actualRate = data.electricityCostInUsd
          ? data.electricityCostInUsd / data.electricityCost
          : 0;
        const rate = isActual ? actualRate : priceUsd;
        return {
          date: data.date ? moment(data.date).format("YYYY/MM/DD") : "",
          grossEarnings: data.grossProfit
            ? niceNumberNoDecimalDisplay(data.grossProfit, 8)
            : "0",
          grossEarningsInUSD: data.grossProfit
            ? niceNumberNoDecimalDisplay(data.grossProfit * rate, 2)
            : "0",
          fees: data.poolFee
            ? niceNumberNoDecimalDisplay(data.poolFee, 8)
            : "0",
          feesInuUSD: data.poolFee
            ? niceNumberNoDecimalDisplay(data.poolFee * rate, 2)
            : "0",
          powerCost: isActual
            ? niceNumberNoDecimalDisplay(data.electricityCost, 8)
            : niceNumberNoDecimalDisplay(
                data.electricityCostInUsd / priceUsd,
                8
              ),
          powerCostiInUSD: data.electricityCostInUsd
            ? niceNumberNoDecimalDisplay(data.electricityCostInUsd, 2)
            : "0",
          netEarnings: data.netProfit
            ? niceNumberNoDecimalDisplay(data.netProfit, 8)
            : "0",
          netEarningsInUSD: data.netProfit
            ? niceNumberNoDecimalDisplay(data.netProfit * rate, 2)
            : "0",
        };
      });
      this.setState({
        excelData: earningsArr,
      });
    } catch (error) {
      console.log(error);
    }
  };

  async componentDidUpdate(_prevProps, prevState) {
    if (prevState.isActual !== this.state.isActual) {
      await this.getEarnings();
    }
  }

  async componentDidMount() {
    await this.getEarnings();
  }

  render() {
    const { modalOpen, onCloseModal } = this.props;
    const { fileName, timeHorizon } = this.state;
    return (
      <Modal
        open={modalOpen}
        onClose={onCloseModal}
        center
        classNames={{
          closeIcon: "pull-right cursor-pointer",
          modal: "instructon-modal-container",
        }}
      >
        <div className="onboarding-modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content ">
              <div className="onboarding-slider-w">
                <div className="onboarding-slide">
                  <div
                    className="onboarding-content"
                    style={{ minWidth: "550px" }}
                  >
                    <h4 className=" text-black">Export Data</h4>
                    <div className="switch-actual-live">
                      <Switch
                        onColor="#6485e1"
                        checked={this.state.isActual}
                        onChange={this.toggleActualAndLive}
                        onHandleColor="#2693e6"
                        handleDiameter={25}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        height={25}
                        width={42}
                        className="actual-live-switch"
                      />
                      <p className="ml-2 text-black">
                        {this.state.isActual ? "ACTUAL" : "LIVE"}
                      </p>
                    </div>
                    <div className="form-group text-black">
                      <label htmlFor="title">
                        Start Date<span className="required-field">*</span>
                      </label>
                      <input
                        className="form-control w-100"
                        id="startDate"
                        type="date"
                        value={this.state.startDate}
                        onChange={this.onChangeStartDate}
                      />
                    </div>

                    <div className="form-group text-black">
                      <label htmlFor="title">
                        End Date<span className="required-field">*</span>
                      </label>
                      <input
                        className="form-control w-100 "
                        id="endDate"
                        type="date"
                        value={this.state.endDate}
                        onChange={this.onChangeEndDate}
                      />
                    </div>
                    <div className="separator text-black">
                      <hr />
                      <span>OR</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "20px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="text-black">Select Time Horizon</div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="btn-group mr-1 mb-1"
                          style={{ width: "151px" }}
                        >
                          <button
                            aria-expanded="false"
                            aria-haspopup="true"
                            className="btn default-border-btn dropdown-toggle"
                            data-toggle="dropdown"
                            id="dropdownMenuButton6"
                            type="button"
                          >
                            {timeHorizon || <span>Time Horizon</span>}
                          </button>
                          <div aria-labelledby="" className="dropdown-menu">
                            <button
                              className="mr-2 mb-2 dropdown-item"
                              type="button"
                              onClick={() => {
                                this.onChangeTimeHorizon("24H");
                              }}
                            >
                              24H
                            </button>

                            <button
                              className="mr-2 mb-2 dropdown-item"
                              type="button"
                              onClick={() => {
                                this.onChangeTimeHorizon("7D");
                              }}
                            >
                              7D
                            </button>

                            <button
                              className="mr-2 mb-2 dropdown-item"
                              type="button"
                              onClick={() => {
                                this.onChangeTimeHorizon("30D");
                              }}
                            >
                              30D
                            </button>
                            <button
                              className="mr-2 mb-2 dropdown-item"
                              type="button"
                              onClick={() => {
                                this.onChangeTimeHorizon("All");
                              }}
                            >
                              All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pull-left mb-3">
                      <ExportCSV
                        csvData={this.state.excelData}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        timeHorizon={this.state.timeHorizon}
                        fileName={fileName}
                        onCloseModal={onCloseModal}
                        clearTime={this.clearTime}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default EarningModal;
