import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import auth from "../../utils/auth";
import Api from "../../services/api";
import * as messageConstants from "../../utils/Messages";
import progressIcon from "../../assets/img/review.svg";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";

let api;

class KycStatus extends React.Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      error: null,
      note: "",
    };
  }

  async componentWillMount() {
    document.title =
      messageConstants.KYC_STATUS_PAGE_TITLE +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    pageProgress("display");
    try {
      const userResponse = await api.create("user/getUserDetails");
      if (userResponse.code === 200) {
        if (userResponse.data.isKycVerified === true) {
          this.props.history.push("/kyc_status/approved");
        }
        if (userResponse.data.kycStatus === "r") {
          this.props.history.push("/kyc_status/rejected");
        }
        if (
          !_.isUndefined(userResponse.data.userKycDetail.noteHistory) &&
          !_.isEmpty(userResponse.data.userKycDetail.noteHistory) &&
          userResponse.data.userKycDetail.noteHistory !== "" &&
          userResponse.data.userKycDetail.noteHistory !== null
        ) {
          this.setState({
            note: userResponse.data.userKycDetail.noteHistory,
          });
        }
      }
      pageProgress("remove");
    } catch (error) {
      pageProgress("remove");
    }
  }

  render() {
    const { note } = this.state;
    let dateArr = [];
    let kycStatus = this.props.match.params.status;
    let buttonDisplay = false;
    let heading =
      kycStatus === "approved"
        ? "Verified"
        : kycStatus === "rejected"
        ? "Not Verified"
        : "Under Review";
    let description =
      kycStatus === "approved"
        ? messageConstants.KYC_DOCUMENTS_VERIFIED_SUCCESS
        : kycStatus === "rejected"
        ? messageConstants.KYC_DOCUMENTS_REJECTED
        : messageConstants.KYC_DOCUMENTS_UNDER_REVIEW;

    let redirectLink = "";
    if (kycStatus === "approved" || kycStatus === "rejected") {
      buttonDisplay = true;
      redirectLink = kycStatus === "approved" ? "/dashboard" : "/kyc";
    }

    if (kycStatus === "approved") {
      let userInfo = {};
      let authUserInfo = this.props.authUserInfo;
      userInfo.fullName = authUserInfo.fullName;
      userInfo.userDisplayName = authUserInfo.userDisplayName;
      userInfo.email = authUserInfo.email;
      userInfo.isKycVerified = true;
      userInfo.appovedScreenViwed = authUserInfo.appovedScreenViwed;
      auth.setUserInfo(userInfo);
    }

    return (
      <div className="kyc-status-container text-center">
        <h1 className="kyc-header standardColorIcon">KYC</h1>
        <div className="kyc-content pl-5 pr-5">
          <h2 className=" mt-3">
            <img src={progressIcon} alt="progress" />
          </h2>
          <h3 className="text-xl kycstatus-heading ">{heading}</h3>
          <div className="row mt-3">
            <div className="col-md-12">
              <h6 className="mt-3" style={{ fontWeight: "normal" }}>
                {description}
              </h6>
              {note !== null &&
                !_.isEmpty(note) &&
                !_.isUndefined(note) &&
                note !== "" &&
                note.map((note) => {
                  dateArr = note.date.split("T");
                  return (
                    <div>
                      <h6 className="mt-4 text-black">Note : {note.note}</h6>
                      <h6 className="mt-4 black">Date : {dateArr[0]}</h6>
                    </div>
                  );
                })}
            </div>
          </div>
          {buttonDisplay === true && (
            <div className="row mt-5">
              <div className="col-md-12">
                <Link to={redirectLink} className="">
                  <span className="btn cancel-button mr-2 standardColorIcon">
                    Go to Dashboard
                  </span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authUserInfo: state.user.userInfo,
});

export default connect(mapStateToProps)(KycStatus);
