import React, { Component } from "react";
import _ from "lodash";
import Api from "../../services/api";
import { PRICE_KEY } from "../../services/api-config";
import * as messageConstants from "../../utils/Messages";
import { loadStripe } from "@stripe/stripe-js";
import { niceNumberDecimalDisplay, upgradeAlert } from "../../utils/Util";
import moment from "moment";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import questionImg from "../../assets/img/questionMark.svg";
import EarningsTooltip from "./EarningsTooltip";
import PropTypes from "prop-types";
import paginationFactory from "react-bootstrap-table2-paginator";
import UpgradeModal from "./UpgradeModal";
import UpdatePaymentModal from "./UpdatePaymentModal";
import HomePage from "./HomePage";
import { Elements } from "@stripe/react-stripe-js";
import overlayFactory from "react-bootstrap-table2-overlay";
import DepositModal from "./DepositModal";
import "./Subscription.css";
import { pageProgress } from "../../utils/Util";
import { connect } from "react-redux";
import { fetchUserDetails } from "../../store/actions/UserAction";
import Link from "react-router-dom/Link";

let api;

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    api = new Api();
    this.state = {
      userName: "",
      email: "",
      line1: "",
      page: 1,
      invoiceTotalSize: 0,
      invoiceSizePerPage: 10,
      confirmationEmail: "",
      userQuantity: "",
      userShowQuantity: 0,
      showpayment: false,
      nextCycle: "",
      totalWorkers: 0,
      newPayment: false,
      subscribeUnitAmount: "",
      workerLoading: false,
      renderFlag: false,
      countryName: "Select country",
      showUpdate: false,
      invoiceAmount: "",
      invoiceQuantity: "",
      countrIdValue: "",
      countryId: "",
      countryCode: "",
      countriesList: [],
      stripeKey: "",
      earningsRenderFlag: false,
      upgradeModalOpen: false,
      quantity: "",
      upgradeAmount: "",
      unitAmount: "",
      updateModalOpen: false,
      isSubscribed: "",
      lastDate: "",
      subscriptionData: [],
      paySubscribedInvoice: false,
      postalCode: "",
      depositModalOpen: false,
      tableLoading: true,
      state: "",
      city: "",
      cardExpiryDate: "",
      country: "Select country",
      stripePromise: "",
    };
    this.optionRef = React.createRef();
    pageProgress("force_remove");
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onSubscribe = this.onSubscribe.bind(this);
    this.getRecords = this.getRecords.bind(this);
    this.depositOnCloseModal = this.depositOnCloseModal.bind(this);
    this.depositOnShowModal = this.depositOnShowModal.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.upgradeModalClose = this.upgradeModalClose.bind(this);
    this.upgradeModalShow = this.upgradeModalShow.bind(this);

    this.onQuantityChange = this.onQuantityChange.bind(this);
    this.upgradeSubscription = this.upgradeSubscription.bind(this);
    this.getSubscriptions = this.getSubscriptions.bind(this);
    this.generateInvoiceNumber = this.generateInvoiceNumber.bind(this);
    this.getWorkers = this.getWorkers.bind(this);
    this.getCountriesList = this.getCountriesList.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.onMenuOpen = this.onMenuOpen.bind(this);
    this.showUpdateModal = this.showUpdateModal.bind(this);
    this.updateModalOnCloseModal = this.updateModalOnCloseModal.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.onNewPayment = this.onNewPayment.bind(this);
    this.getSubscriptionsDetails = this.getSubscriptionsDetails.bind(this);
    this.getCardDetails = this.getCardDetails.bind(this);
    this.onchange = this.onchange.bind(this);
    this.getStripeKey = this.getStripeKey.bind(this);
  }
  async getStripeKey() {
    const response = await api.create("miner/getPaymentCredentials", {
      paymentMode: "stripe",
    });

    if (response.code === 200) {
      this.setState({
        stripeKey: response.data[0].key,
        stripePromise: loadStripe(response.data[0].key),
      });
    }
  }

  onchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  async getCardDetails() {
    try {
      const { isSubscribed, countriesList } = this.state;
      if (isSubscribed) {
        const userResponse = await api.get("miner/viewCustomerDetails", {
          type: "subscribe",
        });
        if (userResponse.code === 200) {
          if (userResponse.data.billing_details) {
            this.setState(
              {
                email: userResponse.data.billing_details.email,
                line1: userResponse.data.billing_details.address
                  ? userResponse.data.billing_details.address.line1
                  : "",
                state: userResponse.data.billing_details.address
                  ? userResponse.data.billing_details.address.state
                  : "",
                city: userResponse.data.billing_details.address
                  ? userResponse.data.billing_details.address.city
                  : "",
                countryCode: userResponse.data.billing_details.address
                  ? userResponse.data.billing_details.address.country
                  : "",
                countryId: userResponse.data.billing_details.address
                  ? userResponse.data.billing_details.address.country
                  : "",
                countryIdValue: userResponse.data.billing_details.address
                  ? userResponse.data.billing_details.address.country
                  : "",
                postalCode: userResponse.data.billing_details.address
                  ? userResponse.data.billing_details.address.postal_code
                  : "",
                cardExpiryDate: userResponse.data.card
                  ? `${
                      userResponse.data.card.exp_month.length === 1
                        ? "0" + userResponse.data.card.exp_month
                        : userResponse.data.card.exp_month
                    }/${String(userResponse.data.card.exp_year).slice(-2)}`
                  : "",
              },
              () => {
                if (countriesList) {
                  const getCountry = countriesList.filter((data) => {
                    return data.value === this.state.countryCode;
                  });
                  if (!_.isEmpty(getCountry)) {
                    this.setState({ country: getCountry[0].label }, () => {});
                  }
                }
              }
            );
          } else if (userResponse.data.address) {
            this.setState(
              {
                userName: userResponse.data.name,
                email: userResponse.data.email,
                line1: userResponse.data.address
                  ? userResponse.data.address.line1
                  : "",
                state: userResponse.data.address
                  ? userResponse.data.address.state
                  : "",
                city: userResponse.data.address
                  ? userResponse.data.address.city
                  : "",
                countryCode: userResponse.data.address
                  ? userResponse.data.address.country
                  : "",
                countryId: userResponse.data.address
                  ? userResponse.data.address.country
                  : "",
                countryIdValue: userResponse.data.address
                  ? userResponse.data.address.country
                  : "",
                postalCode: userResponse.data.address
                  ? userResponse.data.address.postal_code
                  : "",
              },
              () => {
                if (countriesList) {
                  const getCountry = countriesList.filter((data) => {
                    return data.value === this.state.countryCode;
                  });
                  if (!_.isEmpty(getCountry)) {
                    this.setState({ country: getCountry[0].label }, () => {});
                  }
                }
              }
            );
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleTableChange = (type, { page, sizePerPage, filters }) => {
    if (
      this.state.invoiceSizePerPage !== sizePerPage ||
      this.state.page !== page
    ) {
      this.setState({ invoiceSizePerPage: sizePerPage, page: page }, () => {
        this.getRecords();
      });
    } else {
      if (!_.isUndefined(filters)) {
        this.setState({ filters: filters }, () => {
          this.getRecords();
        });
        return true;
      }
    }
  };

  showUpdateModal() {
    this.setState({
      updateModalOpen: true,
    });
  }
  onNewPayment() {
    if (this.state.newPayment === true) {
      this.setState({
        newPayment: false,
      });
    } else if (this.state.newPayment === false) {
      this.setState({
        newPayment: true,
      });
    }
  }
  updateModalOnCloseModal() {
    this.setState({
      updateModalOpen: false,
    });
  }
  onUpdate() {
    if (this.state.showUpdate === true) {
      this.setState({
        showUpdate: false,
      });
    } else if (this.state.showUpdate === false) {
      this.setState({
        showUpdate: true,
      });
    }
  }
  onMenuOpen() {
    const { countriesList, countryIdValue } = this.state;
    setTimeout(() => {
      this.optionRef.current.select.scrollToFocusedOptionOnUpdate = true;
      this.optionRef.current.select.setState({
        focusedOption: countriesList.find(
          (option) => option.value === countryIdValue
        ),
      });
    }, 0);
  }

  handleCountryChange(selectedOption) {
    this.setState({
      countryIdValue: selectedOption.value,
      country: selectedOption.label,
    });
  }

  async getCountriesList() {
    const { countries } = this.props;
    this.setState(
      {
        countriesList: countries.map((country) => ({
          value: country.sortname,
          label: country.name,
        })),
      },
      () => {
        this.getCardDetails();
      }
    );
  }

  generateInvoiceNumber() {
    let number = Math.floor(Math.random() * 1000000);
    if (number.toString().length === 6) {
      return number;
    } else if (number.toString().length === 5) {
      number = `${number}0`;
    } else if (number.toString().length === 4) {
      number = `${number}00`;
    } else if (number.toString().length === 3) {
      number = `${number}000`;
    } else if (number.toString().length === 2) {
      number = `${number}0000`;
    } else if (number.toString().length === 1) {
      number = `${number}00000`;
    } else if (number.toString().length === 0) {
      number = `${number}000000`;
    }
    return number;
  }

  async onChangeEmail(e) {
    this.setState({
      confirmationEmail: e.target.value,
    });
  }
  async onQuantityChange(e) {
    const { subscribeUnitAmount } = this.state;
    this.setState(
      {
        quantity: e.target.value,
      },
      () => {
        if (this.state.quantity > this.state.totalWorkers) {
          this.setState({
            quantity: this.state.totalWorkers,
            upgradeAmount: this.state.totalWorkers * subscribeUnitAmount,
          });
          toast.error("Quantity is exceeding the workers you have!");
        } else if (this.state.quantity <= this.state.totalWorkers) {
          this.setState({
            upgradeAmount: this.state.quantity * subscribeUnitAmount,
          });
        }
      }
    );
  }
  async cancelSubscription() {
    this.depositOnCloseModal();
    const userResponse = await api.get("miner/cancelSubscription");

    if (userResponse.code === 200) {
      toast.success(userResponse.message);
      this.setState({ userShowQuantity: 0 });
      this.getRecords();
    } else {
      toast.error(userResponse.message);
    }
  }
  async getWorkers() {
    try {
      const userResponse = await api.get("user/miner/workers");
      if (userResponse.code === 200) {
        this.setState({
          totalWorkers: userResponse.data.totalMachines,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
  async upgradeSubscription() {
    if (this.state.quantity > 0) {
      this.upgradeModalClose();
      const { subscribeUnitAmount } = this.state;
      const userResponse = await api.create("miner/updateSubscription", {
        price: PRICE_KEY,
        quantity: this.state.quantity,
        amount: this.state.quantity * subscribeUnitAmount,
      });

      if (userResponse.code === 200) {
        toast.success(userResponse.message);
        this.getRecords();
      } else {
        toast.error(userResponse.message);
      }
    } else {
      toast.error("Please make sure the quantity is greater than 0!");
    }
  }
  upgradeModalShow() {
    this.setState({ upgradeModalOpen: true });
  }
  upgradeModalClose() {
    this.setState({ upgradeModalOpen: false });
  }
  depositOnCloseModal() {
    this.setState({ depositModalOpen: false });
  }
  depositOnShowModal() {
    this.setState({ depositModalOpen: true });
  }
  onSubscribe() {
    if (this.state.showpayment === false) {
      this.setState({ showpayment: true });
    } else if (this.state.showpayment === true) {
      this.setState({ showpayment: false });
    }
  }

  onCloseModal() {
    this.setState({ modalOpen: false });
  }

  async componentDidMount() {
    document.title =
      "Subscriptions" +
      messageConstants.PAGE_TITLE_SEPERATOR +
      messageConstants.PERMIAN_LABEL;
    this.getStripeKey();
    this.setState({
      lastDate: moment().endOf("month").format("DD, MMM YYYY"),
    });
    this.getRecords();
    if (upgradeAlert(this.state.userEmail)) {
      toast.success(
        "Your Subscription will be upgraded automatically on 1 Feb, 2022"
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.countries !== prevProps.countries) {
      this.getCountriesList();
    }
  }

  async getSubscriptionsDetails() {
    try {
      const { isSubscribed } = this.state;
      if (isSubscribed === true) {
        const userResponse = await api.get("miner/retrieveSubscription");
        if (userResponse.code === 200) {
          this.setState(
            {
              userQuantity: userResponse.data.quantity,
              quantity: userResponse.data.quantity,
              nextCycle: userResponse.data.current_period_end,
              unitAmount: userResponse.data.amount,
              userShowQuantity: userResponse.data.quantity
                ? userResponse.data.quantity
                : 0,
            },
            () => {
              this.viewProductDetails();
            }
          );
        }
      } else {
        this.viewProductDetails();
      }
      this.setState({
        tableLoading: false,
        workerLoading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async viewProductDetails() {
    const { isSubscribed } = this.state;
    if (isSubscribed === true) {
      const SubscriptionIdResponse = await api.create(
        "miner/viewSubscriptionId"
      );

      if (SubscriptionIdResponse.code === 200) {
        if (
          SubscriptionIdResponse.data &&
          SubscriptionIdResponse.data.subscribe
        ) {
          const userResponse = await api.create("miner/viewProductDetails", {
            prc: SubscriptionIdResponse.data.subscribe.stripePriceId,
          });
          if (userResponse.code === 200) {
            this.setState({
              subscribeUnitAmount: userResponse.data.unit_amount,
              upgradeAmount:
                this.state.quantity * userResponse.data.unit_amount,
            });
          }
        }
      }
    } else if (isSubscribed === false) {
      const userResponse = await api.create("miner/viewProductDetails", {
        prc: PRICE_KEY,
      });
      if (userResponse.code === 200) {
        this.setState({ subscribeUnitAmount: userResponse.data.unit_amount });
      }
    }
  }

  async getSubscriptions() {
    try {
      const { invoiceSizePerPage, page } = this.state;
      const userResponse = await api.get("miner/viewSubscribedInvoices", {
        page: page,
        sizePerPage: invoiceSizePerPage,
      });

      if (userResponse.code === 200 && userResponse.data.invoices) {
        this.setState({
          subscriptionData: userResponse.data.invoices,
          invoiceTotalSize: userResponse.data.number,
        });
      }
      this.setState({
        tableLoading: false,
        workerLoading: false,
        renderFlag: true,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getRecords() {
    const { fetchUserDetails } = this.props;
    fetchUserDetails((userDetails) => {
      this.setState(
        {
          userName: userDetails.fullName,
          email: userDetails.email,
          isSubscribed: userDetails.isSubscribed,
          line1: userDetails.userKycDetail
            ? userDetails.userKycDetail.addressLine1
            : "",
          state: userDetails.userKycDetail
            ? userDetails.userKycDetail.state
            : "",
          city: userDetails.userKycDetail ? userDetails.userKycDetail.city : "",
          country: userDetails.userKycDetail
            ? userDetails.userKycDetail.countryId.name
            : "Select Country",
          countryCode: userDetails.userKycDetail
            ? userDetails.userKycDetail.countryId.sortname
            : "",
          countryId: userDetails.userKycDetail
            ? userDetails.userKycDetail.countryId._id
            : "",
          countryIdValue: userDetails.userKycDetail
            ? userDetails.userKycDetail.countryId.sortname
            : "",
        },
        () => {
          this.getWorkers();
          this.getSubscriptionsDetails();
          this.getCountriesList();
          this.getSubscriptions();
        }
      );
    });
  }

  render() {
    const {
      userQuantity,
      unitAmount,
      userShowQuantity,
      invoiceAmount,
      invoiceQuantity,
      upgradeAmount,
      totalWorkers,
      tableLoading,
      renderFlag,
      subscriptionData,
      newPayment,
      countriesList,
      isSubscribed,
      confirmationEmail,
      countryIdValue,
      countryCode,
      nextCycle,
      invoiceSizePerPage,
      invoiceTotalSize,
      page,
      cardExpiryDate,
      workerLoading,
      stripeKey,
      stripePromise,
    } = this.state;
    const workerColumns = [
      {
        headerClasses: "custom-table-th",
        dataField: "_id",
        text: "INVOICE ID",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <span style={{ letterSpacing: "1px" }}>
              {row._id ? row._id.substr(row._id.length - 6) : ""}
            </span>
          );
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "subscriptionName",
        text: "PRODUCT",
        isDummyField: true,
        formatter: function (cell, row) {
          return <span>{row.subscriptionName}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "amountDue",
        text: "AMOUNT",
        isDummyField: true,
        formatter: function (cell, row) {
          return <span>$ {niceNumberDecimalDisplay(row.amountDue, 2)}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "amountPaid",
        text: "AMOUNT PAID",
        isDummyField: true,
        formatter: function (cell, row) {
          return <span>$ {niceNumberDecimalDisplay(row.amountPaid, 2)}</span>;
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "amountRemaining",
        text: "AMOUNT DUE",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <span>$ {niceNumberDecimalDisplay(row.amountRemaining, 2)}</span>
          );
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "periodStart",
        text: "Date",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <span>
              {row.periodStart
                ? moment(row.periodStart).format("YYYY-MM-DD")
                : ""}
            </span>
          );
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "Auto",
        text: "BILLING",
        isDummyField: true,
        formatter: function (cell, row) {
          return <span>Auto</span>;
        },
      },
      {
        headerClasses: "custom-table-th ",
        dataField: "status",
        text: "status",
        isDummyField: true,
        formatter: function (cell, row) {
          let statusLabel = row.status;
          let classLabel = "badge-warning1";
          if (row.status === "paid") {
            classLabel = "badge-success";
          }
          return (
            <span style={{ display: "flex", alignItems: "center" }}>
              <span
                className={"badge1 " + classLabel}
                style={{ marginRight: "5px" }}
              >
                {statusLabel}
              </span>
            </span>
          );
        },
      },
      {
        headerClasses: "custom-table-th",
        dataField: "",
        text: "OPERATIONS",
        isDummyField: true,
        formatter: function (cell, row) {
          return (
            <button
              className="btn btn-primary"
              onClick={() => {
                row.status === "paid"
                  ? window.open(row.hostedInvoiceUrl, "_blank")
                  : this.props.history.push("/invoices");
              }}
            >
              {row.status === "paid" ? "Download" : "Pay"}
            </button>
          );
        },
      },
    ];

    const WorkerRemoteAll = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className="table-responsive">
        <BootstrapTable
          remote
          keyField="_id"
          bordered={false}
          loading={workerLoading}
          data={data}
          columns={workerColumns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          noDataIndication="No results!"
          overlay={overlayFactory({
            spinner: true,
            background: "rgba(192,192,192,0.3)",
          })}
          classes="table table-striped table-lightfont dataTable"
        />
      </div>
    );

    WorkerRemoteAll.propTypes = {
      data: PropTypes.array.isRequired,
      page: PropTypes.number.isRequired,
      totalSize: PropTypes.number.isRequired,
      sizePerPage: PropTypes.number.isRequired,
      onTableChange: PropTypes.func.isRequired,
    };

    return (
      <div className="buy-xpr-container">
        <div className="content-i">
          <div className="content-box">
            {this.state.showpayment === false && (
              <div className="row ">
                <div className="col-sm-12 mt-3 col-md-12 col-lg-5 powertab">
                  <div className=" dark-blue-theme-color compresspad h-100  p-5">
                    <div className="row justify-content-between">
                      <div className="row subscribeCol mr-2 mb-2 flex-column justify-content-between align-items-start">
                        <div>
                          {!isSubscribed && (
                            <h2 className="setHeading">
                              Subscribe to Power Pack{" "}
                              <span className="custom-tooltip-coin4 row justify-content-center ml-1 mr-1">
                                <img
                                  className="questionColor"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    position: "relative",
                                    top: "-5px",
                                  }}
                                  src={questionImg}
                                  alt=""
                                />
                                <EarningsTooltip />
                              </span>
                            </h2>
                          )}
                          {isSubscribed && (
                            <h2 className="">
                              Power Pack{" "}
                              <span className="custom-tooltip-coin4 row justify-content-center ml-1 mr-1">
                                <img
                                  className="questionColor"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    position: "relative",
                                    top: "-5px",
                                  }}
                                  src={questionImg}
                                  alt=""
                                />
                                <EarningsTooltip />
                              </span>
                            </h2>
                          )}
                        </div>
                        <div className="row widthDouble justify-content-between">
                          <div
                            className="current"
                            style={{ marginLeft: "9px" }}
                          >
                            {!isSubscribed && (
                              <div
                                className="amountdiv"
                                style={{ marginLeft: "7px" }}
                              >
                                <h3 className="text-bold">
                                  ${this.state.subscribeUnitAmount}
                                </h3>
                                <span
                                  style={{ fontSize: "14px" }}
                                  className="row ml-1 flex-column align-items-start justify-content-center text-bold "
                                >
                                  <span className="per text-gray">per</span>
                                  <span className="month text-gray">month</span>
                                </span>
                              </div>
                            )}
                            {isSubscribed && (
                              <div
                                className="amountdiv"
                                style={{ marginLeft: "7px" }}
                              >
                                <h3 className="text-bold">
                                  ${userQuantity * unitAmount}
                                </h3>
                                <span
                                  style={{ fontSize: "14px" }}
                                  className="row ml-1 flex-column align-items-start justify-content-center text-bold"
                                >
                                  <span className="per text-gray">per</span>
                                  <span className="month text-gray">month</span>
                                </span>
                              </div>
                            )}
                            {!isSubscribed && (
                              <div>
                                <span>
                                  {" "}
                                  <button
                                    className="btn btn-primary mt-3"
                                    onClick={this.onSubscribe}
                                  >
                                    Subscribe
                                  </button>
                                </span>
                                <span>
                                  <button
                                    className="btn btn-primary ml-3 mt-3"
                                    onClick={() => {
                                      this.props.history.push(
                                        "/dashboard?showSubscriptions=true"
                                      );
                                    }}
                                  >
                                    I’ll do it later
                                  </button>
                                </span>
                              </div>
                            )}
                            {isSubscribed && (
                              <div className="columnButton">
                                <button
                                  className="btn btn-primary mt-2"
                                  onClick={this.upgradeModalShow}
                                >
                                  Upgrade
                                </button>
                                <button
                                  className="btn btn-primary ml-3 mt-2"
                                  onClick={this.depositOnShowModal}
                                >
                                  Cancel
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="text-color-two progressCol circleShow row mb-2 justify-content-between">
                            <CircularProgressbar
                              value={Number(
                                totalWorkers !== 0
                                  ? (userShowQuantity / totalWorkers) * 100
                                  : 0
                              )}
                              text={`${userShowQuantity}/${totalWorkers} workers`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="progressCol circleHide row mb-2 justify-content-between">
                        <CircularProgressbar
                          value={Number(
                            totalWorkers !== 0
                              ? (userShowQuantity / totalWorkers) * 100
                              : 0
                          )}
                          text={`${userShowQuantity}/${totalWorkers} workers`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {isSubscribed && (
                  <div className="col-sm-12 mt-3 col-md-12 col-lg-3 paymenttab">
                    <div className=" dark-blue-theme-color compresspad h-100 myaccount_card p-5">
                      <div className="row justify-content-between">
                        <div className="row flex-column justify-content-between align-items-start">
                          <div>
                            <h2 className="">Next Payment</h2>
                            <span className="text-bold">
                              on {moment(nextCycle).format("DD, MMM YYYY")}
                            </span>
                          </div>
                          <div className="row">
                            <button
                              className="btn mr-2 mt-5 btn-primary"
                              onClick={this.showUpdateModal}
                            >
                              Update Card
                            </button>
                            <button
                              className="btn mt-5 btn-primary"
                              onClick={() => {
                                this.onNewPayment();
                                this.onSubscribe();
                              }}
                            >
                              Change Card
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {this.state.showpayment === false && (
              <div className="element-box card-inner-padding mgtp-xl-4 mgtp-lg-4 mgtp-xs-4 mgtp-md-4 mgtp-sm-4 ">
                <div className="earnings-table table-responsive">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "5px",
                      alignItems: "center !important",
                      paddingTop: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "5px",
                        alignItems: "center !important",
                        paddingTop: "5px",
                      }}
                    >
                      <h4 className="card-title-font form-table-header">
                        <span className="walletFont"> Invoices</span>
                      </h4>
                    </div>
                  </div>
                  <div>
                    {tableLoading && (
                      <div className="text-center mb-2 mt-2">
                        <span id="loading" color="#047bf8" />
                      </div>
                    )}
                  </div>
                  {renderFlag === true && (
                    <WorkerRemoteAll
                      data={subscriptionData}
                      page={page}
                      sizePerPage={invoiceSizePerPage}
                      totalSize={invoiceTotalSize}
                      onTableChange={this.handleTableChange}
                    />
                  )}
                </div>
              </div>
            )}
            {this.state.showpayment === true && (
              <div className="row flex-column">
                <div>
                  {!newPayment && (
                    <span
                      style={{ cursor: "pointer" }}
                      className="pull-right mb-2"
                      onClick={() => {
                        this.onSubscribe();
                      }}
                    >
                      <i
                        className="fa fa-arrow-left standardColorIcon mr-4"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                  )}
                  {newPayment && (
                    <span
                      style={{ cursor: "pointer" }}
                      className="pull-right mb-2"
                      onClick={() => {
                        this.onSubscribe();
                        this.onNewPayment();
                      }}
                    >
                      <i
                        className="fa fa-arrow-left standardColorIcon mr-4"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                  )}
                </div>
                <div className="">
                  {stripeKey && (
                    <Elements stripe={stripePromise}>
                      <HomePage
                        authToken={this.props.authToken}
                        userName1={this.state.userName}
                        userEmail={this.state.email}
                        userline1={this.state.line1}
                        countriesList={countriesList}
                        onSubscribe={this.onSubscribe}
                        getRecords={this.getRecords}
                        optionRef={this.optionRef}
                        onMenuOpen={this.onMenuOpen}
                        subscribeUnitAmount={this.state.subscribeUnitAmount}
                        newPayment={newPayment}
                        onNewPayment={this.onNewPayment}
                        userCountryIdValue={countryIdValue}
                        userCountryId={this.state.countryId}
                        userCity={this.state.city}
                        userstate={this.state.state}
                        invoiceAmount={invoiceAmount}
                        invoiceQuantity={invoiceQuantity}
                        handleCountryChange={this.handleCountryChange}
                        userCountryCode={countryCode}
                        userQuantity={this.state.userQuantity}
                        totalWorkers={totalWorkers}
                        usercountry={this.state.country}
                        userPostalCode={this.state.postalCode}
                      />
                    </Elements>
                  )}
                </div>
              </div>
            )}
          </div>
          <DepositModal
            {...this.props}
            {...this.state}
            cancelSubscription={this.cancelSubscription}
            confirmationEmail={confirmationEmail}
            onChangeEmail={this.onChangeEmail}
            depositOnCloseModal={this.depositOnCloseModal}
            onInputValueChange={this.onchange}
          />
          <UpdatePaymentModal
            {...this.props}
            {...this.state}
            userName={this.state.userName}
            cardExpiryDate={cardExpiryDate}
            optionRef={this.optionRef}
            handleCountryChange={this.handleCountryChange}
            countryIdValue={this.state.countryIdValue}
            onMenuOpen={this.onMenuOpen}
            subscribeUnitAmount={this.state.subscribeUnitAmount}
            userCountryCode={countryCode}
            updateModalOnCloseModal={this.updateModalOnCloseModal}
            onchange={this.onchange}
          />
          <UpgradeModal
            {...this.props}
            {...this.state}
            confirmationEmail={confirmationEmail}
            onChangeEmail={this.onChangeEmail}
            upgradeModalOpen={this.state.upgradeModalOpen}
            upgradeModalClose={this.upgradeModalClose}
            totalWorkers={totalWorkers}
            upgradeAmount={upgradeAmount}
            upgradeSubscription={this.upgradeSubscription}
            onQuantityChange={this.onQuantityChange}
            // onInputValueChange={this.onchange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.token,
  authUserInfo: state.user.userInfo,
  userDetails: state.user.userDetails,
  countries: state.country.countries,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserDetails: (success) => dispatch(fetchUserDetails(success)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
