import React, { Component } from "react";
import { Link } from "react-router-dom";
import { pageProgress } from "../../utils/Util";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    pageProgress("force_remove");
  }

  render() {
    return (
      <div className="buy-xpr-container ">
        <div className="content-i">
          <div className="content-box">
            <h1 className="color-white text-center mt-2 pb-1">Checkout</h1>
            <div className="buy-container " style={{ margin: "0px" }}>
              <div className="row">
                <div className="col-md-12 col-lg-5 col-sm-12   ">
                  <div className="theme-bg h-100 p-3">
                    <div className="align-items-center row justify-content-between ml-2 mr-2 mt-1 mb-4">
                      <p className="no-margin color-white white-lighter-text">
                        Billing Address
                      </p>
                      <div className="select-address-container">
                        <p className="no-margin color-white font-16 font-weight-200">
                          Select Address
                        </p>
                      </div>
                    </div>
                    <div className="ml-1 mr-1 row pt-2 mb-3">
                      <div className="col-md-12 mb-1">
                        <p className="no-margin color-white font-16 font-weight-200">
                          Full Name
                        </p>
                      </div>
                      <div className="col-md-12">
                        <input type="text" className="white-border-input" />
                      </div>
                    </div>
                    <div className="ml-1 mr-1 row pt-2 mb-3">
                      <div className="col-md-12 mb-1">
                        <p className="no-margin color-white font-16 font-weight-200">
                          Address
                        </p>
                      </div>
                      <div className="col-md-12 mb-2">
                        <input type="text" className="white-border-input" />
                      </div>
                      <div className="col-md-12">
                        <input type="text" className="white-border-input" />
                      </div>
                    </div>
                    <div className="ml-1 mr-1 row pt-2 mb-3">
                      <div className="col-lg-6 col-md-12 mb-3">
                        <p className="no-margin color-white font-16 font-weight-200">
                          City
                        </p>
                        <input type="text" className="white-border-input" />
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <p className="no-margin color-white font-16 font-weight-200">
                          Postal Code
                        </p>
                        <input type="text" className="white-border-input" />
                      </div>
                    </div>
                    <div className="ml-1 mr-1 row pt-2 mb-3">
                      <div className="col-lg-6 col-md-12 mb-3">
                        <p className="no-margin color-white font-16 font-weight-200">
                          State/Province
                        </p>
                        <input type="text" className="white-border-input" />
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <p className="no-margin color-white font-16 font-weight-200">
                          Country
                        </p>
                        <input type="text" className="white-border-input" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-7 col-sm-12 payment-margin-mobile d-flex flex-column justify-content-between  ">
                  <div className="theme-bg p-3 mb-5">
                    <div className="row pb-2 pt-2">
                      <div className="col-md-12">
                        <p className="no-margin color-white white-lighter-text">
                          Payment Method
                        </p>
                      </div>
                      <div className="custom-radio-group col-md-12 mt-5 mb-5">
                        <label className="radio-group-label col-md-3">
                          <input
                            className="radio-group-button"
                            type="radio"
                            checked="checked"
                            name="is_company"
                          />
                          <span className="radio-group-text">
                            Wire Transfer
                          </span>
                        </label>
                        <label className="radio-group-label col-md-3">
                          <input
                            className="radio-group-button"
                            type="radio"
                            name="is_company"
                          />
                          <span className="radio-group-text">BTC </span>
                        </label>
                        <label className="radio-group-label col-md-3">
                          <input
                            className="radio-group-button"
                            type="radio"
                            name="is_company"
                          />
                          <span className="radio-group-text"> ETH</span>
                        </label>
                        <label className="radio-group-label col-md-3">
                          <input
                            className="radio-group-button"
                            type="radio"
                            name="is_company"
                          />
                          <span className="radio-group-text"> USDT</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="theme-bg p-3 pt-4">
                    <div className="row">
                      <div className="col-md-12">
                        <p className="no-margin color-white white-lighter-text">
                          Review Your Order
                        </p>
                      </div>
                    </div>
                    <div className="order-row mt-4 mb-4">
                      <div className="col-md-3 color-white">
                        <p className="font-16 font-weight-200 mb-1">
                          Station ID
                        </p>
                        <p className="font-16">248659</p>
                      </div>
                      <div className="col-md-3 color-white">
                        <p className="font-16 font-weight-200 mb-1">
                          Station Name
                        </p>
                        <p className="font-16">Aryan Station</p>
                      </div>
                      <div className="col-md-3 color-white">
                        <p className="font-16 font-weight-200 mb-1">Order</p>
                        <p className="font-16 d-flex">
                          100 kWh
                          <div className="edit-round vertical-center ml-2">
                            <i
                              class="fa fa-pencil font-15"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </p>
                      </div>
                      <div className="col-md-3 color-white">
                        <p className="font-16 font-weight-200 mb-1">Subtotal</p>
                        <p className="font-16">15.00 USD</p>
                      </div>
                    </div>
                    <div className="align-items-center justify-content-end row mb-1">
                      <div className="col-3">
                        <p className="no-margin color-white font-16 font-weight-200">
                          Service Charges:
                        </p>
                      </div>
                      <div className="col-3">
                        <p className="no-margin color-white font-16">0 USD</p>
                      </div>
                    </div>
                    <div className="align-items-center justify-content-end row">
                      <div className="col-3">
                        <p className="no-margin color-white font-16 font-weight-200">
                          Grand Total:
                        </p>
                      </div>
                      <div className="col-3">
                        <p className="no-margin color-white font-16">
                          15.00 USD
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center align-items-center mt-5 checkout-btn">
                <div className="col-md-6 internal-checkout-btn">
                  <Link to="/exchange">
                    <button class="btn buy-btn w-250 position-relative h-50">
                      <div className="white-round btn-left-icon vertical-center">
                        <i className="fa fa-arrow-left theme-color "></i>
                      </div>
                      <span className="color-white white-lighter-text">
                        Back
                      </span>
                    </button>
                  </Link>
                </div>
                <div className="col-md-6 internal-checkout-btn d-flex justify-content-end">
                  <Link to="/order_placed">
                    <button class="btn buy-btn w-250 position-relative h-50">
                      <span className="color-white white-lighter-text">
                        Place Order
                      </span>
                      <div className="white-round btn-right-icon vertical-center">
                        <i class="fa fa-arrow-right theme-color"></i>
                      </div>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Checkout;
